import { Modal, Input, Select, PageHeader, Layout, Button, Form, Space, Card, message, Spin } from 'antd';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getMutation, getPaymentList, getXenditInvoices } from '../../store/actions/PaymentAction';
import { formatUang2, roleUser } from '../../shared/helper';
import { clearInfo } from '../../store/actions/InfoAction';
import { getMyProfile } from '../../store/actions/AccountActions';
import rs_img from '../../shared/assets/img/ruang_seminar_01.jpg';
import { changePassword, logout } from '../../store/actions/AuthAction';

let moment = require('moment');
const { Header, Content } = Layout;

const ChangePasswordPage = () => {
  const dispatch = useDispatch();
  const {info, isLoading} = useSelector((state: any) => ({
    info: state.info,
    isLoading: state.auth.isLoading,
  }));
  
  const [form] = Form.useForm();

  const [isUpdate, setIsUpdate] = useState(false);
  let initVal = {
    old_password: '',
    new_password: '',
    confirm_new_password: '',
  };

  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    if (info.id === 'AUTH_CHANGE_PASSWORD_SUCCESS') {
      setTimeout(() => {
        modal.success(configSuccess);
      }, 500);
    }
    if (info.id === 'AUTH_CHANGE_PASSWORD_FAIL') {
      setTimeout(() => {
        modal.error(configFailed);
      }, 500);
    }
  }, [info]);

  const configSuccess = {
    title: 'Berhasil',
    centered: true,
    content: (<div><p>{'Ganti password berhasil. Silahkan login kembali.'}</p></div>),
    onOk() {
      dispatch(clearInfo());
      dispatch(logout());
      form.resetFields();
      setIsUpdate(false);
    }
  };

  const configFailed = {
    title: 'Mohon maaf',
    centered: true,
    content: (<div><p>Ganti password belum berhasil.</p></div>),
    onOk() {
      // dispatch(clearInfo())
    }
  };

  const showModalConfirm = (values: any) => {
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: 'Apakah Kamu yakin akan mengganti password?',
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        onSubmitForm(values);
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const onSubmitForm = (values: any) => {
    let payload = {
      // settingId: (settingData && settingData.length > 0 && settingData[0]._id) || '',
      old_password: values.old_password, 
      new_password: values.new_password, 
    }
    console.log('isi payload', payload);
    dispatch(changePassword(payload));
  }

  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title="Ganti Password"
        // breadcrumb={{ routes }}
      />

      <Card>
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={initVal}
          onFinish={() => {
            form
              .validateFields()
              .then((values: any) => {
                showModalConfirm(values);
              })
              .catch((info: any) => {
                console.log('Validate Failed:', info);
              });
          }}
          // initialValues={{name: initVal.name}}
        >
          <Form.Item
            name="old_password"
            label="Password Lama"
            rules={[{ required: true, message: 'Password lama diperlukan' }]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="new_password"
            label="Password Baru"
            rules={[
              { required: true, message: 'Password baru diperlukan' },
              { min: 6, message: 'Password harus minimal 6 karakter' }
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            label="Konfirmasi Password Baru"
            rules={[
              { required: true, message: 'Konfirmasi password diperlukan' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Password tidak cocok'));
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Space>
              <Button type="primary" htmlType="submit">
                Simpan
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </Card>
      {contextHolder}
      {isLoading &&
        <div className="overlay">
          <Spin size="large" />
        </div>
      }
    </Content>
  )
};

export default ChangePasswordPage;