import {httpRequestAxiosService} from '../../services';
import {returnErrors, returnSuccess} from './InfoAction';
import { SETTING_CREATE_FAIL, SETTING_CREATE_SUCCESS, SETTING_GET_FAIL, SETTING_GET_SUCCESS, SETTING_LOADING, SETTING_UPDATE_FAIL, SETTING_UPDATE_SUCCESS } from '../types';
import { tokenConfig } from './AuthAction';

// Get Setting
export const getSetting = () => (dispatch: any, getState: any) => {
  dispatch({type: SETTING_LOADING});

  // let param = {
  //   page: qparam && qparam.page ? qparam.page : 1,
  //   limit: qparam && qparam.limit ? qparam.limit : 10,
  // };

  httpRequestAxiosService.get(process.env.REACT_APP_MAIN_API + 'setting', tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: SETTING_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Pengaturan berhasil diperoleh.', 200, 'SETTING_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Pengaturan belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'SETTING_GET_FAIL',
        ),
      );
      dispatch({
        type: SETTING_GET_FAIL,
      });
  })
};

// adjustment setting
export const adjustmentSetting = (payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: SETTING_LOADING});

  httpRequestAxiosService.post(process.env.REACT_APP_MAIN_API + 'setting', payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: SETTING_CREATE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Pengaturan berhasil disesuaikan.', 200, 'SETTING_CREATE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'SETTING_CREATE_FAIL',
          'Pengaturan belum berhasil disesuaikan',
        ),
      );
      dispatch({
        type: SETTING_CREATE_FAIL,
      });
  })
};

// update announcement
export const updateAnnouncement = (paramId: any, payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: SETTING_LOADING});

  httpRequestAxiosService.put(process.env.REACT_APP_MAIN_API + 'announcements/' + paramId, payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: SETTING_UPDATE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Pengumuman berhasil diubah.', 200, 'SETTING_UPDATE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'SETTING_UPDATE_FAIL',
          'Pengumuman belum berhasil diubah',
        ),
      );
      dispatch({
        type: SETTING_UPDATE_FAIL,
      });
  })
};