import { Table, Tag, Space, PageHeader, Layout } from 'antd';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal, Input, message, Select, Spin } from 'antd';
import { validatePaymentMoota } from '../../store/actions/WebinarAction';

import { getHistory, getMootaNotif } from '../../store/actions/HistoryAction';
import { formatUang2 } from '../../shared/helper';
let moment = require('moment');

const { Header, Content } = Layout;

const WebinarHistory = (props: any) => {
  const dispatch = useDispatch();
  const {info, isLoading, hList, hTotal} = useSelector((state: any) => ({
    info: state.info,
    hList: state.hstr.data,
    hTotal: state.hstr.total,
    isLoading: state.hstr.isLoading,
  }));

  const [wInfo, setWInfo] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    loadHistory()
  }, [page]);
  
  const loadHistory = () => {
    let param = {
      page: page,
      limit: 10,
    }
    dispatch(getHistory(param));
  }

  useEffect(() => {
    if (info.id === 'WEBINAR_VALIDATE_PAYMENT_MOOTA_SUCCESS') {
      message.success(info.msg);
      loadHistory()
    }
    if (info.id === 'WEBINAR_VALIDATE_PAYMENT_MOOTA_FAIL') {
      message.error(info.msg.message);
    }
  }, [info]);

  const showModalAccPayment = (param: any, status: any) => {
    let payload = {
      status: status,
    };

    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: 'Apakah Kamu yakin akan menyetujui pembayaran ini?',
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(validatePaymentMoota(param.webinar._id, param.user._id, payload));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const columns = [
    {
      title: 'Tanggal',
      dataIndex: 'createdAt',
      key: 'tanggal',
      render: (waktu: any) => <text>{moment(waktu).format('DD MMM YYYY HH:mm')}</text>,
    },
    {
      title: 'Webinar',
      dataIndex: ['webinar', 'name'],
      key: 'webinar_name',
    },
    {
      title: 'Peserta',
      dataIndex: ['user', 'name'],
      key: 'peserta',
    },
    {
      title: 'Email',
      dataIndex: ['user', 'email'],
      key: 'peserta',
    },
    {
      title: 'No HP',
      dataIndex: ['user', 'phone'],
      key: 'peserta',
    },
    {
      title: 'Platform Pembayaran',
      dataIndex: 'invoice',
      key: 'Platform Pembayaran',
      render: (item: any) => <text>{item && item.x_invoice_url ? 'Xendit' : 'Moota'}</text>,
    },
    {
      title: 'Kode Unik',
      dataIndex: ['invoice', 'uniqueCode'],
      key: 'Kode Unik',
    },
    {
      title: 'Total Pembayaran',
      dataIndex: 'invoice',
      key: 'Total Pembayaran',
      render: (item: any) => <text>{item ? formatUang2((parseInt(item.amount)+parseInt(item.uniqueCode)).toString(), undefined) : '-'}</text>,
    },
    {
      title: 'Status Pembayaran',
      dataIndex: ['invoice', 'status'],
      key: 'status_pembayaran',
    },
    {
      title: 'Action',
      key: 'action',
      render: (item: any, record: any) => (
        <Space size="middle">
          {record && record.invoice && (record.invoice !== null) && (record.invoice.status === 'PENDING') && (
            <a
              onClick={() => {
                showModalAccPayment(record, 'PAID');
              }}
            >Acc Pembayaran</a>
          )}
          {record && record.invoice && (record.invoice !== null) && (record.invoice.status === 'PAID') && (
            <a
              onClick={() => {
                showModalAccPayment(record, 'PENDING');
              }}
            >Batalkan Acc Pembayaran</a>
          )}
        </Space>
      ),
    },
  ];

  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title={'Webinar History'}
        // breadcrumb={{ routes }}
        // extra={[
        //   <Button key="3" type="primary" onClick={() => dispatch(getMootaNotif(undefined))}>Cek Pembayaran</Button>
        // ]}
      />
      <Table 
        columns={columns} 
        dataSource={hList} 
        loading={isLoading}
        pagination={{
          hideOnSinglePage: true,
          total: hTotal,
          onChange: (val) => setPage(val)
        }}  
      />
    </Content>
  )
};

export default WebinarHistory;