import React from 'react';
import { Navigate, Routes, Route } from 'react-router-dom';

import AuthRoute from './AuthRoute';
import GuestRoute from './GuestRoute';

import AdminLayout from '../layouts/AdminLayout';

import Login from '../pages/auth/Login';

import Certificate from '../pages/certificate';

import Dashboard from '../pages/dashboard/Dashboard';

import ParticipantList from '../pages/participant';

import PaymentList from '../pages/payment';

import History from '../pages/history';

import WebinarList from '../pages/webinar/Webinar';
import WebinarDetail from '../pages/webinar/WebinarDetail';
import WebinarParticipant from '../pages/webinar/WebinarParticipant';
import WebinarPostTest from '../pages/webinar/WebinarPostTest';
import WebinarCertificate from '../pages/webinar/WebinarCertificate';

import AnnouncementList from '../pages/announcement/List';

import MasterData from '../pages/masterdata/MasterData';
import NotFound from '../layouts/NotFound';
import PaymentXendit from '../pages/payment/paymentXendit';
import Setting from '../pages/setting';
import WebinarParticipantDetail from '../pages/webinar/WebinarParticipantDetail';
import ProfilePage from '../pages/account/Profile';
import AnnouncementAdd from '../pages/announcement/AnnouncementAdd';
import ParticipantDetail from '../pages/participant/ParticipantDetail';
import ParticipantImport from '../pages/participantImport';
import AddParticipantFromImport from '../pages/participantImport/AddParticipant';
import ChangePasswordPage from '../pages/account/ChangePassword';


const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/login" element={<GuestRoute><Login /></GuestRoute>} />
      <Route path="/admin/dashboard" element={<AdminLayout />}>
        <Route path="" element={<AuthRoute><Dashboard /></AuthRoute>} />
      </Route>
      <Route path="/admin/webinar" element={<AdminLayout />}>
        <Route path="" element={<AuthRoute><WebinarList /></AuthRoute>} />
        <Route path="participant/:id" element={<AuthRoute><WebinarParticipant /></AuthRoute>} />
        <Route path="participant-detail/:id" element={<AuthRoute><WebinarParticipantDetail /></AuthRoute>} />
        <Route path="posttest/:id" element={<AuthRoute><WebinarPostTest /></AuthRoute>} />
        <Route path="detail/:id" element={<AuthRoute><WebinarDetail /></AuthRoute>} />
        <Route path="certificate/:id" element={<AuthRoute><WebinarCertificate /></AuthRoute>} />
      </Route>
      <Route path="/admin/history" element={<AdminLayout />}>
        <Route path="" element={<AuthRoute><History /></AuthRoute>} />
      </Route>
      <Route path="/admin/participant" element={<AdminLayout />}>
        <Route path="" element={<AuthRoute><ParticipantList /></AuthRoute>} />
        <Route path="detail/:id" element={<AuthRoute><ParticipantDetail /></AuthRoute>} />
      </Route>
      <Route path="/admin/imported-participant" element={<AdminLayout />}>
        <Route path="" element={<AuthRoute><ParticipantImport /></AuthRoute>} />
        <Route path="add" element={<AuthRoute><AddParticipantFromImport /></AuthRoute>} />
      </Route>
      <Route path="/admin/payment" element={<AdminLayout />}>
        <Route path="" element={<AuthRoute><PaymentList /></AuthRoute>} />
        <Route path="xendit" element={<AuthRoute><PaymentXendit /></AuthRoute>} />
      </Route>
      <Route path="/admin/announcement" element={<AdminLayout />}>
        <Route path="" element={<AuthRoute><AnnouncementList /></AuthRoute>} />
        <Route path="add" element={<AuthRoute><AnnouncementAdd /></AuthRoute>} />
      </Route>
      <Route path="/admin/masterdata" element={<AdminLayout />}>
        <Route path="" element={<AuthRoute><MasterData /></AuthRoute>} />
      </Route>
      <Route path="/admin/setting" element={<AdminLayout />}>
        <Route path="" element={<AuthRoute><Setting /></AuthRoute>} />
      </Route>
      <Route path="/admin/profile" element={<AdminLayout />}>
        <Route path="" element={<AuthRoute><ProfilePage /></AuthRoute>} />
      </Route>
      <Route path="/admin/changepassword" element={<AdminLayout />}>
        <Route path="" element={<AuthRoute><ChangePasswordPage /></AuthRoute>} />
      </Route>
      <Route path='*' element={<AuthRoute><NotFound /></AuthRoute>} />
    </Routes>
  );
}

export default Router;