import React, {useEffect, useState} from 'react';
import { Button, message, Modal } from 'antd';
import ProForm, { ProFormText } from '@ant-design/pro-form';
import { MobileOutlined, LockOutlined } from '@ant-design/icons';
import {useSelector, useDispatch} from 'react-redux';

import {login} from '../../store/actions/AuthAction';
import { getPackage } from '../../store/actions/PackageAction';
import { clearInfo } from '../../store/actions/InfoAction';

const LoginPage = () => {
  const dispatch = useDispatch();
  const {info, isLoading, isAuthenticated} = useSelector((state: any) => ({
    info: state.info,
    isAuthenticated: state.auth.isAuthenticated,
    isLoading: state.auth.isLoading,
  }));

  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [modal, contextHolder] = Modal.useModal();
  
  const config = {
    title: 'Terjadi Kesalahan',
    centered: true,
    content: (
      <div>
        <p>Username atau password keliru...</p>
      </div>
    ),
    onOk() {
      dispatch(clearInfo())
    }
  };

  useEffect(() => {
    if (info.id === 'LOGIN_SUCCESS' && isAuthenticated) {
      message.success('Login berhasil');
      dispatch(getPackage())
      // dispatch(loadMe());
    }
    if (info.id === 'LOGIN_FAIL' && !isAuthenticated) {
      // message.error('Login belum berhasil')
      setTimeout(() => {
        // setIsOpenAlert(true)
        modal.error(config)
      }, 300);
    }
  }, [info]);

  return (
    <div style={{width: 330, margin: 'auto', marginTop: '10%'}}>
      <ProForm
        onFinish={async (val) => {
          dispatch(login(val));
          // modal.error(config)
          // await waitTime(2000);
        }}
        submitter={{
          searchConfig: {
            submitText: 'Login',
          },
          render: (_, dom) => dom.pop(),
          submitButtonProps: {
            size: 'large',
            style: {
              width: '100%',
            },
          },
        }}
      >
        <h1
          style={{
            textAlign: 'center',
          }}
        >
          {/* <img
            style={{
              height: '44px',
              marginRight: 16,
            }}
            alt="logo"
            src="https://gw.alipayobjects.com/zos/rmsportal/KDpgvguMpGfqaHPjicRK.svg"
          /> */}
          Ruang Seminar
        </h1>
        <div
          style={{
            marginTop: 12,
            textAlign: 'center',
            marginBottom: 40,
          }}
        >
          Ruang Seminar Platform
        </div>
        <ProFormText
          name="email"
          fieldProps={{
            size: 'large',
            prefix: <MobileOutlined />,
          }}
          placeholder="Email"
          rules={[
            {
              required: true,
              message: 'Email harus diisi',
            },
            // {
            //   pattern: /\d{6}$/,
            //   message: 'Penulisan Email salah',
            // },
          ]}
        />
        <ProFormText.Password
          name="password"
          fieldProps={{
            size: 'large',
            prefix: <LockOutlined className={"prefixIcon"} />,
          }}
          placeholder={"Password"}
          rules={[
            {
              required: true,
              message: "Password harus diisi",
            },
          ]}
        />
      </ProForm>
      {contextHolder}
    </div>
  );
};

export default LoginPage;
