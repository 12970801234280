import { Table, Tag, Space, PageHeader, Layout } from 'antd';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Modal, Input, message, Select, Spin } from 'antd';
import { validatePaymentMoota } from '../../store/actions/WebinarAction';

import { getHistory, getMootaNotif, getUserActivity } from '../../store/actions/HistoryAction';
import { formatUang2 } from '../../shared/helper';
let moment = require('moment');

const { Header, Content } = Layout;

const ActivityHistory = (props: any) => {
  const dispatch = useDispatch();
  const {info, isLoading, uATotal, uActivity} = useSelector((state: any) => ({
    info: state.info,
    isLoading: state.hstr.isLoading,
    uActivity: state.hstr.userActivity,
    uATotal: state.hstr.totalUserActivity,
  }));

  const [page, setPage] = useState(1);

  useEffect(() => {
    loadActivityHistory()
  }, [page]);
  
  const loadActivityHistory = () => {
    let param = {
      page: page,
      limit: 10,
    }
    dispatch(getUserActivity(param));
  }

  const columns = [
    {
      title: 'Tanggal',
      dataIndex: 'createdAt',
      key: 'tanggal',
      render: (waktu: any) => <text>{moment(waktu).format('DD MMM YYYY HH:mm')}</text>,
    },
    {
      title: 'Kode Aktivitas',
      dataIndex: 'activity_code',
      key: 'activity_code',
    },
    {
      title: 'Deskripsi',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'User',
      dataIndex: ['user', 'name'],
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: ['user', 'email'],
      key: 'peserta',
    },
    {
      title: 'No HP',
      dataIndex: ['user', 'phone'],
      key: 'peserta',
    }
  ];

  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title={'User Activity Log'}
      />
      <Table 
        columns={columns} 
        dataSource={uActivity} 
        loading={isLoading}
        pagination={{
          hideOnSinglePage: true,
          total: uATotal,
          onChange: (val) => setPage(val)
        }}  
      />
    </Content>
  )
};

export default ActivityHistory;