import {httpRequestAxiosService} from '../../services';
import {returnErrors, returnSuccess} from './InfoAction';
import { EVIDENCE_CREATE_FAIL, EVIDENCE_CREATE_SUCCESS, EVIDENCE_DELETE_FAIL, EVIDENCE_DELETE_SUCCESS, EVIDENCE_GET_FAIL, EVIDENCE_GET_SUCCESS, EVIDENCE_LOADING, EVIDENCE_UPDATE_FAIL, EVIDENCE_UPDATE_SUCCESS } from '../types';
import { tokenConfig } from './AuthAction';

// Get Evidence
export const getEvidence = (qparam: any) => (dispatch: any, getState: any) => {
  dispatch({type: EVIDENCE_LOADING});

  let param = {
    page: qparam && qparam.page ? qparam.page : 1,
    limit: qparam && qparam.limit ? qparam.limit : 10,
  };

  httpRequestAxiosService.get(process.env.REACT_APP_MAIN_API + 'evidences?page=' + param.page + '&limit=' + param.limit, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: EVIDENCE_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Bukti share berhasil diperoleh.', 200, 'EVIDENCE_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Bukti share belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'EVIDENCE_GET_FAIL',
        ),
      );
      dispatch({
        type: EVIDENCE_GET_FAIL,
      });
  })
};

// add new evidence
export const createEvidence = (payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: EVIDENCE_LOADING});

  httpRequestAxiosService.post(process.env.REACT_APP_MAIN_API + 'evidences', payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: EVIDENCE_CREATE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Bukti share berhasil ditambahkan.', 200, 'EVIDENCE_CREATE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'EVIDENCE_CREATE_FAIL',
          'Bukti share belum berhasil ditambahkan',
        ),
      );
      dispatch({
        type: EVIDENCE_CREATE_FAIL,
      });
  })
};

// // update evidence
export const updateEvidence = (paramId: any, payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: EVIDENCE_LOADING});

  httpRequestAxiosService.put(process.env.REACT_APP_MAIN_API + 'evidences/' + paramId, payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: EVIDENCE_UPDATE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Bukti share berhasil diubah.', 200, 'EVIDENCE_UPDATE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'EVIDENCE_UPDATE_FAIL',
          'Bukti share belum berhasil diubah',
        ),
      );
      dispatch({
        type: EVIDENCE_UPDATE_FAIL,
      });
  })
};

// // remove evidence
export const removeEvidence = (paramId: any) => (dispatch: any, getState: any) => {
  dispatch({type: EVIDENCE_LOADING});

  httpRequestAxiosService.delete(process.env.REACT_APP_MAIN_API + 'evidences/' + paramId, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: EVIDENCE_DELETE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Bukti share berhasil dihapus.', 200, 'EVIDENCE_DELETE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'EVIDENCE_DELETE_FAIL',
          'Bukti share belum berhasil dihapus',
        ),
      );
      dispatch({
        type: EVIDENCE_DELETE_FAIL,
      });
  })
};