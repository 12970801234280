import {
  WEBINAR_ADD_CERTIFICATE_FAIL,
  WEBINAR_ADD_CERTIFICATE_SUCCESS,
  WEBINAR_ADD_CERTIFICATE_USER_FAIL,
  WEBINAR_ADD_CERTIFICATE_USER_SUCCESS,
  WEBINAR_ADD_PACKAGE_FAIL,
  WEBINAR_ADD_PACKAGE_SUCCESS,
  WEBINAR_ADD_POSTER_FAIL,
  WEBINAR_ADD_POSTER_SUCCESS,
  WEBINAR_ADD_SPEAKER_AVATAR_FAIL,
  WEBINAR_ADD_SPEAKER_AVATAR_SUCCESS,
  WEBINAR_ADD_VBG_FAIL,
  WEBINAR_ADD_VBG_SUCCESS,
  WEBINAR_CREATE_FAIL,
  WEBINAR_CREATE_SUCCESS,
  WEBINAR_DELETE_FAIL,
  WEBINAR_DELETE_SUCCESS,
  WEBINAR_DETAIL_GET_FAIL,
  WEBINAR_DETAIL_GET_SUCCESS,
  WEBINAR_GENERATE_INVOICE_FAIL,
  WEBINAR_GENERATE_INVOICE_SUCCESS,
  WEBINAR_GET_FAIL,
  WEBINAR_GET_SUCCESS,
  WEBINAR_LOADING,
  WEBINAR_PARTICIPANT_DETAIL_GET_FAIL,
  WEBINAR_PARTICIPANT_DETAIL_GET_SUCCESS,
  WEBINAR_PARTICIPANT_GET_FAIL,
  WEBINAR_PARTICIPANT_GET_SUCCESS,
  WEBINAR_PARTICIPANT_RESET_NO_CERT_FAIL,
  WEBINAR_PARTICIPANT_RESET_NO_CERT_SUCCESS,
  WEBINAR_REFRESH_STATUS_FAIL,
  WEBINAR_REFRESH_STATUS_SUCCESS,
  WEBINAR_UPDATE_FAIL,
  WEBINAR_UPDATE_STATUS_FAIL,
  WEBINAR_UPDATE_STATUS_SUCCESS,
  WEBINAR_UPDATE_SUCCESS,
  WEBINAR_USER_GET_FAIL,
  WEBINAR_USER_GET_SUCCESS,
  WEBINAR_VALIDATE_PARTICIPANT_BASIC_FAIL,
  WEBINAR_VALIDATE_PARTICIPANT_BASIC_SUCCESS,
  WEBINAR_VALIDATE_PAYMENT_MOOTA_FAIL,
  WEBINAR_VALIDATE_PAYMENT_MOOTA_SUCCESS,
  WEBINAR_VALIDATE_W_REGIS_FAIL,
  WEBINAR_VALIDATE_W_REGIS_SUCCESS,
} from '../types';

const initialState = {
  data: [],
  wParticipant: [],
  wpDetail: undefined,
  wDetail: null,
  wuList: [],
  total: 0,
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case WEBINAR_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case WEBINAR_GET_SUCCESS:
      return {
        ...state,
        data: action.resp.webinar,
        total: action.resp.total,
        isLoading: false,
      };
    case WEBINAR_GET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_USER_GET_SUCCESS:
      return {
        ...state,
        wuList: action.resp.webinar,
        isLoading: false,
      };
    case WEBINAR_USER_GET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_CREATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_REFRESH_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_REFRESH_STATUS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_ADD_PACKAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_ADD_PACKAGE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_VALIDATE_W_REGIS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_VALIDATE_W_REGIS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_VALIDATE_PAYMENT_MOOTA_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_VALIDATE_PAYMENT_MOOTA_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_ADD_CERTIFICATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_ADD_CERTIFICATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_ADD_POSTER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_ADD_POSTER_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_ADD_VBG_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_ADD_VBG_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_ADD_CERTIFICATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_ADD_CERTIFICATE_USER_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_ADD_SPEAKER_AVATAR_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_ADD_SPEAKER_AVATAR_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_DELETE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_DETAIL_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wDetail: action.resp,
      };
    case WEBINAR_DETAIL_GET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_PARTICIPANT_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wParticipant: action.resp,
      };
    case WEBINAR_PARTICIPANT_GET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_PARTICIPANT_DETAIL_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wpDetail: action.resp,
      };
    case WEBINAR_PARTICIPANT_DETAIL_GET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_PARTICIPANT_RESET_NO_CERT_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_PARTICIPANT_RESET_NO_CERT_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_VALIDATE_PARTICIPANT_BASIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_VALIDATE_PARTICIPANT_BASIC_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_UPDATE_STATUS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_UPDATE_STATUS_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_GENERATE_INVOICE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_GENERATE_INVOICE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
