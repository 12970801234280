import { Table, Switch, Space, PageHeader, Layout } from 'antd';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Link, useLocation, useNavigate } from "react-router-dom";

import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import type { ProColumns, ActionType } from '@ant-design/pro-table';
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Modal, Input, message, Select, Spin } from 'antd';
import { getWebinar, createWebinar, addPackageToWebinar, removeWebinar, getWebinarUser } from '../../store/actions/WebinarAction';
import { getPackage } from '../../store/actions/PackageAction';
import { createAnnouncement, getAnnouncement, updateAnnouncement } from '../../store/actions/AnnouncementActions';
import { getWebinarCategories } from '../../store/actions/WebinarCategoryAction';
import { getMainObject, getMainValue } from '../../shared/helper';
let moment = require('moment');

const { Option } = Select;
const { Header, Content } = Layout;

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const AnnouncementAdd = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {info, isLoading, isLoading2, aList, wuList, webCatList} = useSelector((state: any) => ({
    info: state.info,
    aList: state.anno.data,
    isLoading: state.anno.isLoading,
    isLoading2: state.wbnr.isLoading,
    wuList: state.wbnr.wuList,
    webCatList: state.wbcr.data,
  }));

  const location = useLocation();

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isRefresh, setIsRefresh] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(undefined);
  const [previewImage, setPreviewImage] = useState('');

  const [page, setPage] = useState(1);

  const [initVal, setInitVal] = useState<any>({
    title: '',
    info: '',
    related_data: '',
    topic_selected: [],
    type: 'all',
    schedule: 'send_now',
    status: 1,
    userId: ''
  });

  useEffect(() => {
    if (isRefresh) {
      setTimeout(() => {
        setIsRefresh(false)
      }, 300);
    }
  }, [isRefresh])

  useEffect(() => {
    if (location && location.state && location.state.webinar) {
      form.setFieldValue('title', 'Webinar Baru Tersedia')
      form.setFieldValue('info', 'Ikuti webinar terbaru Ruang Seminar')
      form.setFieldValue('related_data', location.state.webinar._id)
    }
    if (location && location.state && location.state.participant) {
      form.setFieldValue('title', 'Webinar Baru Tersedia')
      form.setFieldValue('info', 'Ikuti webinar terbaru Ruang Seminar')
      form.setFieldValue('type', 'specific_by_participant')
      form.setFieldValue('userId', location.state.participant._id)
      form.setFieldValue('userName', location.state.participant.name)
      form.setFieldValue('fcmToken', location.state.participant.mobileFCMToken)
    }
  }, [location])

  useEffect(() => {
    loadAnnouncement();
    loadWebinarUser();
    loadWebinarTheme();
  }, [page]);

  useEffect(() => {
    if (info.id === 'ANNOUNCEMENT_CREATE_SUCCESS' || info.id === 'ANNOUNCEMENT_UPDATE_SUCCESS') {
      message.success(info.msg);
      loadAnnouncement();
      navigate(-1)
    }
    if (info.id === 'ANNOUNCEMENT_CREATE_FAIL' || info.id === 'ANNOUNCEMENT_UPDATE_FAIL') {
      message.error(info.msg.message);
    }
  }, [info]);

  const loadAnnouncement = () => {
    let param = {
      page: page,
      limit: 10,
    }

    dispatch(getAnnouncement(param));
  }

  const loadWebinarUser = () => {
    let param = {
      page: page,
      limit: 100,
    }

    dispatch(getWebinarUser(param));
  }

  const loadWebinarTheme = () => {
    let param = {
      page: page,
      limit: 100,
    }
    dispatch(getWebinarCategories(param));
  }

  const onCancel = () => {
    setVisible(false);
  }

  const onFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setPreviewImage(URL.createObjectURL(event.target.files[0]))
  }; 

  const onSubmit = (val: any) => {
    if (val.related_data) {
      let theData = getMainObject(wuList, '_id', val.related_data)
      val.related_data = {
        webinarId: theData._id,
        webinarName: theData.name,
        webinarStart: theData.start,
        webinarEnd: theData.end,
      }
    }

    const formData = new FormData();
    formData.append("title", val.title);
    formData.append("info", val.info);
    formData.append("type", val.type);
    formData.append("related_image", selectedFile);
    formData.append("userId", val.userId);
    formData.append("fcmToken", val.fcmToken);
    
    formData.append("related_data", val.related_data ? JSON.stringify(val.related_data) : "");
    formData.append("topic_selected", val.topic_selected ? JSON.stringify(val.topic_selected) : "");

    dispatch(createAnnouncement(formData));
  }

  const resetInitVal = () => {
    setInitVal({
      name: '',
      description: '',
      point: '',
      category: '',
    })
  }

  const onBack = () => {
    navigate(-1)
  }

  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title="Tambah Pengumuman"
        // breadcrumb={{ routes }}
        // extra={[
        //   <Button key="3" type="primary" onClick={() => setVisible(true)}>Tambah Pengumuman</Button>
        // ]}
      />
      <Form
        // {...formItemLayout}
        form={form}
        layout="vertical"
        name="form_announcement"
        initialValues={initVal}
        onFinish={onSubmit}
        scrollToFirstError
      >
        <Form.Item
          name="title"
          label="Judul Pengumuman"
          rules={[{ required: true, message: 'Judul pengumuman harus diisi' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item 
          name="info" 
          label="Isi Pengumuman"
          rules={[{ required: true, message: 'Isi pengumuman harus diisi' }]}
        >
          <Input type="textarea" />
        </Form.Item>
        {previewImage && (
          <img
            width={200}
            alt="poster"
            src={previewImage}
          />
        )}
        <Form.Item 
          name="related_image" 
          label="Gambar Terkait Pengumuman"
        >
          <Input type="file" onChange={onFileChange} />
        </Form.Item>
        <Form.Item name="related_data" label="Webinar Terkait">
          <Select
            placeholder="Webinar Terkait"
            allowClear
          >
            {wuList && wuList.map((item: any, ind: any) => {
              return (<Option key={ind} value={item._id}>{item.name}</Option>)
            })}
          </Select>
        </Form.Item>
        <Form.Item name="type" label="Jenis Pengumuman" rules={[{ required: true }]}>
          <Select placeholder="Jenis Pengumuman" allowClear onChange={() => setIsRefresh(true)}>
            <Option value={'all'}>Semua Peserta</Option>
            <Option value={'specific_by_topic'}>Spesifik Berdasarkan Topik</Option>
            <Option value={'specific_by_participant'}>Spesifik Berdasarkan Peserta</Option>
          </Select>
        </Form.Item>
        {!isRefresh && form && (form.getFieldValue('type') === 'specific_by_topic') && (
          <Form.Item name="topic_selected" label="Pilih Topik (paling banyak 5)">
            <Select 
              mode="multiple"
              placeholder="Pilih Topik" allowClear>
              {webCatList && webCatList.map((item: any, ind: any) => {
                return (<Option key={ind} value={item.name}>{item.name}</Option>)
              })}
            </Select>
          </Form.Item>
        )}
        {!isRefresh && form && (form.getFieldValue('type') === 'specific_by_participant') && (
          <>
            <Form.Item name="userId" label="ID Peserta">
              <Input disabled />
            </Form.Item>
            <Form.Item name="userName" label="Nama Peserta">
              <Input disabled />
            </Form.Item>
            <Form.Item name="fcmToken" label="Mobile FCM Token">
              <Input disabled />
            </Form.Item>
          </>
        )}
        {/* <Form.Item name="schedule" label="Jadwal Pengumuman" rules={[{ required: true }]}>
          <Select
            placeholder="Jadwal Pengumuman"
            allowClear
          >
            <Option value={'save_as_draft'}>Simpan sebagai draft</Option>
            <Option value={'send_now'}>Kirim saat ini</Option>
          </Select>
        </Form.Item> */}
        {/* <Form.Item name="status" label="Status Pengumuman" rules={[{ required: true }]}>
          <Select placeholder="Status Pengumuman" allowClear>
            <Option value={1}>Aktif</Option>
            <Option value={0}>Tidak Aktif</Option>
          </Select>
        </Form.Item> */}
        <Form.Item {...tailLayout}>
          {/* <Button htmlType="button" onClick={onBack}>
            Kembali
          </Button> */}
          <Button type="primary" htmlType="submit">
            Simpan dan Kirim
          </Button>
        </Form.Item>
      </Form>

    </Content>
  )
};

export default AnnouncementAdd;
