import React, { useEffect, useState } from 'react';
import { Button, Input, Layout, PageHeader, Table, Modal, Space, Tabs } from 'antd';
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import WebinarHistory from './WebinarHistory';
import ActivityHistory from './ActivityHistory';


const { Header, Content } = Layout;
const { Search } = Input;
const { TabPane } = Tabs;

const History = (props: any) => {
  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title="History and Log Activity"
      />
      <Tabs defaultActiveKey="1">
        <TabPane tab="History Webinar" key="1">
          <WebinarHistory />
        </TabPane>
        <TabPane tab="Aktivitas User" key="2">
          <ActivityHistory />
        </TabPane>
      </Tabs>
    </Content>
  );
}

export default History;