import { Table, Tag, Space, PageHeader, Layout } from 'antd';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import type { ProColumns, ActionType } from '@ant-design/pro-table';
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Input, message, Select, Divider } from 'antd';
import { createPackage, getPackage, removePackage, updatePackage } from '../../../store/actions/PackageAction';

const { Option } = Select;
const { Header, Content } = Layout;
const routes = [
  {
    path: 'index',
    breadcrumbName: 'Dashboard',
  },
  {
    path: 'first',
    breadcrumbName: 'Paket Webinar',
  },
];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

const Package = () => {
  const dispatch = useDispatch();
  const {info, isLoading, pList} = useSelector((state: any) => ({
    info: state.info,
    pList: state.pckg.data,
    isLoading: state.pckg.isLoading,
  }));

  const actionRef = useRef<ActionType>();
  const [form] = Form.useForm();
  const [isAddFormVisible, setAddFormVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isConfirmDelete, setConfirmDelete] = useState(false);
  const [initVal, setInitVal] = useState<any>({
    name: '',
    facilities: '',
    term: '',
    category: '',
    isNeedAddress: 'no',
    isForStudent: 'no',
    durationForGetCertificate: '',
  });

  useEffect(() => {
    dispatch(getPackage());
  }, []);

  useEffect(() => {
    if (info.id === 'PACKAGE_CREATE_SUCCESS' || info.id === 'PACKAGE_UPDATE_SUCCESS' || info.id === 'PACKAGE_DELETE_SUCCESS') {
      message.success(info.msg);
      dispatch(getPackage());
    }
    if (info.id === 'PACKAGE_CREATE_FAIL' || info.id === 'PACKAGE_UPDATE_FAIL' || info.id === 'PACKAGE_DELETE_FAIL') {
      message.error(info.msg.message);
    }
  }, [info]);

  const onCancel = () => {
    setAddFormVisible(false);
    setIsUpdate(false);
  }

  const onSubmit = (val: any) => {
    setAddFormVisible(false);
    if (isUpdate) {
      setIsUpdate(false);
      dispatch(updatePackage(initVal._id, val));
      resetInitVal()
    } else {
      dispatch(createPackage(val));
    }
  }

  const resetInitVal = () => {
    setInitVal({
      name: '',
      facilities: '',
      term: '',
      category: '',
      isNeedAddress: 'no',
      isForStudent: 'no',
      durationForGetCertificate: '',
    })
  }

  const showModalConfirm = (param: any) => {
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: 'Apakah Kamu yakin akan menghapus paket ini?',
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(removePackage(param._id));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: 'Kategori',
      dataIndex: 'category',
      key: 'category'
    },
    {
      title: 'Durasi untuk mendapat sertifikat',
      dataIndex: 'durationForGetCertificate',
      key: 'durationForGetCertificate',
    },
    {
      title: 'Untuk pelajar/mahasiswa?',
      dataIndex: 'isForStudent',
      key: 'isForStudent',
      render: (text: any) => <div>{(text === 'yes' ? 'Ya' : 'Tidak')}</div>,
    },
    {
      title: 'User input alamat?',
      dataIndex: 'isNeedAddress',
      key: 'isNeedAddress',
      render: (text: any) => <div>{(text === 'yes' ? 'Ya' : 'Tidak')}</div>,
    },
    {
      title: 'Link Youtube?',
      dataIndex: 'isShowLinkYoutube',
      key: 'isShowLinkYoutube',
      render: (text: any) => <div>{(text === 'yes' ? 'Ya' : 'Tidak')}</div>,
    },

    {
      title: 'Link Zoom?',
      dataIndex: 'isShowLinkZoom',
      key: 'isShowLinkZoom',
      render: (text: any) => <div>{(text === 'yes' ? 'Ya' : 'Tidak')}</div>,
    },
    
    {
      title: 'Aksi',
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <a 
            onClick={() => {
              setInitVal(record);
              form.setFieldsValue(record);
              setIsUpdate(true);
              setTimeout(() => {
                setAddFormVisible(true);
              }, 1000);
            }}
          >Ubah</a>
          <a
            onClick={() => {
              showModalConfirm(record);
            }}
          >Hapus</a>
        </Space>
      ),
    },
  ];

  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        extra={[
          <Button key="3" type="primary" onClick={() => setAddFormVisible(true)}>Tambah Paket</Button>
        ]}
      />
      <Table columns={columns} loading={isLoading} dataSource={pList} />

      <Modal
        visible={isAddFormVisible}
        title={isUpdate ? "Ubah info paket" : "Tambah paket baru"}
        okText={isUpdate ? "Ubah" : "Tambah"}
        cancelText="Batal"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              form.resetFields();
              onSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:');
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={initVal}
          // initialValues={{name: initVal.name}}
        >
          <Form.Item
            name="name"
            label="Nama"
            rules={[{ required: true, message: 'Nama paket harus diisi!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="category"
            label="Kategori"
            rules={[{ required: true, message: 'Kategori harus diisi!' }]}
          >
            <Select placeholder="Kategori paket" allowClear>
              <Option value={'basic'}>Basic</Option>
              <Option value={'silver'}>Silver</Option>
              <Option value={'gold'}>Gold</Option>
              <Option value={'platinum'}>Platinum</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="isForStudent"
            label="Apakah paket ini untuk pelajar/mahasiswa?"
            rules={[{ required: true, message: 'Tolong diisi!' }]}
          >
            <Select placeholder="Apakah paket ini untuk pelajar/mahasiswa?" allowClear>
              <Option value={'yes'}>Ya</Option>
              <Option value={'no'}>Tidak</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="isNeedAddress"
            label="Apakah user harus input alamat?"
            rules={[{ required: true, message: 'Tolong diisi!' }]}
          >
            <Select placeholder="Apakah user harus input alamat?" allowClear>
              <Option value={'yes'}>Ya</Option>
              <Option value={'no'}>Tidak</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="isShowLinkYoutube"
            label="Apakah disediakan link youtube?"
            rules={[{ required: true, message: 'Tolong diisi!' }]}
          >
            <Select placeholder="Apakah disediakan link youtube?" allowClear>
              <Option value={'yes'}>Ya</Option>
              <Option value={'no'}>Tidak</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="isShowLinkZoom"
            label="Apakah disediakan link zoom?"
            rules={[{ required: true, message: 'Tolong diisi!' }]}
          >
            <Select placeholder="Apakah disediakan link zoom?" allowClear>
              <Option value={'yes'}>Ya</Option>
              <Option value={'no'}>Tidak</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="durationForGetCertificate"
            label="Durasi untuk mendapatkan sertifikat"
            rules={[{ required: true, message: 'Tolong diisi!' }]}
          >
            <Input />
          </Form.Item>

          <Form.List
            name="facilities"
            // rules={[
            //   {
            //     validator: async (_, names) => {
            //       if (!names || names.length < 2) {
            //         return Promise.reject(new Error('At least 2 passengers'));
            //       }
            //     },
            //   },
            // ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    // {...(index === 0 ? formItemLayout : formItemLayout)}
                    label={index === 0 ? 'Fasilitas' : ''}
                    required={false}
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: "Tolong masukan fasilitas atau hapus field ini.",
                        },
                      ]}
                      noStyle
                    >
                      <Input placeholder="fasilitas" style={{ width: '90%', marginRight: '2%' }} />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    style={{ width: '60%' }}
                    icon={<PlusOutlined />}
                  >
                    Tambahkan Fasilitas
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
      {/* {modal} */}
    </Content>
  )
};

export default Package;