import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { List, Card , Form, Modal, Typography, message, Select, Tag, Space, PageHeader, Layout, Input, Button, Row, Col } from 'antd';
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { getWebinar, createWebinar, addPackageToWebinar, removeWebinar, loadWebinarDetail, addSpeakerAvatar, addWebinarPoster, addWebinarCertificate, addWebinarVBG, updateWebinar, loadParticipantDetail, resetCertificateNumber } from '../../store/actions/WebinarAction';
import { getPackage } from '../../store/actions/PackageAction';
import { formatUang, getMainValue, isJsonString, renderSpeaker } from '../../shared/helper';
import { useLocation, useParams } from 'react-router-dom';

import rs_img from '../../shared/assets/img/ruang_seminar_01.jpg';
import WebinarFormComponent from './component/WebinarFormComponent';
import { clearInfo } from '../../store/actions/InfoAction';

let moment = require('moment');

const { Option } = Select;
const { Header, Content } = Layout;
const { Text } = Typography;

const WebinarParticipantDetail = (props: any) => {
  const dispatch = useDispatch();
  const {info, isLoading, wDetail, pList, evidList, wpDetail} = useSelector((state: any) => ({
    info: state.info,
    wDetail: state.wbnr.wDetail,
    wpDetail: state.wbnr.wpDetail,
    isLoading: state.wbnr.isLoading,
    pList: state.pckg.data,
    isLoading2: state.pckg.isLoading,
    evidList: state.evid.data
  }));

  const { id } = useParams();
  
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    dispatch(loadParticipantDetail(id));
    dispatch(getPackage());
  }, []);

  useEffect(() => {
    if (info.id === 'WEBINAR_PARTICIPANT_RESET_NO_CERT_SUCCESS') {
      setTimeout(() => {
        modal.success(configSuccess);
      }, 500);
    }
    if (info.id === 'WEBINAR_PARTICIPANT_RESET_NO_CERT_FAIL') {
      setTimeout(() => {
        modal.error(configFailed);
      }, 500);
    }
  }, [info]);

  const configSuccess = {
    title: 'Berhasil',
    centered: true,
    content: (<div><p>{'No sertifkat berhasil diubah'}</p></div>),
    onOk() {
      dispatch(clearInfo())
      dispatch(loadParticipantDetail(id));
    }
  };

  const configFailed = {
    title: 'Belum Berhasil',
    centered: true,
    content: (<div><p>{info?.msg && info?.msg[0] && info?.msg[0].message || 'No sertifikat berhasil diubah'}</p></div>),
    onOk() {
      // dispatch(clearInfo())
    }
  };

  const showConfirmResetCertificate = () => {
    // (no urut peserta menjadi no terakhir)
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: 'Apakah Kamu yakin akan mereset no sertifikat untuk peserta ini?',
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(resetCertificateNumber(wpDetail.user._id, wpDetail.webinar._id));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const renderListShareEvidence = () => {
    if (wpDetail && wpDetail?.shareEvidence !== 'https://shareevidence.png' && isJsonString(wpDetail.typeEvidences)) {
      let evidList = JSON.parse(wpDetail.typeEvidences);
      return evidList.map((item: any, ind: any) => {
        return (
          <Col className="gutter-row" span={6} key={ind}>
            <img
              width={'90%'}
              alt="logo"
              src={item.imagePath ? process.env.REACT_APP_WEB + item.imagePath : "https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"}
            />
            <div>{item.description}</div>
          </Col>
        )
      })
    } else {
      return (
        <div style={{marginLeft: 10}}>Belum ada bukti share</div>
      )
    }
  }

  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title={wpDetail && wpDetail.user && wpDetail.user.name}
      />
      {wpDetail && (
        <>
          <Card 
            type="inner" 
            title="Informasi Peserta" 
            // extra={<a href="#">More</a>}
          >
            <List.Item>
              <List.Item.Meta
                title="Nama"
                description={wpDetail && wpDetail.user && wpDetail?.user?.name}
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title="Email"
                description={wpDetail && wpDetail.user && wpDetail?.user?.email || '-'}
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title="No HP"
                description={wpDetail && wpDetail.user && wpDetail?.user?.phone || '-'}
              />
            </List.Item>
          </Card>

          <Card
            style={{ marginTop: 16 }}
            type="inner"
            title="Paket Webinar"
            // extra={<a href="#">More</a>}
          >
            {wpDetail && wpDetail.package && (
              <List.Item>
                <List.Item.Meta
                  title={'Paket'}
                  description={wpDetail.package.name}
                />
              </List.Item>
            )}
            {wpDetail && wpDetail.package && (
              <List.Item>
                <List.Item.Meta
                  title={'Harga'}
                  description={`${formatUang(wpDetail.package.price.toString(), 'Rp ')} | ${(wpDetail.package.isForStudent === 'yes') ? 'Pelajar/Mahasiswa' : 'Umum'}`}
                />
              </List.Item>
            )}
            {wpDetail && wpDetail.package && (
              <List.Item>
                <List.Item.Meta
                  title={'Fasilitas'}
                  description={wpDetail.package.facilities && wpDetail.package.facilities.map((point: any, ind: any) => {
                    return (
                      <div key={ind}>
                        <Text>- </Text>
                        <Text>{point}</Text>
                      </div>
                    )
                  })}
                />
              </List.Item>
            )}
          </Card>

          <Card
            style={{ marginTop: 16 }}
            type="inner"
            title="Status Peserta Webinar"
            // extra={<a href="#">More</a>}
          >
            {wpDetail && wpDetail.invoice ? (
              <List.Item>
                <List.Item.Meta
                  title={'Invoice'}
                  description={
                    <div>
                      <div><Text>- ID Invoice: {wpDetail.invoice.x_invoiceId || "-"}</Text></div>
                      <div><Text>- Harga: {wpDetail.invoice.amount ? formatUang(wpDetail.invoice.amount, '') : "-"}</Text></div>
                      <div><Text>- Status: {wpDetail.invoice.status}</Text></div>
                      <div><Text>- Tanggal Kadaluarsa: {wpDetail.invoice.expiryDate ? moment(wpDetail.invoice.expiryDate).format('DD MMM YYYY HH:mm') : "-"}</Text></div>
                      {wpDetail.invoice?.x_invoice_url ? (
                        <div><Text>- Link Pembayaran: <a target="_blank" rel="noopener noreferrer" href={wpDetail.invoice?.x_invoice_url}>Link Pembayaran Xendit</a></Text></div>
                      ) : "-"}                    
                    </div>
                  }
                />
              </List.Item>
            ) : (
              <List.Item>
                <List.Item.Meta
                  title={'Invoice'}
                  description={'Tidak ada invoice'}
                />
              </List.Item>
            )}
            {wpDetail && wpDetail.package && (wpDetail.package.category === 'basic') && (
              <>
                <List.Item>
                  <List.Item.Meta
                    title={'Bukti Share'}
                    description={(wpDetail && wpDetail.typeEvidence) ? wpDetail.typeEvidence.description : '-'}
                  />
                </List.Item>
                <Row gutter={[16, 24]}>
                  {renderListShareEvidence()}
                </Row>
              </>
            )}
            <List.Item>
              <List.Item.Meta
                title={'Tanggal Registrasi'}
                description={wpDetail && wpDetail.createdAt && moment(wpDetail.createdAt).format('DD MMM YYYY HH:mm')}
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={'No Sertifikat'}
                description={wpDetail && wpDetail.certificateNumber}
              />
            </List.Item>
            <Button onClick={() => showConfirmResetCertificate()}>Reset No Sertifikat </Button>
            <List.Item>
              <List.Item.Meta
                title={'Status Mengerjakan Post Test'}
                description={wpDetail && wpDetail.isCollectPostTest ? 'Sudah' : 'Belum'}
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={'Nilai Post Test'}
                description={wpDetail && wpDetail.isCollectPostTest ? wpDetail.postTestPoint : '-'}
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title={'Status Mengunduh Sertifikat'}
                description={wpDetail && wpDetail.isDownloadCertificate ? 'Sudah' : 'Belum'}
              />
            </List.Item>
          </Card>
        </>
      )}

      {contextHolder}
    </Content>
  )
};

export default WebinarParticipantDetail;