import { ACCOUNT_GET_FAIL, ACCOUNT_GET_SUCCESS, ACCOUNT_LOADING } from "../types";

const initialState = {
  data: [],
  total: 0,
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ACCOUNT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ACCOUNT_GET_SUCCESS:
      return {
        ...state,
        data: action.resp,
        isLoading: false,
      };
    case ACCOUNT_GET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
