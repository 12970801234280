import { combineReducers } from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import accountReducer from './AccountReducer';
import announcementReducer from './AnnouncementReducer';
import authReducer from './AuthReducer';
import evidenceReducer from './EvidenceReducer';
import historyReducer from './HistoryReducer';
import infoReducer from './InfoReducer';
import importExportReducer from './/ImportExportReducer';
import packageReducer from './PackageReducer';
import paymentReducer from './PaymentReducer';
import paymentMethodReducer from './PaymentMethodReducer';
import postestReducer from './PostTestReducer';
import settingReducer from './SettingReducer';
import userReducer from './UserReducer';
import webinarReducer from './WebinarReducer';
import webinarCategoryReducer from './WebinarCategoryReducer';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
};

const RootReducer = combineReducers({
  acnt: accountReducer,
  anno: announcementReducer,
  auth: authReducer,
  evid: evidenceReducer,
  hstr: historyReducer,
  imex: importExportReducer,
  info: infoReducer,
  pckg: packageReducer,
  pote: postestReducer,
  pymn: paymentReducer,
  pmtd: paymentMethodReducer,
  stng: settingReducer,
  user: userReducer,
  wbnr: webinarReducer,
  wbcr: webinarCategoryReducer,
});

export default persistReducer(persistConfig, RootReducer);
