import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Button, Form, Modal, Input, message, Select, Table, Tag, Space, PageHeader, Layout } from 'antd';

import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import type { ProColumns, ActionType } from '@ant-design/pro-table';
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { createPackage, getPackage, removePackage, updatePackage } from '../../../store/actions/PackageAction';
import { createEvidence, getEvidence, updateEvidence } from '../../../store/actions/EvidenceAction';
import { createWebinarCategory, getWebinarCategories, removeWebinarCategory, updateWebinarCategory } from '../../../store/actions/WebinarCategoryAction';

const { Option } = Select;
const { Header, Content } = Layout;
const routes = [
  {
    path: 'index',
    breadcrumbName: 'Dashboard',
  },
  {
    path: 'first',
    breadcrumbName: 'Paket Webinar',
  },
];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

const WebinarCategory = () => {
  const dispatch = useDispatch();
  const {info, isLoading, webCatList} = useSelector((state: any) => ({
    info: state.info,
    webCatList: state.wbcr.data,
    isLoading: state.wbcr.isLoading,
  }));

  const [page, setPage] = useState(1);
  const [form] = Form.useForm();
  const [isAddFormVisible, setAddFormVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isConfirmDelete, setConfirmDelete] = useState(false);
  const [initVal, setInitVal] = useState<any>({
    name: '',
    description: '',
  });

  useEffect(() => {
    loadWebinarCategory()
  }, []);

  useEffect(() => {
    if (info.id === 'WEBINAR_CATEGORY_CREATE_SUCCESS' || info.id === 'WEBINAR_CATEGORY_UPDATE_SUCCESS' || info.id === 'WEBINAR_CATEGORY_DELETE_SUCCESS') {
      message.success(info.msg);
      resetInitVal();
      loadWebinarCategory()
    }
    if (info.id === 'WEBINAR_CATEGORY_CREATE_FAIL' || info.id === 'WEBINAR_CATEGORY_UPDATE_FAIL' || info.id === 'WEBINAR_CATEGORY_DELETE_FAIL') {
      message.error(info.msg.message);
    }
  }, [info]);

  const loadWebinarCategory = () => {
    let param = {
      page: page,
      limit: 20,
    }
    dispatch(getWebinarCategories(param));
  }

  const onCancel = () => {
    setAddFormVisible(false);
    setIsUpdate(false);
  }

  const onSubmit = (val: any) => {
    setAddFormVisible(false);
    if (isUpdate) {
      setIsUpdate(false);
      dispatch(updateWebinarCategory(initVal._id, val));
      resetInitVal()
    } else {
      dispatch(createWebinarCategory(val));
    }
  }

  const resetInitVal = () => {
    setInitVal({
      name: '',
      description: '',
    })
  }

  const showModalConfirm = (param: any) => {
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: 'Apakah Kamu yakin akan menghapus kategori webinar ini?',
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(removeWebinarCategory(param._id));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const columns = [
    {
      title: 'Nama Kategori',
      dataIndex: 'name',
      key: 'name',
      // render: (text: any) => <a>{text}</a>,
    },
    {
      title: 'Deskripsi',
      dataIndex: 'description',
      key: 'description',
      // render: (text: any) => <a>{text}</a>,
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <a 
            onClick={() => {
              setInitVal(record);
              form.setFieldsValue(record);
              setIsUpdate(true);
              setTimeout(() => {
                setAddFormVisible(true);
              }, 1000);
            }}
          >Ubah</a>
          <a
            onClick={() => {
              showModalConfirm(record);
            }}
          >Hapus</a>
        </Space>
      ),
    },
  ];

  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        extra={[
          <Button key="3" type="primary" onClick={() => setAddFormVisible(true)}>Tambah Kategori Webinar</Button>
        ]}
      />
      <Table columns={columns} loading={isLoading} dataSource={webCatList} />

      <Modal
        visible={isAddFormVisible}
        title={isUpdate ? "Ubah kategori webinar" : "Tambah kategori webinar"}
        okText={isUpdate ? "Ubah" : "Tambah"}
        cancelText="Batal"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              form.resetFields();
              onSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:');
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={initVal}
          // initialValues={{name: initVal.name}}
        >
          <Form.Item
            name="name"
            label="Nama kategori webinar"
            rules={[{ required: true, message: 'Nama kategori harus diisi!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="description"
            label="Deskripsi"
            // rules={[{ required: true, message: 'Deskripsi harus diisi!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      {/* {modal} */}
    </Content>
  )
};

export default WebinarCategory;