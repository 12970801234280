import { Table, Tag, Space, PageHeader, Layout } from 'antd';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import type { ProColumns, ActionType } from '@ant-design/pro-table';
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Input, message, Select, Divider } from 'antd';
import { createPackage, getPackage, removePackage, updatePackage } from '../../../store/actions/PackageAction';
import { createEvidence, getEvidence, updateEvidence } from '../../../store/actions/EvidenceAction';

const { Option } = Select;
const { Header, Content } = Layout;
const routes = [
  {
    path: 'index',
    breadcrumbName: 'Dashboard',
  },
  {
    path: 'first',
    breadcrumbName: 'Paket Webinar',
  },
];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

const Package = () => {
  const dispatch = useDispatch();
  const {info, isLoading, eList} = useSelector((state: any) => ({
    info: state.info,
    eList: state.evid.data,
    isLoading: state.evid.isLoading,
  }));

  const [page, setPage] = useState(1);
  const [form] = Form.useForm();
  const [isAddFormVisible, setAddFormVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isConfirmDelete, setConfirmDelete] = useState(false);
  const [initVal, setInitVal] = useState<any>({
    type: 'share evidence',
    description: '',
  });

  useEffect(() => {
    loadEvidence()
  }, []);

  useEffect(() => {
    if (info.id === 'EVIDENCE_CREATE_SUCCESS' || info.id === 'EVIDENCE_UPDATE_SUCCESS' || info.id === 'EVIDENCE_DELETE_SUCCESS') {
      message.success(info.msg);
      resetInitVal();
      loadEvidence()
    }
    if (info.id === 'EVIDENCE_CREATE_FAIL' || info.id === 'EVIDENCE_UPDATE_FAIL' || info.id === 'EVIDENCE_DELETE_FAIL') {
      message.error(info.msg.message);
    }
  }, [info]);

  const loadEvidence = () => {
    let param = {
      page: page,
      limit: 10,
    }
    dispatch(getEvidence(param));
  }

  const onCancel = () => {
    setAddFormVisible(false);
  }

  const onSubmit = (val: any) => {
    setAddFormVisible(false);
    if (isUpdate) {
      setIsUpdate(false);
      dispatch(updateEvidence(initVal._id, val));
      resetInitVal()
    } else {
      dispatch(createEvidence(val));
    }
  }

  const resetInitVal = () => {
    setInitVal({
      name: 'share evidence',
      facilities: '',
    })
  }

  const showModalConfirm = (param: any) => {
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: 'Apakah Kamu yakin akan menghapus paket ini?',
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(removePackage(param._id));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const columns = [
    {
      title: 'Jenis Bukti Share',
      dataIndex: 'type',
      key: 'type',
      // render: (text: any) => <a>{text}</a>,
    },
    {
      title: 'Deskripsi',
      dataIndex: 'description',
      key: 'description',
      // render: (text: any) => <a>{text}</a>,
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <a 
            onClick={() => {
              setInitVal(record);
              form.setFieldsValue(record);
              setIsUpdate(true);
              setTimeout(() => {
                setAddFormVisible(true);
              }, 1000);
            }}
          >Ubah</a>
          <a
            onClick={() => {
              showModalConfirm(record);
            }}
          >Hapus</a>
        </Space>
      ),
    },
  ];

  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        extra={[
          <Button key="3" type="primary" onClick={() => setAddFormVisible(true)}>Tambah Bukti Share</Button>
        ]}
      />
      <Table columns={columns} loading={isLoading} dataSource={eList} />

      <Modal
        visible={isAddFormVisible}
        title={isUpdate ? "Ubah info bukti share" : "Tambah bukti share baru"}
        okText={isUpdate ? "Ubah" : "Tambah"}
        cancelText="Batal"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              form.resetFields();
              onSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:');
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={initVal}
          // initialValues={{name: initVal.name}}
        >
          <Form.Item
            name="type"
            label="Jenis Bukti Share"
            rules={[{ required: true, message: 'Jenis bukti share harus diisi!' }]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            name="description"
            label="Deskripsi"
            rules={[{ required: true, message: 'Deskripsi harus diisi!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      {/* {modal} */}
    </Content>
  )
};

export default Package;



const data = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    tags: ['nice', 'developer'],
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    tags: ['loser'],
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
    tags: ['cool', 'teacher'],
  },
];
