import React, { useEffect, useState } from 'react';
import { Button, Input, Layout, PageHeader, Table, Modal, Space, Tabs } from 'antd';
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import PaymentXendit from './paymentXendit';
import PaymentNotXendit from './paymentNotXendit';


const { Header, Content } = Layout;
const { Search } = Input;
const { TabPane } = Tabs;

const PaymentPage = (props: any) => {
  
  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title="Master Data"
        // breadcrumb={{ routes }}
      />
      <Tabs defaultActiveKey="1">
        <TabPane tab="Pembayaran Xendit" key="1">
          <PaymentXendit />
        </TabPane>
        <TabPane tab="Pembayaran Lainnya" key="2">
          <PaymentNotXendit />
        </TabPane>
      </Tabs>
    </Content>
  );
}

export default PaymentPage;