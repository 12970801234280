import { ANNOUNCEMENT_CREATE_FAIL, ANNOUNCEMENT_CREATE_SUCCESS, ANNOUNCEMENT_GET_FAIL, ANNOUNCEMENT_GET_SUCCESS, ANNOUNCEMENT_LOADING, ANNOUNCEMENT_REMOVE_FAIL, ANNOUNCEMENT_REMOVE_SUCCESS } from '../types';

const initialState = {
  data: [],
  total: 0,
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case ANNOUNCEMENT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ANNOUNCEMENT_GET_SUCCESS:
      return {
        ...state,
        data: action.resp.announcements,
        total: action.resp.total,
        isLoading: false,
      };
    case ANNOUNCEMENT_GET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ANNOUNCEMENT_CREATE_SUCCESS:
      return {
        ...state,
        // data: action.resp.material,
        // total: action.resp.total,
        isLoading: false,
      };
    case ANNOUNCEMENT_CREATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case ANNOUNCEMENT_REMOVE_FAIL || ANNOUNCEMENT_REMOVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
