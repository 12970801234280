import { HISTORY_GET_FAIL, HISTORY_GET_SUCCESS, HISTORY_LOADING, HISTORY_USER_ACTIVITY_GET_FAIL, HISTORY_USER_ACTIVITY_GET_SUCCESS, MOOTA_NOTIF_GET_FAIL, MOOTA_NOTIF_GET_SUCCESS } from '../types';

const initialState = {
  data: [],
  total: 0,
  userActivity: [],
  totalUserActivity: 0,
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case HISTORY_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case HISTORY_GET_SUCCESS:
      return {
        ...state,
        data: action.resp.userWebinars,
        total: action.resp.total,
        isLoading: false,
      };
    case HISTORY_GET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case HISTORY_USER_ACTIVITY_GET_SUCCESS:
      return {
        ...state,
        userActivity: action.resp.data,
        totalUserActivity: action.resp.total,
        isLoading: false,
      };
    case HISTORY_USER_ACTIVITY_GET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case MOOTA_NOTIF_GET_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case MOOTA_NOTIF_GET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
