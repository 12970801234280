import {httpRequestAxiosService} from '../../services';
import {returnErrors, returnSuccess} from './InfoAction';
import { tokenConfig } from './AuthAction';
import { ACCOUNT_GET_FAIL, ACCOUNT_GET_SUCCESS, ACCOUNT_LOADING } from '../types';

// Get History
export const getMyProfile = () => (dispatch: any, getState: any) => {
  dispatch({type: ACCOUNT_LOADING});

  httpRequestAxiosService.get(process.env.REACT_APP_MAIN_API + 'users/profile', tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: ACCOUNT_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Data profil berhasil diperoleh.', 200, 'ACCOUNT_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Data profil belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'ACCOUNT_GET_FAIL',
        ),
      );
      dispatch({
        type: ACCOUNT_GET_FAIL,
      });
  })
};