import { Table, Switch, Space, PageHeader, Layout, Progress } from 'antd';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Link, useLocation, useNavigate } from "react-router-dom";

import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Modal, Input, message, Select, Spin } from 'antd';
import { getMainObject, getMainValue } from '../../shared/helper';
import { importParticipant } from '../../store/actions/ImportExportActions';
let moment = require('moment');

const { Option } = Select;
const { Header, Content } = Layout;

const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const AddParticipantFromImport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {info, isLoading} = useSelector((state: any) => ({
    info: state.info,
    isLoading: state.imex.isLoading,
  }));

  const location = useLocation();

  const [form] = Form.useForm();
  const [selectedFile, setSelectedFile] = useState<any>(undefined);
  const [percent, setPercent] = useState(0);
  const [isShowProgress, setIsShowProgress] = useState(false);
  const duration = 35000;

  const [initVal, setInitVal] = useState<any>({
    file: '',
  });

  useEffect(() => {
    if (info.id === 'EXPORT_IMPORT_PARTICIPANT_POST_SUCCESS') {
      message.success(info.msg);
      navigate(-1)
    }
    if (info.id === 'EXPORT_IMPORT_PARTICIPANT_POST_FAIL') {
      message.error(info.msg.message);
    }
  }, [info]);

  const onFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  }; 

  const onSubmit = () => {  
    if (selectedFile) {
      handleProgress();
      const formData = new FormData();
      formData.append("file", selectedFile);
  
      dispatch(importParticipant(formData));

    } else {
      message.warning('Mohon pilih file csv terlebih dahulu');
    }
  }

  const handleProgress = () => {
    setPercent(0); // Reset progress to 0
    setIsShowProgress(true);
    const increment = 1;
    const interval = (duration / 100) * increment;

    const timer = setInterval(() => {
      setPercent((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(timer);
          return 100;
        }
        return prevProgress + increment;
      });
    }, interval);
  };

  const onBack = () => {
    navigate(-1)
  }

  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title="Import Data Participant"
      />
      <Form
        // {...formItemLayout}
        form={form}
        layout="vertical"
        name="import_form"
        initialValues={initVal}
        onFinish={onSubmit}
        scrollToFirstError
      >
        <Form.Item 
          name="related_image" 
          label="Pilih File CSV yang akan di-import (direkomendasikan paling banyak 1500 baris data)"
        >
          <Input type="file" onChange={onFileChange} />
        </Form.Item>

        {isShowProgress && (
          <Form.Item>
            <Progress percent={percent} />
          </Form.Item>
        )}

        <Form.Item {...tailLayout}>
          <Button htmlType="button" onClick={onBack}>
            Kembali
          </Button>
          <Button type="primary" htmlType="submit">
            Import
          </Button>
        </Form.Item>
      </Form>

    </Content>
  )
};

export default AddParticipantFromImport;
