import { WEBINAR_CATEGORY_CREATE_FAIL, WEBINAR_CATEGORY_CREATE_SUCCESS, WEBINAR_CATEGORY_DELETE_FAIL, WEBINAR_CATEGORY_DELETE_SUCCESS, WEBINAR_CATEGORY_GET_FAIL, WEBINAR_CATEGORY_GET_SUCCESS, WEBINAR_CATEGORY_LOADING, WEBINAR_CATEGORY_UPDATE_FAIL, WEBINAR_CATEGORY_UPDATE_SUCCESS } from '../types';

const initialState = {
  data: [],
  total: 0,
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case WEBINAR_CATEGORY_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case WEBINAR_CATEGORY_GET_SUCCESS:
      return {
        ...state,
        data: action.resp.wCategory,
        total: action.resp.total,
        isLoading: false,
      };
    case WEBINAR_CATEGORY_GET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_CATEGORY_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_CATEGORY_CREATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_CATEGORY_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_CATEGORY_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_CATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case WEBINAR_CATEGORY_DELETE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
