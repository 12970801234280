import { Modal, Input, Select, PageHeader, Layout, Button, Form, Space, Card, message } from 'antd';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getMutation, getPaymentList, getXenditInvoices } from '../../store/actions/PaymentAction';
import { formatUang2, roleUser } from '../../shared/helper';
import { clearInfo } from '../../store/actions/InfoAction';
import { getMyProfile } from '../../store/actions/AccountActions';
import rs_img from '../../shared/assets/img/ruang_seminar_01.jpg';

let moment = require('moment');
const { Header, Content } = Layout;

const ProfilePage = () => {
  const dispatch = useDispatch();
  const {info, isLoading, accountData, userData} = useSelector((state: any) => ({
    info: state.info,
    accountData: state.acnt.data,
    isLoading: state.acnt.isLoading,
    userData: state.auth.user,
  }));

  // console.log('isi user data', userData)
  // console.log('isi account data', accountData)

  const [form] = Form.useForm();

  const [sValue, setSearchValue] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [initVal, setInitVal] = useState<any>({
    name: userData && userData.name,
    email: userData && userData.email,
    phone: userData && userData.phone,
    role: userData && userData.role ? roleUser(userData.role) : '-' ,
  });

  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    if (info.id === 'SETTING_CREATE_SUCCESS') {
      setTimeout(() => {
        message.success('Penyesuaian konfigurasi berhasil dilakukan.');
        // loadSetting();
        // modal.success(configSuccess);
      }, 300);
    }
    if (info.id === 'SETTING_CREATE_FAIL') {
      setTimeout(() => {
        message.error('Penyesuaian konfigurasi belum berhasil dilakukan.');
      }, 200);
    }
  }, info);

  useEffect(() => {
    let initValue = {
      // isShowXenditOptionPayment: settingData && (settingData.length > 0) && settingData[0].isShowXenditOptionPayment || '',
      // isShowManualOptionPayment: settingData && (settingData.length > 0) && settingData[0].isShowManualOptionPayment || '',
    }
    setInitVal(initValue);
  }, [accountData])

  useEffect(() => {
    loadProfile()
  }, [sValue]);

  const loadProfile = () => {
    // dispatch(getSetting());
    dispatch(getMyProfile())
  }

  const configSuccess = {
    title: 'Berhasil',
    centered: true,
    content: (<div><p>{'Penyesuaian konfigurasi berhasil dilakukan'}</p></div>),
    onOk() {
      dispatch(clearInfo())
      form.resetFields();
      loadProfile();
      setIsUpdate(false);
    }
  };

  const showModalConfirm = (param: any) => {
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: 'Apakah Kamu yakin akan menghapus material ini?',
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        // dispatch(removeMaterial(param._id));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const onSubmitForm = (values: any) => {
    let payload = {
      // settingId: (settingData && settingData.length > 0 && settingData[0]._id) || '',
      user: userData._id, 
      userType: userData.role, 
      settingType: 'general',
      isShowXenditOptionPayment: values.isShowXenditOptionPayment,
      isShowManualOptionPayment: values.isShowManualOptionPayment
    }
    // dispatch(adjustmentSetting(payload));
  }

  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title="Profil"
        // breadcrumb={{ routes }}
      />

      <Card>
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={initVal}
          onFinish={() => {
            form
              .validateFields()
              .then((values: any) => {
                onSubmitForm(values);
              })
              .catch((info: any) => {
                console.log('Validate Failed:', info);
              });
          }}
          // initialValues={{name: initVal.name}}
        >
          <Form.Item>
            <img
              width={200}
              alt="avatar"
              src={
                userData && userData.avatar ?
                  (process.env.REACT_APP_WEB + userData.avatar) : rs_img
              }
              // src={rs_img}
              // onClick={() => onChangePoster()}
            />
          </Form.Item>
          <Form.Item name="name" label="Nama">
            <Input disabled />
          </Form.Item>

          <Form.Item name="email" label="Email">
            <Input disabled />
          </Form.Item>

          <Form.Item name="phone" label="No HP">
            <Input disabled />
          </Form.Item>

          <Form.Item name="role" label="Role">
            <Input disabled />
          </Form.Item>

          {/* <Form.Item>
            <Space>
              <Button type="default" onClick={() => setIsUpdate(!isUpdate)}>{isUpdate ? 'Batal' : 'Ubah'}</Button>
              <Button type="primary" htmlType="submit">Simpan</Button>
            </Space>
          </Form.Item> */}
        </Form>
      </Card>
      {contextHolder}
    </Content>
  )
};

export default ProfilePage;