import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { 
  Card, 
  Col, 
  DatePicker,
  Form,
  Menu,
  Modal,
  Layout,
  Row, 
  Statistic,
  Tabs, 
  Typography,
  Tooltip,
} from 'antd';

import {
  DownOutlined,
  BookTwoTone,
  UserOutlined
} from "@ant-design/icons";
import { getWebinar } from '../../store/actions/WebinarAction';
import { getUser } from '../../store/actions/UserAction';
import { getHistory } from '../../store/actions/HistoryAction';
import { getPackage } from '../../store/actions/PackageAction';
import { getSetting } from '../../store/actions/SettingAction';

const { RangePicker } = DatePicker;
const { Header, Content } = Layout;
const { Title, Text, Link } = Typography;
const { TabPane } = Tabs;
let moment = require('moment');

const routes = [
  {
    path: 'index',
    breadcrumbName: 'Dasbor',
  },
];

const gridStyle1 = {
  width: '50%',
  textAlign: 'center',
};

const Dashboard = (props: any) => {
  const dispatch = useDispatch();
  const {info, wLoading, wTotal, uLoading, uTotal, hLoading, hTotal} = useSelector((state: any) => ({
    info: state.info,
    wTotal: state.wbnr.total,
    wLoading: state.wbnr.isLoading,
    uTotal: state.user.total,
    uLoading: state.user.isLoading,
    hTotal: state.hstr.total,
    hLoading: state.hstr.isLoading,
  }));

  const [isOpenCustomRange, setIsOpenCustomRange] = useState(false);
  const [dData, setDData] = useState(undefined);
  const [mData, setMData] = useState(undefined);
  const [title, setTitle] = useState(`Hari Ini | ${moment().format('DD-MM-YYYY')}`);
  const [form] = Form.useForm();
  const [initVal, setInitVal] = useState({
    nama: '',
    deskripsi: '',
  });

  useEffect(() => {
    dispatch(getWebinar(undefined));
    dispatch(getUser(undefined));
    dispatch(getHistory(undefined));
    dispatch(getPackage());
    dispatch(getSetting());
  }, []);
  
  const onSubmitCustomRange = (values: any) => {
    setIsOpenCustomRange(false);
    let payload = {
      param: 'custom',
      start: values.begindate,
      end: values.enddate,
    }

    // loadDData(payload);
  }

  const onFilterActive = (param: any) => {
    let start = new Date();
    let end = new Date().toISOString();

    if (param !== 'custom') {
      if (param === 'today') {
        start = new Date();
        end = new Date().toISOString();
        setTitle(`Hari Ini | ${moment(start).format('DD-MM-YYYY')}`);
      } else if (param === 'lastweek') {
        start = moment(start).subtract(1, 'week').startOf('week').toISOString();
        setTitle(`1 Minggu Terakhir | ${moment(start).format('DD-MM-YYYY')} - ${moment(end).format('DD-MM-YYYY')}`);
        // setTitle(`1 Minggu Terakhir | ${moment(start).format('DD-MM-YYYY h:mm:ss')} - ${moment(end).format('DD-MM-YYYY h:mm:ss')}`);
      } else if (param === 'lastmonth') {
        start = moment(start).subtract(1, 'month').startOf('month').toISOString();
        setTitle(`1 Bulan Terakhir | ${moment(start).format('DD-MM-YYYY')} - ${moment(end).format('DD-MM-YYYY')}`);
      } else if (param === 'last3month') {
        start = moment(start).subtract(3, 'month').startOf('month').toISOString();
        setTitle(`3 Bulan Terakhir | ${moment(start).format('DD-MM-YYYY')} - ${moment(end).format('DD-MM-YYYY')}`);
      } else if (param === 'lastyear') {
        start = moment(start).subtract(1, 'year').startOf('year').toISOString();
        setTitle(`1 Tahun Terakhir | ${moment(start).format('DD-MM-YYYY')} - ${moment(end).format('DD-MM-YYYY')}`);
      }
      let payload = {param, start, end};
      // loadDData(payload);

    } else {
      setIsOpenCustomRange(true);
    }
  }

  const fieldConfig = {
    rules: [{ type: 'object', required: true, message: 'Tolong pilih tanggal!' }],
  };

  const rangeConfig = {
    rules: [{ type: 'array', required: true, message: 'Tolong pilih tanggal!' }],
  };

  const COLOR_PLATE_10 = [
    '#5B8FF9',
    '#5AD8A6',
    '#5D7092',
    '#F6BD16',
    '#E8684A',
    '#6DC8EC',
    '#9270CA',
    '#FF9D4D',
    '#269A99',
    '#FF99C3',
  ];
  // const config = {
  //   data: dData && dData.printcost_vs_donation && dData.printcost_vs_donation.cetakvsdonasi ? dData.printcost_vs_donation.cetakvsdonasi : [],
  //   xField: 'date',
  //   yField: 'nominal',
  //   seriesField: 'type',
  //   yAxis: {
  //     label: {
  //       formatter: (v) => `${v}`.replace(/\d{1,3}(?=(\d{3})+$)/g, (s) => `${s},`),
  //     },
  //   },
  //   color: COLOR_PLATE_10, 
  //   point: {
  //     shape: ({ type }) => {
  //       return type === 'bcetak' ? 'square' : 'circle';
  //     },
  //   },
  // };

  // distribution book by book
  // const configCol1 = {
  //   data: dData && dData.pengiriman_buku_by_book ? dData.pengiriman_buku_by_book : [],
  //   xField: 'judul_buku',
  //   yField: 'bookCount',
  //   label: {
  //     position: 'middle',
  //     style: {
  //       fill: '#FFFFFF',
  //       opacity: 0.6,
  //     },
  //   },
  //   xAxis: {
  //     label: {
  //       autoHide: true,
  //       autoRotate: false,
  //     },
  //   },
  //   meta: {
  //     bookCount: {
  //       alias: 'Jumlah Pengiriman',
  //     },
  //     judul_buku: {
  //       alias: 'Judul Buku',
  //     },
  //   },
  // };

  // distribution book by Customer
  // const configCol2 = {
  //   data: dData && dData.pengiriman_buku_by_customer ? dData.pengiriman_buku_by_customer : [],
  //   xField: 'pelanggan',
  //   yField: 'customerCount',
  //   label: {
  //     position: 'middle',
  //     style: {
  //       fill: '#FFFFFF',
  //       opacity: 0.6,
  //     },
  //   },
  //   xAxis: {
  //     label: {
  //       autoHide: true,
  //       autoRotate: false,
  //     },
  //   },
  //   meta: {
  //     customerCount: {
  //       alias: 'Jumlah Pengiriman',
  //     },
  //     pelanggan: {
  //       alias: 'Pelanggan',
  //     },
  //   },
  // };

  // distribution book by Customer category
  // const configCol3 = {
  //   data: dData && dData.pengiriman_buku_by_customer_cat ? dData.pengiriman_buku_by_customer_cat : [],
  //   xField: 'kategori_pelanggan',
  //   yField: 'cCatCount',
  //   label: {
  //     position: 'middle',
  //     style: {
  //       fill: '#FFFFFF',
  //       opacity: 0.6,
  //     },
  //   },
  //   xAxis: {
  //     label: {
  //       autoHide: true,
  //       autoRotate: false,
  //     },
  //   },
  //   meta: {
  //     cCatCount: {
  //       alias: 'Jumlah Pengiriman',
  //     },
  //     kategori_pelanggan: {
  //       alias: 'Kategori Pelanggan',
  //     },
  //   },
  // };

  const menu = (
    <Menu>
      <Menu.Item key={0} onClick={() => onFilterActive('today')}>
        Hari Ini
      </Menu.Item>
      <Menu.Item key={1} onClick={() => onFilterActive('lastweek')}>
        1 Minggu Terakhir
      </Menu.Item>
      <Menu.Item key={2} onClick={() => onFilterActive('lastmonth')}>
        1 Bulan Terakhir
      </Menu.Item>
      <Menu.Item key={3} onClick={() => onFilterActive('last3month')}>
        3 Bulan Terakhir
      </Menu.Item>
      <Menu.Item key={4} onClick={() => onFilterActive('lastyear')}>
        1 Tahun Terakhir
      </Menu.Item>
      <Menu.Item key={5} onClick={() => onFilterActive('custom')}>
        Custom
      </Menu.Item>
    </Menu>
  );
 
  return (
    <Content
      className="site-layout-background"
      style={{
        margin: '8px 16px',
        paddingLeft: 12,
        paddingRight: 12,
        minHeight: 600,
      }}
    >
      <Row gutter={[16, 16]}>
        {/* <Col span={24} style={{alignItems: 'flex-end', justifyContent: 'flex-end'}}>
          <Card bordered>
            <Title level={3}>{title}</Title>
            <Dropdown overlay={menu} >
              <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                Filter <DownOutlined />
              </a>
            </Dropdown>
          </Card>
        </Col> */}
        <Col xs={24} sm={24} md={8}>
          <Card bordered>
            <Tooltip title={'Webinar'}>
              <Statistic title="Webinar" value={wTotal ? wTotal : 0} prefix={<BookTwoTone twoToneColor="#F63E4F" />} />
            </Tooltip>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Card bordered>
            <Tooltip title={'Peserta Terdaftar'}>
              <Statistic title="Peserta Terdaftar" value={uTotal ? uTotal : 0} prefix={<UserOutlined twoToneColor="#27C7FF" />} />
            </Tooltip>
          </Card>
        </Col>
        <Col xs={24} sm={24} md={8}>
          <Card bordered>
            <Tooltip title={'Jumlah peserta yang mendaftar webinar'}>
              <Statistic title="Peserta Webinar" value={hTotal ? hTotal : 0} prefix={<UserOutlined twoToneColor="#27C7FF" />} />
            </Tooltip>
          </Card>
        </Col>
        {/* <Col xs={24} sm={24} md={6}>
          <Card bordered>
            <Tooltip title={'Pengiriman'}>
              <Statistic title="Pengiriman" value={300} prefix={<BookTwoTone twoToneColor="#27C7FF" />} />
            </Tooltip>
          </Card>
        </Col> */}
        {/* {contents && contents.map((item: any, ind: any) => {
          return (
            <Col key={ind} xs={24} sm={24} md={6}>
              <Card bordered>
                <Statistic title={item._id} value={item.total} prefix={<FolderTwoTone twoToneColor="#F63E4F" />} />
              </Card>
            </Col>    
          )
        })} */}

        {/* Baris 2 */}
        {/* <Col xs={24} sm={24} md={24}>
          <Card bordered>
            <Title level={4}>Biaya Cetak vs Donasi</Title>
            <Line {...config} />
          </Card>
        </Col> */}

        {/* Baris 3 */}
        {/* distribution book by book */}
        {/* <Col xs={24} sm={24} md={24}>
          <Card bordered>
            <Title level={4}>Distribusi Buku berdasarkan Judul Buku</Title>
            <Column {...configCol1} />
          </Card>
        </Col> */}
        {/* distribution book by customer */}
        {/* <Col xs={24} sm={24} md={24}>
          <Card bordered>
            <Title level={4}>Distribusi Buku berdasarkan Pelanggan</Title>
            <Column {...configCol2} />
          </Card>
        </Col> */}
        {/* distribution book by customer category */}
        {/* <Col xs={24} sm={24} md={24}>
          <Card bordered>
            <Title level={4}>Distribusi Buku berdasarkan Kategori Pelanggan</Title>
            <Column {...configCol3} />
          </Card>
        </Col> */}
      </Row>
      
      <Modal
        visible={isOpenCustomRange}
        title={"Masukan tanggal"}
        okText={"Filter"}
        cancelText="Batal"
        onCancel={() => setIsOpenCustomRange(false)}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              form.resetFields();
              onSubmitCustomRange(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={initVal}
        >
          <Form.Item name="begindate" label="Tanggal Awal">
            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>

          <Form.Item name="enddate" label="Tanggal Akhir">
            <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
          </Form.Item>
        </Form>
      </Modal>
    </Content>
  );
}

export default Dashboard;

const data = [
  { year: '1991', value: 3 },
  { year: '1992', value: 4 },
  { year: '1993', value: 3.5 },
  { year: '1994', value: 5 },
  { year: '1995', value: 4.9 },
  { year: '1996', value: 6 },
  { year: '1997', value: 7 },
  { year: '1998', value: 9 },
  { year: '1999', value: 13 },
];