import {httpRequestAxiosService} from '../../services';
import {returnErrors, returnInfo, returnSuccess} from './InfoAction';
import {
  WEBINAR_LOADING,
  WEBINAR_CREATE_SUCCESS,
  WEBINAR_CREATE_FAIL,
  WEBINAR_GET_SUCCESS,
  WEBINAR_GET_FAIL,
  WEBINAR_UPDATE_SUCCESS,
  WEBINAR_UPDATE_FAIL,
  WEBINAR_DELETE_SUCCESS,
  WEBINAR_DELETE_FAIL,
  WEBINAR_ADD_PACKAGE_SUCCESS,
  WEBINAR_ADD_PACKAGE_FAIL,
  WEBINAR_PARTICIPANT_GET_SUCCESS,
  WEBINAR_PARTICIPANT_GET_FAIL,
  WEBINAR_DETAIL_GET_SUCCESS,
  WEBINAR_DETAIL_GET_FAIL,
  WEBINAR_ADD_CERTIFICATE_SUCCESS,
  WEBINAR_ADD_CERTIFICATE_FAIL,
  WEBINAR_VALIDATE_W_REGIS_SUCCESS,
  WEBINAR_VALIDATE_W_REGIS_FAIL,
  WEBINAR_ADD_SPEAKER_AVATAR_SUCCESS,
  WEBINAR_ADD_SPEAKER_AVATAR_FAIL,
  WEBINAR_ADD_CERTIFICATE_USER_SUCCESS,
  WEBINAR_ADD_CERTIFICATE_USER_FAIL,
  WEBINAR_ADD_POSTER_SUCCESS,
  WEBINAR_ADD_POSTER_FAIL,
  WEBINAR_VALIDATE_PAYMENT_MOOTA_SUCCESS,
  WEBINAR_VALIDATE_PAYMENT_MOOTA_FAIL,
  WEBINAR_ADD_VBG_SUCCESS,
  WEBINAR_ADD_VBG_FAIL,
  WEBINAR_VALIDATE_PARTICIPANT_BASIC_SUCCESS,
  WEBINAR_VALIDATE_PARTICIPANT_BASIC_FAIL,
  WEBINAR_UPDATE_STATUS_SUCCESS,
  WEBINAR_UPDATE_STATUS_FAIL,
  WEBINAR_REFRESH_STATUS_SUCCESS,
  WEBINAR_REFRESH_STATUS_FAIL,
  WEBINAR_PARTICIPANT_DETAIL_GET_SUCCESS,
  WEBINAR_PARTICIPANT_DETAIL_GET_FAIL,
  WEBINAR_PARTICIPANT_RESET_NO_CERT_SUCCESS,
  WEBINAR_PARTICIPANT_RESET_NO_CERT_FAIL,
  WEBINAR_USER_GET_SUCCESS,
  WEBINAR_USER_GET_FAIL,
  WEBINAR_GENERATE_INVOICE_SUCCESS,
  WEBINAR_GENERATE_INVOICE_FAIL,
} from '../types';
import { tokenConfig } from './AuthAction';

// Get Webinar
export const getWebinar = (qparam: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});
  let param = {
    page: qparam && qparam.page ? qparam.page : 1,
    limit: qparam && qparam.limit ? qparam.limit : 10,
  };

  httpRequestAxiosService.get(process.env.REACT_APP_MAIN_API + 'webinars/admin?page=' + param.page + '&limit=' + param.limit + '&sortby=createdAt&order=desc', tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Daftar webinar berhasil diperoleh.', 200, 'WEBINAR_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Data webinar belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'WEBINAR_GET_FAIL',
        ),
      );
      dispatch({
        type: WEBINAR_GET_FAIL,
      });
  })
};

// Get Webinar
export const getWebinarUser = (qparam: any, payload: any = {isShowByUser: true}) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});
  let param = {
    page: qparam && qparam.page ? qparam.page : 1,
    limit: qparam && qparam.limit ? qparam.limit : 100,
  };

  httpRequestAxiosService.post(process.env.REACT_APP_MAIN_API + 'webinars/get-webinar-user?page=' + param.page + '&limit=' + param.limit + '&sortby=createdAt&order=desc', payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_USER_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Daftar webinar berhasil diperoleh.', 200, 'WEBINAR_USER_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Data webinar belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'WEBINAR_USER_GET_FAIL',
        ),
      );
      dispatch({
        type: WEBINAR_USER_GET_FAIL,
      });
  })
};

// add new webinar
export const createWebinar = (payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});

  httpRequestAxiosService.post(process.env.REACT_APP_MAIN_API + 'webinars', payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_CREATE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Webinar berhasil ditambahkan.', 200, 'WEBINAR_CREATE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnInfo(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'WEBINAR_CREATE_FAIL',
          'Webinar belum berhasil ditambahkan',
        ),
      );
      dispatch({
        type: WEBINAR_CREATE_FAIL,
      });
  })
};

// add new webinar
export const updateWebinar = (wId: any, payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});

  httpRequestAxiosService.put(process.env.REACT_APP_MAIN_API + 'webinars/' + wId, payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_UPDATE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Webinar berhasil diubah.', 200, 'WEBINAR_UPDATE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnInfo(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'WEBINAR_UPDATE_FAIL',
          'Webinar belum berhasil diubah',
        ),
      );
      dispatch({
        type: WEBINAR_UPDATE_FAIL,
      });
  })
};

// Load Webinar Detail
export const loadWebinarDetail = (wId: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});

  httpRequestAxiosService.get(process.env.REACT_APP_MAIN_API + 'webinars/' + wId, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_DETAIL_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Rincian webinar berhasil diperoleh.', 200, 'WEBINAR_DETAIL_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Rincian webinar belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'WEBINAR_DETAIL_GET_FAIL',
        ),
      );
      dispatch({
        type: WEBINAR_DETAIL_GET_FAIL,
      });
  })
};

// Refresh status Webinar
export const refreshWebinarStatus = () => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});
  
  httpRequestAxiosService.get(process.env.REACT_APP_MAIN_API + 'webinars/bulkupdatestatuswebinar', tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_REFRESH_STATUS_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Refresh status webinar berhasil.', 200, 'WEBINAR_REFRESH_STATUS_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Refresh status webinar belum berhasil',
          (err && err.response && err.response.status) || 401,
          'WEBINAR_REFRESH_STATUS_FAIL',
        ),
      );
      dispatch({
        type: WEBINAR_REFRESH_STATUS_FAIL,
      });
  })
};

// Load Webinar Participant
export const loadWebinarParticipant = (wId: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});

  httpRequestAxiosService.get(process.env.REACT_APP_MAIN_API + 'webinars/'+wId+'/users', tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_PARTICIPANT_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Peserta webinar berhasil diperoleh.', 200, 'WEBINAR_PARTICIPANT_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Peserta webinar belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'WEBINAR_PARTICIPANT_GET_FAIL',
        ),
      );
      dispatch({
        type: WEBINAR_PARTICIPANT_GET_FAIL,
      });
  })
};

// add package to webinar
export const addPackageToWebinar = (wId: any, payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});

  httpRequestAxiosService.put(process.env.REACT_APP_MAIN_API + 'webinars/' + wId + '/packages', payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_ADD_PACKAGE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Paket berhasil ditambahkan.', 200, 'WEBINAR_ADD_PACKAGE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'WEBINAR_ADD_PACKAGE_FAIL',
          'Paket belum berhasil ditambahkan',
        ),
      );
      dispatch({
        type: WEBINAR_ADD_PACKAGE_FAIL,
      });
  })
};

// upload webinar certificate
export const uploadUserCertificate = (wId: any, uId: any, payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});

  httpRequestAxiosService.put(process.env.REACT_APP_MAIN_API + 'users/' + uId + '/webinars/' + wId + '/certificate', payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_ADD_CERTIFICATE_USER_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Sertifikat berhasil ditambahkan.', 200, 'WEBINAR_ADD_CERTIFICATE_USER_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'WEBINAR_ADD_CERTIFICATE_USER_FAIL',
          'Sertifikat belum berhasil ditambahkan',
        ),
      );
      dispatch({
        type: WEBINAR_ADD_CERTIFICATE_USER_FAIL,
      });
  })
};

// validasi registrasi webinar
export const validateWRegis = (wId: any, uId: any, payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});

  httpRequestAxiosService.put(process.env.REACT_APP_MAIN_API + 'users/' + uId + '/webinars/' + wId + '/validate', payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_VALIDATE_W_REGIS_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Validasi pendaftaran webinar berhasil.', 200, 'WEBINAR_VALIDATE_W_REGIS_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'WEBINAR_VALIDATE_W_REGIS_FAIL',
          'Validasi pendaftaran webinar belum berhasil',
        ),
      );
      dispatch({
        type: WEBINAR_VALIDATE_W_REGIS_FAIL,
      });
  })
};

// get participant detail in webinar
export const loadParticipantDetail = (uwId: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});

  httpRequestAxiosService.get(process.env.REACT_APP_MAIN_API + 'users/webinar/' + uwId, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_PARTICIPANT_DETAIL_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Ambil data peserta webinar berhasil.', 200, 'WEBINAR_PARTICIPANT_DETAIL_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'WEBINAR_PARTICIPANT_DETAIL_GET_FAIL',
          'Ambil data peserta webinar belum berhasil',
        ),
      );
      dispatch({
        type: WEBINAR_PARTICIPANT_DETAIL_GET_FAIL,
      });
  })
};

// reset cert number by admin
export const resetCertificateNumber = (uId: any, wId: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});

  httpRequestAxiosService.put(process.env.REACT_APP_MAIN_API + `users/${uId}/webinars/${wId}/resetnumbercertificate`, {}, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_PARTICIPANT_RESET_NO_CERT_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Reset no sertifikat berhasil.', 200, 'WEBINAR_PARTICIPANT_RESET_NO_CERT_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'WEBINAR_PARTICIPANT_RESET_NO_CERT_FAIL',
          'Reset no sertifikat belum berhasil',
        ),
      );
      dispatch({
        type: WEBINAR_PARTICIPANT_RESET_NO_CERT_FAIL,
      });
  })
};

// add speaker avatar
export const addSpeakerAvatar = (wId: any, sId: any, payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});

  httpRequestAxiosService.put(process.env.REACT_APP_MAIN_API + 'webinars/' + wId + '/speakers/' + sId, payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_ADD_SPEAKER_AVATAR_SUCCESS,
        resp: resp, 
      });
      dispatch(returnSuccess('Berhasil menambahkan avatar pemateri.', 200, 'WEBINAR_ADD_SPEAKER_AVATAR_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'WEBINAR_ADD_SPEAKER_AVATAR_FAIL',
          'Avatar pemateri belum berhasil ditambahkan',
        ),
      );
      dispatch({
        type: WEBINAR_ADD_SPEAKER_AVATAR_FAIL,
      });
  })
};

// add certificate
export const addWebinarCertificate = (wId: any, payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});

  httpRequestAxiosService.put(`${process.env.REACT_APP_MAIN_API}webinars/${wId}/certificate`, payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_ADD_CERTIFICATE_SUCCESS,
        resp: resp, 
      });
      dispatch(returnSuccess('Berhasil menambahkan template sertifikat.', 200, 'WEBINAR_ADD_CERTIFICATE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'WEBINAR_ADD_CERTIFICATE_FAIL',
          'Template sertifikat belum berhasil ditambahkan',
        ),
      );
      dispatch({
        type: WEBINAR_ADD_CERTIFICATE_FAIL,
      });
  })
};

// add poster
export const addWebinarPoster = (wId: any, payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});

  httpRequestAxiosService.put(`${process.env.REACT_APP_MAIN_API}webinars/${wId}/poster`, payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_ADD_POSTER_SUCCESS,
        resp: resp, 
      });
      dispatch(returnSuccess('Berhasil menambahkan poster webinar.', 200, 'WEBINAR_ADD_POSTER_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'WEBINAR_ADD_POSTER_FAIL',
          'Poster webinar belum berhasil ditambahkan',
        ),
      );
      dispatch({
        type: WEBINAR_ADD_POSTER_FAIL,
      });
  })
};

// add vbg
export const addWebinarVBG = (wId: any, payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});

  httpRequestAxiosService.put(`${process.env.REACT_APP_MAIN_API}webinars/${wId}/vbg`, payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_ADD_VBG_SUCCESS,
        resp: resp, 
      });
      dispatch(returnSuccess('Berhasil menambahkan virtual background webinar.', 200, 'WEBINAR_ADD_VBG_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'WEBINAR_ADD_VBG_FAIL',
          'Virtual Backgrond webinar belum berhasil ditambahkan',
        ),
      );
      dispatch({
        type: WEBINAR_ADD_VBG_FAIL,
      });
  })
};

// remove webinar
export const removeWebinar = (paramId: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});

  httpRequestAxiosService.delete(process.env.REACT_APP_MAIN_API + 'webinars/' + paramId, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_DELETE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Webinar berhasil dihapus.', 200, 'WEBINAR_DELETE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'WEBINAR_DELETE_FAIL',
          'Webinar belum berhasil dihapus',
        ),
      );
      dispatch({
        type: WEBINAR_DELETE_FAIL,
      });
  })
};

// Validasi pembayaran via moota
export const validatePaymentMoota = (wId: any, uId: any, payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});

  httpRequestAxiosService.put(process.env.REACT_APP_MAIN_API + 'users/' + uId + '/webinars/' + wId + '/invoice', payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_VALIDATE_PAYMENT_MOOTA_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Validasi pembayaran webinar berhasil.', 200, 'WEBINAR_VALIDATE_PAYMENT_MOOTA_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnInfo(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'WEBINAR_VALIDATE_PAYMENT_MOOTA_FAIL',
          'Validasi pembayaran webinar belum berhasil',
        ),
      );
      dispatch({
        type: WEBINAR_VALIDATE_PAYMENT_MOOTA_FAIL,
      });
  })
};

// Validasi partisipan paket basic
export const validateBasicParticipant = (wId: any, payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});

  httpRequestAxiosService.put(process.env.REACT_APP_MAIN_API + 'webinars/' + wId + '/users-basic-validate', payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_VALIDATE_PARTICIPANT_BASIC_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Validasi pendaftaran webinar paket basic berhasil.', 200, 'WEBINAR_VALIDATE_PARTICIPANT_BASIC_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnInfo(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'WEBINAR_VALIDATE_PARTICIPANT_BASIC_FAIL',
          'Validasi pendaftaran webinar paket basic belum berhasil',
        ),
      );
      dispatch({
        type: WEBINAR_VALIDATE_PARTICIPANT_BASIC_FAIL,
      });
  })
};

// Update show status webinar
export const updateStatusWebinar = (wId: any, payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});

  httpRequestAxiosService.put(process.env.REACT_APP_MAIN_API + 'webinars/' + wId + '/update-status', payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_UPDATE_STATUS_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Ubah status webinar berhasil.', 200, 'WEBINAR_UPDATE_STATUS_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnInfo(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'WEBINAR_UPDATE_STATUS_FAIL',
          'Ubah status webinar belum berhasil',
        ),
      );
      dispatch({
        type: WEBINAR_UPDATE_STATUS_FAIL,
      });
  })
};

// generate invoice for user webinar
export const generateUserInvoiceWebinar = (wId: any, uId: any, payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_LOADING});

  httpRequestAxiosService.put(`${process.env.REACT_APP_MAIN_API}webinars/${wId}/user/${uId}/generate-invoice`, payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_GENERATE_INVOICE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Ubah status webinar berhasil.', 200, 'WEBINAR_GENERATE_INVOICE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnInfo(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'WEBINAR_GENERATE_INVOICE_FAIL',
          'Ubah status webinar belum berhasil',
        ),
      );
      dispatch({
        type: WEBINAR_GENERATE_INVOICE_FAIL,
      });
  })
};