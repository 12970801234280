import './App.css';

import React, {useEffect} from 'react';
import Routes from './routes';

import reportWebVitals from './reportWebVitals';

import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react'

import {store, persistor} from './store';

import { ConfigProvider } from 'antd';
import idID from 'antd/lib/locale/id_ID';
import { GlobalDebug } from './utils/remove-consoles';

// ... normal setup, create store and persistor, import components etc.

const App = () => {
  // useEffect(() => {
  //   (process.env.REACT_APP_ENV === 'production') && GlobalDebug(false);
  // }, []);
  if (process.env.REACT_APP_ENV === 'production') {
    console.log = () => {}
    console.error = () => {}
    console.debug = () => {}
  }

  return (
    <Provider store={store}>
      <BrowserRouter>
        <PersistGate persistor={persistor}>
          <ConfigProvider locale={idID}>
            <Routes />
          </ConfigProvider>
        </PersistGate>
      </BrowserRouter>
    </Provider>
  );
};

export default App;