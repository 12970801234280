import {
  PACKAGE_CREATE_FAIL,
  PACKAGE_CREATE_SUCCESS,
  PACKAGE_DELETE_FAIL,
  PACKAGE_DELETE_SUCCESS,
  PACKAGE_GET_FAIL,
  PACKAGE_GET_SUCCESS,
  PACKAGE_LOADING,
  PACKAGE_UPDATE_FAIL,
  PACKAGE_UPDATE_SUCCESS,
} from '../types';

const initialState = {
  data: [],
  total: 0,
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case PACKAGE_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case PACKAGE_GET_SUCCESS:
      return {
        ...state,
        data: action.resp.packages,
        total: action.resp.total,
        isLoading: false,
      };
    case PACKAGE_GET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case PACKAGE_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case PACKAGE_CREATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case PACKAGE_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case PACKAGE_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case PACKAGE_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case PACKAGE_DELETE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
