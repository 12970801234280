import {httpRequestAxiosService} from '../../services';
import {returnErrors, returnSuccess} from './InfoAction';
import { EXPORT_IMPORT_LOADING, EXPORT_IMPORT_PARTICIPANT_GET_FAIL, EXPORT_IMPORT_PARTICIPANT_GET_SUCCESS, EXPORT_IMPORT_PARTICIPANT_POST_FAIL, EXPORT_IMPORT_PARTICIPANT_POST_SUCCESS, EXPORT_IMPORT_PARTICIPANT_REMOVE_ALL_FAIL, EXPORT_IMPORT_PARTICIPANT_REMOVE_ALL_SUCCESS, EXPORT_IMPORT_PARTICIPANT_REMOVE_BY_ID_FAIL, EXPORT_IMPORT_PARTICIPANT_REMOVE_BY_ID_SUCCESS } from '../types';
import { tokenConfig, tokenConfig2 } from './AuthAction';

// Get Imported Participant
export const getImportedParticipant = (qparam: any = undefined) => (dispatch: any, getState: any) => {
  dispatch({type: EXPORT_IMPORT_LOADING});
  let param = {
    page: qparam && qparam.page ? qparam.page : 1,
    limit: qparam && qparam.limit ? qparam.limit : 10,
  };
  httpRequestAxiosService.get(process.env.REACT_APP_MAIN_API + `old-participant?page=${param.page}&limit=${param.limit}`, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: EXPORT_IMPORT_PARTICIPANT_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Data berhasil diperoleh.', 200, 'EXPORT_IMPORT_PARTICIPANT_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Data belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'EXPORT_IMPORT_PARTICIPANT_GET_FAIL',
        ),
      );
      dispatch({
        type: EXPORT_IMPORT_PARTICIPANT_GET_FAIL,
      });
  })
};

// import participant data
export const importParticipant = (payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: EXPORT_IMPORT_LOADING});

  httpRequestAxiosService.post(process.env.REACT_APP_MAIN_API + 'csv/upload-participant', payload, tokenConfig2(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: EXPORT_IMPORT_PARTICIPANT_POST_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Import berhasil dilakukan.', 200, 'EXPORT_IMPORT_PARTICIPANT_POST_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'EXPORT_IMPORT_PARTICIPANT_POST_FAIL',
          'Import belum berhasil dilakukan',
        ),
      );
      dispatch({
        type: EXPORT_IMPORT_PARTICIPANT_POST_FAIL,
      });
  })
};

// // remove imported participant
export const removeImportedParticipant = (paramId: any) => (dispatch: any, getState: any) => {
  dispatch({type: EXPORT_IMPORT_LOADING});

  httpRequestAxiosService.delete(process.env.REACT_APP_MAIN_API + 'old-participant/' + paramId, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: EXPORT_IMPORT_PARTICIPANT_REMOVE_BY_ID_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Data berhasil dihapus.', 200, 'EXPORT_IMPORT_PARTICIPANT_REMOVE_BY_ID_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'EXPORT_IMPORT_PARTICIPANT_REMOVE_BY_ID_FAIL',
          'Data belum berhasil dihapus',
        ),
      );
      dispatch({
        type: EXPORT_IMPORT_PARTICIPANT_REMOVE_BY_ID_FAIL,
      });
  })
};

// // remove all imported participant
export const removeAllImportedParticipant = () => (dispatch: any, getState: any) => {
  dispatch({type: EXPORT_IMPORT_LOADING});

  httpRequestAxiosService.delete(process.env.REACT_APP_MAIN_API + 'old-participant/delete-all/', tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: EXPORT_IMPORT_PARTICIPANT_REMOVE_ALL_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Data berhasil dihapus.', 200, 'EXPORT_IMPORT_PARTICIPANT_REMOVE_ALL_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'EXPORT_IMPORT_PARTICIPANT_REMOVE_ALL_FAIL',
          'Data belum berhasil dihapus',
        ),
      );
      dispatch({
        type: EXPORT_IMPORT_PARTICIPANT_REMOVE_ALL_FAIL,
      });
  })
};