import React, { useEffect, useState } from 'react';
import { Button, Input, Layout, PageHeader, Table, Modal, Space, Tabs } from 'antd';
import { Link } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';

import Package from './package';
import ShareEvidence from './shareevidence';
import WebinarCategory from './webinarCategory';
import PaymentMethodPage from './paymentMethod';

const { Header, Content } = Layout;
const { Search } = Input;
const { TabPane } = Tabs;

const MasterData = (props: any) => {
  
  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title="Master Data"
        // breadcrumb={{ routes }}
      />
      <Tabs defaultActiveKey="1">
        <TabPane tab="Paket Webinar" key="1">
          <Package />
        </TabPane>
        <TabPane tab="Kategori Bukti Share" key="2">
          <ShareEvidence />
        </TabPane>
        <TabPane tab="Kategori Webinar" key="3">
          <WebinarCategory />
        </TabPane>
        <TabPane tab="Metode Pembayaran" key="4">
          <PaymentMethodPage />
        </TabPane>
      </Tabs>
    </Content>
  );
}

export default MasterData;