import {useState} from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Tag, Space, Modal, Tooltip, Input, message, Select, Spin } from 'antd';

let moment = require('moment');

const { Option } = Select;

const PostTestFormComponent = (props: any) => {
  let {initVal, form} = props;
 
  return (
    <Form
      form={form}
      layout="vertical"
      name="form_in_modal"
      initialValues={initVal}
      // initialValues={{name: initVal.name}}
    >
      <Form.Item
        name="testTitle"
        label="Judul Soal"
      >
        <Input />
      </Form.Item>
      <Form.Item 
        name="testSubtitle" 
        label="Subjudul soal"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="testCase"
        label="Kasus Soal"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="testNumber"
        label="No Soal"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="testQuestion"
        label="Pertanyaan"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="testOptAmount"
        label="Jumlah opsi jawaban"
      >
        <Select
          placeholder="Pilih jenis webinar"
          allowClear
        >
          <Option value="2">2</Option>
          <Option value="3">3</Option>
          <Option value="4">4</Option>
          <Option value="5">5</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="testOpt1"
        label="Opsi 1"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="testOpt2"
        label="Opsi 2"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="testOpt3"
        label="Opsi 3"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="testOpt4"
        label="Opsi 4"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="testOpt5"
        label="Opsi 5"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="testAnswer"
        label="Jawaban"
      >
        <Select
          placeholder="Pilih pilihan benar"
          allowClear
        >
          <Option value="testOpt1">Opsi 1</Option>
          <Option value="testOpt2">Opsi 2</Option>
          <Option value="testOpt3">Opsi 3</Option>
          <Option value="testOpt4">Opsi 4</Option>
          <Option value="testOpt5">Opsi 5</Option>
        </Select>
      </Form.Item>
      <Form.Item
        name="testCorrectPoint"
        label="Poin benar"
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="testWrongPoint"
        label="Poin salah"
      >
        <Input />
      </Form.Item>
    </Form>
  )
}

export default PostTestFormComponent;

