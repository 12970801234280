import { EXPORT_IMPORT_LOADING, EXPORT_IMPORT_PARTICIPANT_GET_FAIL, EXPORT_IMPORT_PARTICIPANT_GET_SUCCESS, EXPORT_IMPORT_PARTICIPANT_POST_FAIL, EXPORT_IMPORT_PARTICIPANT_POST_SUCCESS, EXPORT_IMPORT_PARTICIPANT_REMOVE_ALL_FAIL, EXPORT_IMPORT_PARTICIPANT_REMOVE_ALL_SUCCESS, EXPORT_IMPORT_PARTICIPANT_REMOVE_BY_ID_FAIL, EXPORT_IMPORT_PARTICIPANT_REMOVE_BY_ID_SUCCESS } from '../types';

const initialState = {
  data: [],
  total: 0,
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case EXPORT_IMPORT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case EXPORT_IMPORT_PARTICIPANT_GET_SUCCESS:
      return {
        ...state,
        data: action.resp.data.data,
        total: action.resp.data.total,
        isLoading: false,
      };
    case EXPORT_IMPORT_PARTICIPANT_GET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case EXPORT_IMPORT_PARTICIPANT_POST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case EXPORT_IMPORT_PARTICIPANT_POST_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case EXPORT_IMPORT_PARTICIPANT_REMOVE_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case EXPORT_IMPORT_PARTICIPANT_REMOVE_BY_ID_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case EXPORT_IMPORT_PARTICIPANT_REMOVE_ALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case EXPORT_IMPORT_PARTICIPANT_REMOVE_ALL_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
