import {httpRequestAxiosService} from '../../services';
import {returnErrors, returnSuccess} from './InfoAction';
import { ANNOUNCEMENT_CREATE_FAIL, ANNOUNCEMENT_CREATE_SUCCESS, ANNOUNCEMENT_GET_FAIL, ANNOUNCEMENT_GET_SUCCESS, ANNOUNCEMENT_LOADING, ANNOUNCEMENT_REMOVE_FAIL, ANNOUNCEMENT_REMOVE_SUCCESS, ANNOUNCEMENT_UPDATE_FAIL, ANNOUNCEMENT_UPDATE_SUCCESS } from '../types';
import { tokenConfig, tokenConfig2 } from './AuthAction';

// Get Announcement
export const getAnnouncement = (qparam: any) => (dispatch: any, getState: any) => {
  dispatch({type: ANNOUNCEMENT_LOADING});

  let param = {
    page: qparam && qparam.page ? qparam.page : 1,
    limit: qparam && qparam.limit ? qparam.limit : 10,
  };

  httpRequestAxiosService.get(`${process.env.REACT_APP_MAIN_API}announcements?page=${param.page}&limit=${param.limit}&sortby=createdAt&order=desc` , tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: ANNOUNCEMENT_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Pengumuman berhasil diperoleh.', 200, 'ANNOUNCEMENT_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Pengumuman belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'ANNOUNCEMENT_GET_FAIL',
        ),
      );
      dispatch({
        type: ANNOUNCEMENT_GET_FAIL,
      });
  })
};

// add new announcement
export const createAnnouncement = (payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: ANNOUNCEMENT_LOADING});

  httpRequestAxiosService.post(process.env.REACT_APP_MAIN_API + 'announcements', payload, tokenConfig2(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: ANNOUNCEMENT_CREATE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Pengumuman berhasil ditambahkan.', 200, 'ANNOUNCEMENT_CREATE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'ANNOUNCEMENT_CREATE_FAIL',
          'Pengumuman belum berhasil ditambahkan',
        ),
      );
      dispatch({
        type: ANNOUNCEMENT_CREATE_FAIL,
      });
  })
};

// update announcement
export const updateAnnouncement = (paramId: any, payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: ANNOUNCEMENT_LOADING});

  httpRequestAxiosService.put(process.env.REACT_APP_MAIN_API + 'announcements/' + paramId, payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: ANNOUNCEMENT_UPDATE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Pengumuman berhasil diubah.', 200, 'ANNOUNCEMENT_UPDATE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'ANNOUNCEMENT_UPDATE_FAIL',
          'Pengumuman belum berhasil diubah',
        ),
      );
      dispatch({
        type: ANNOUNCEMENT_UPDATE_FAIL,
      });
  })
};

// add new announcement
export const removeAnnouncement = (payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: ANNOUNCEMENT_LOADING});

  httpRequestAxiosService.delete(`${process.env.REACT_APP_MAIN_API}announcements/${payload}`, tokenConfig2(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: ANNOUNCEMENT_REMOVE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Pengumuman berhasil dihapus.', 200, 'ANNOUNCEMENT_REMOVE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'ANNOUNCEMENT_REMOVE_FAIL',
          'Pengumuman belum berhasil dihapus',
        ),
      );
      dispatch({
        type: ANNOUNCEMENT_REMOVE_FAIL,
      });
  })
};