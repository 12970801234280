import { Table, Switch, Space, PageHeader, Layout } from 'antd';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Link, useNavigate } from "react-router-dom";

import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import type { ProColumns, ActionType } from '@ant-design/pro-table';
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Modal, Input, message, Select, Spin } from 'antd';
import { getWebinarUser } from '../../store/actions/WebinarAction';
import { getAnnouncement, removeAnnouncement } from '../../store/actions/AnnouncementActions';
import { getWebinarCategories } from '../../store/actions/WebinarCategoryAction';
let moment = require('moment');

const { Option } = Select;
const { Header, Content } = Layout;
const routes = [
  {
    path: 'index',
    breadcrumbName: 'Dashboard',
  },
  {
    path: 'first',
    breadcrumbName: 'Pengumuman',
  },
];

const AnnouncementList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {info, isLoading, isLoading2, aList, aTotal, webCatList} = useSelector((state: any) => ({
    info: state.info,
    aList: state.anno.data,
    aTotal: state.anno.total,
    isLoading: state.anno.isLoading,
    isLoading2: state.wbnr.isLoading,
    webCatList: state.wbcr.data,
  }));

  const [isRefresh, setIsRefresh] = useState(false);

  const [page, setPage] = useState(1);

  const [initVal, setInitVal] = useState<any>({
    title: '',
    info: '',
    related_data: '',
    topic_selected: [],
    type: 'all',
    schedule: 'send_now',
    status: 1,
  });

  useEffect(() => {
    if (isRefresh) {
      setTimeout(() => {
        setIsRefresh(false)
      }, 300);
    }
  }, [isRefresh])

  useEffect(() => {
    loadAnnouncement();
    loadWebinarUser();
    loadWebinarTheme();
  }, [page]);

  useEffect(() => {
    if (info.id === 'ANNOUNCEMENT_REMOVE_SUCCESS') {
      message.success(info.msg);
      loadAnnouncement();
    }
    if (info.id === 'ANNOUNCEMENT_REMOVE_FAIL') {
      message.error(info.msg.message);
    }
  }, [info]);

  const loadAnnouncement = () => {
    let param = {
      page: page,
      limit: 10,
    }

    dispatch(getAnnouncement(param));
  }

  const loadWebinarUser = () => {
    let param = {
      page: page,
      limit: 100,
    }

    dispatch(getWebinarUser(param));
  }

  const loadWebinarTheme = () => {
    let param = {
      page: page,
      limit: 100,
    }
    dispatch(getWebinarCategories(param));
  }

  const showModalDeleteConfirm = (param: any) => {
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: 'Apakah Kamu yakin akan menghapus pengumuman ini?',
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(removeAnnouncement(param._id));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const columns = [
    {
      title: 'Judul',
      dataIndex: 'title',
      key: 'title',
      // render: (text: any) => <a>{text}</a>,
    },
    {
      title: 'Isi',
      dataIndex: 'info',
      key: 'info',
    },
    {
      title: 'Tanggal Pembuatan',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (tanggal: any) => <text>{moment(tanggal).format('DD MMM YYYY HH:mm')}</text>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (val: any) => <span>{val ? 'Aktif' : 'Tidak Aktif'}</span>,
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (item: any, record: any) => (
        <Space size="middle">
          {/* <a
            onClick={() => {
              setInitVal(record);
              setIsUpdate(true);
              setVisible(true);
            }}
          >Ubah</a> */}
          <a onClick={() => {showModalDeleteConfirm(record)}}>{record.status === 1 ? 'Non Aktifkan' : 'Aktifkan'}</a>
          <a onClick={() => {showModalDeleteConfirm(record)}}>Hapus</a>
        </Space>
      ),
    },
  ];

  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title="Daftar Pengumuman"
        // breadcrumb={{ routes }}
        extra={[
          <Button key="3" type="primary" onClick={() => navigate('/admin/announcement/add/')}>Tambah Pengumuman</Button>
        ]}
      />
      <Table 
        className="components-table-demo-nested"
        columns={columns} 
        dataSource={aList} 
        loading={isLoading || isLoading2} 
        // expandable={{ expandedRowRender }}
        scroll={{ y: 1000, x: '20vw' }}
        pagination={{
          hideOnSinglePage: true,
          total: aTotal,
          onChange: (val) => setPage(val),
          // onShowSizeChange: (val, val2) => setLimit(val2)
        }}
      />

    </Content>
  )
};

export default AnnouncementList;
