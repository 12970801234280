import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { List, Card , Form, Modal, Typography, message, Select, Tag, Space, PageHeader, Layout, Input, Button, Row, Col } from 'antd';
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { formatUang, getMainValue, isJsonString, renderSpeaker } from '../../shared/helper';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import rs_img from '../../shared/assets/img/ruang_seminar_01.jpg';
import { clearInfo } from '../../store/actions/InfoAction';
import { getUserById } from '../../store/actions/UserAction';

let moment = require('moment');

const { Option } = Select;
const { Header, Content } = Layout;
const { Text } = Typography;

const ParticipantDetail = (props: any) => {
  const dispatch = useDispatch();
  const {info, isLoading, uDetail} = useSelector((state: any) => ({
    info: state.info,
    uDetail: state.user.uDetail,
    isLoading: state.user.isLoading,
  }));

  const { id } = useParams();
  
  const [modal, contextHolder] = Modal.useModal();

  let navigate = useNavigate();

  useEffect(() => {
    dispatch(getUserById(id));
  }, []);

  useEffect(() => {
  }, [info]);

  const configSuccess = {
    title: 'Berhasil',
    centered: true,
    content: (<div><p>{'No sertifkat berhasil diubah'}</p></div>),
    onOk() {
      dispatch(clearInfo())
    }
  };

  const configFailed = {
    title: 'Mohon maaf',
    centered: true,
    content: (<div><p>Peserta tidak dapat diingatkan karena tidak memiliki mobile FCM token.</p></div>),
    onOk() {
      // dispatch(clearInfo())
    }
  };

  const onRemindParticipant = () => {
    if (uDetail && uDetail.mobileFCMToken) {
      navigate('/admin/announcement/add/', {state: {participant: uDetail}})
    } else {
      setTimeout(() => {
        modal.error(configFailed);
      }, 500);
    }
  }
  
  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title={uDetail && uDetail.name}
        extra={[
          <Button key="3" type="primary" onClick={() => onRemindParticipant()}>Ingatkan Peserta</Button>
        ]}
      />
      {uDetail && (
        <>
          <Card 
            type="inner" 
            title="Informasi Umum"
          >
            <img
              width={200}
              alt="logo"
              src={
                uDetail && uDetail.avatar ?
                  process.env.REACT_APP_WEB + uDetail.avatar
                  : rs_img
              }
              onClick={() => console.log('isi')}
            />
            <List.Item>
              <List.Item.Meta
                title="Nama"
                description={uDetail && uDetail?.name}
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title="Email"
                description={uDetail && uDetail?.email || '-'}
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title="No HP"
                description={uDetail && uDetail?.phone || '-'}
              />
            </List.Item>
          </Card>

          <Card
            style={{ marginTop: 16 }}
            type="inner"
            title="Aktivitas"
          >
            {uDetail && uDetail.userActivity && uDetail.userActivity.map((item: any, ind: any) => {
              return (
                <List.Item key={ind}>
                  <List.Item.Meta
                    title={item.activity_code ? item.activity_code : item.description}
                    description={item.description}
                  />
                  <div>
                    <Text>{moment(item.createdAt).format('DD MMM YYYY HH:mm')}</Text>
                  </div>
                </List.Item>
              )
            })}
          </Card>
        </>
      )}

      {contextHolder}
    </Content>
  )
};

export default ParticipantDetail;