import { Table, Tag, Space, PageHeader, Layout } from 'antd';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Input, message, Select, Divider } from 'antd';
import { getUser, removeUser } from '../../store/actions/UserAction';
import { Link } from 'react-router-dom';
let moment = require('moment');

const { Option } = Select;
const { Header, Content } = Layout;
const routes = [
  {
    path: 'index',
    breadcrumbName: 'Dashboard',
  },
  {
    path: 'first',
    breadcrumbName: 'Peserta',
  },
];

const Participant = () => {
  const dispatch = useDispatch();
  const {info, isLoading, uList, uTotal} = useSelector((state: any) => ({
    info: state.info,
    uList: state.user.data,
    uTotal: state.user.total,
    isLoading: state.user.isLoading,
  }));

  const [page, setPage] = useState(1);

  useEffect(() => {
    loadUsers()
  }, [page]);

  useEffect(() => {
    if (info.id === 'USER_DELETE_SUCCESS') {
      message.success(info.msg);
      loadUsers()
    }
    if (info.id === 'USER_DELETE_FAIL') {
      message.error(info.msg.message);
    }
  }, [info]);

  const loadUsers = () => {
    let param = {
      page: page,
      limit: 10,
    }
    dispatch(getUser(param));
  }

  const showModalDeleteConfirm = (param: any) => {
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: 'Apakah Kamu yakin akan menghapus akun ini?',
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(removeUser(param._id));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'No HP',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Alamat',
      key: 'address',
      dataIndex: 'address',
      render: (data: any) => <text>{data ? (data.fullAddress || '-') : '-'}</text>,
    },
    {
      title: 'Tanggal Registrasi',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (tanggal: any) => <text>{moment(tanggal).format('DD MMM YYYY HH:mm')}</text>,
    },
    {
      title: 'Verifikasi No HP',
      key: 'isVerifyPhone',
      dataIndex: 'isVerifyPhone',
      render: (data: any) => <text>{data ? 'Ya' : 'Belum'}</text>,
    },
    {
      title: 'Verifikasi Email',
      key: 'isVerifyEmail',
      dataIndex: 'isVerifyEmail',
      render: (data: any) => <text>{data ? 'Ya' : 'Belum'}</text>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <Link to={{pathname: '/admin/participant/detail/'+record._id}} state={record}>Rincian</Link>
          <a>Blokir</a>
          <a onClick={() => {
            showModalDeleteConfirm(record);
          }}>Hapus</a>
        </Space>
      ),
    },
  ];

  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title="Daftar Peserta"
        // breadcrumb={{ routes }}
        // extra={[
        //   <Button key="3" type="primary">Buat Webinar</Button>
        // ]}
      />
      <Table 
        columns={columns} 
        dataSource={uList} 
        loading={isLoading} 
        // scroll={{ y: 500, x: '100vw' }} 
        pagination={{
          hideOnSinglePage: true,
          total: uTotal,
          onChange: (val) => setPage(val),
          // onShowSizeChange: (val, val2) => setLimit(val2)
        }}
      />
    </Content>
  )
};

export default Participant;