export const formatUang = (angka: any, prefix: any) => {
  let number_string = angka.replace(/[^,\d]/g, '').toString();
  let split = number_string.split(',');
  let sisa = split[0].length % 3;
  let rupiah = split[0].substr(0, sisa);
  let ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if(ribuan){
    let separator = sisa ? '.' : '';
    rupiah += separator + ribuan.join('.');
  }

  rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
  return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
}

export const formatUang2 = (angka: any, prefix: any) => {
  let number_string = angka.replace(/[^.\d]/g, '').toString();
  let split = angka.split(',');
  let sisa = split[0].length % 3;
  let rupiah = split[0].substr(0, sisa);
  let ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if(ribuan){
    let separator = sisa ? '.' : '';
    rupiah += separator + ribuan.join('.');
  }

  rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
  return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
}

export const formatUang3 = (angka: any, prefix: any) => {
  let angka_string = angka.toString();
  let number_string = angka_string.replace(/[^,\d]/g, '').toString();
  let split = number_string.split(',');
  let sisa = split[0].length % 3;
  let rupiah = split[0].substr(0, sisa);
  let ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  // tambahkan titik jika yang di input sudah menjadi angka ribuan
  if(ribuan){
    let separator = sisa ? '.' : '';
    rupiah += separator + ribuan.join('.');
  }

  rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
  return prefix == undefined ? rupiah : (rupiah ? 'Rp. ' + rupiah : '');
}

/**
 * 
 * @param list : list yang akan dicari nilainya
 * @param param : param acuan di list yang nilainya di cari
 * @param valParam : param dari nilai yang ingin didapatkan
 * @param valPayload : nilai yang dibandingkan dengan param acuan
 * @returns 
 */
export const getMainValue = (list: any, param: any, valParam: any, valPayload: any) => {
  if (list && list.length > 0) {
    let data = list.find((x: any) => x[param] === valPayload);
    return data[valParam];
  } else {
    return '-'
  }
}

export const getMainObject = (list: any, param: any, valXParam: any) => {
  if (list && list.length > 0) {
    let data = list.find((x: any) => x[param] === valXParam);
    return data;
  } else {
    return '-'
  }
}

export const renderSpeaker = (val: any) => {
  let speaker = val.name;
  // let title = val && val.title && val.title.split('|');
  // if (title) {
  //   speaker = (title[0] ? title[0] : '') + val.name + (title[1] ? ', ' + title[1] : '');
  // } else {
  // }
  speaker = (val.front_title ? val.front_title : '') + val.name + (val.end_title ? ', ' + val.end_title : '')
  return speaker;
}

// check the string can parse with JSON or not
export const isJsonString = (str: any) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false;
  }
  return true;
}

export const roleUser = (role: any) => {
  if (role === '1' || role === 1) {
    return 'Admin'
  } else if (role === '2' || role === 2) {
    return 'Peserta'
  } else {
    return '-'
  }
}
