import {
  POSTEST_CREATE_FAIL,
  POSTEST_CREATE_SUCCESS,
  POSTEST_DELETE_FAIL,
  POSTEST_DELETE_SUCCESS,
  POSTEST_GET_FAIL,
  POSTEST_GET_SUCCESS,
  POSTEST_LOADING,
  POSTEST_UPDATE_FAIL,
  POSTEST_UPDATE_SUCCESS,
} from '../types';

const initialState = {
  data: [],
  total: 0,
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case POSTEST_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case POSTEST_GET_SUCCESS:
      return {
        ...state,
        data: action.resp.postTestWebinar,
        total: action.resp.total,
        isLoading: false,
      };
    case POSTEST_GET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case POSTEST_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case POSTEST_CREATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case POSTEST_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case POSTEST_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case POSTEST_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case POSTEST_DELETE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
