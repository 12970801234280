import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import type { ProColumns, ActionType } from '@ant-design/pro-table';
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Table, Space, PageHeader, Layout, Form, Modal, Input, Select, message, List, Card, Row, Col, Button, Spin } from 'antd';
import { generateUserInvoiceWebinar, loadWebinarParticipant, uploadUserCertificate, validateBasicParticipant, validateWRegis } from '../../store/actions/WebinarAction';
import './styles.less';
import { getPackage } from '../../store/actions/PackageAction';
import { useParams, useLocation } from 'react-router-dom';
import { getMainValue, isJsonString } from '../../shared/helper';
import { Link } from 'react-router-dom';
import { checkInvoiceStatus } from '../../store/actions/PaymentAction';
let moment = require('moment');

const { Option } = Select;
const { Header, Content } = Layout;
const routes = [
  {
    path: '/',
    breadcrumbName: 'Dashboard',
  },
  {
    path: 'first',
    breadcrumbName: 'Webinar',
  },
  {
    path: 'second',
    breadcrumbName: 'Peserta Webinar',
  },
];

const WebinarParticipant = (props: any) => {
  const dispatch = useDispatch();
  const {info, isLoading, wParticipant, evidList} = useSelector((state: any) => ({
    info: state.info,
    wParticipant: state.wbnr.wParticipant,
    isLoading: state.pymn.isLoading,
    evidList: state.evid.data
  }));
  const { id } = useParams();
  const location = useLocation();

  const [form] = Form.useForm();

  const [participantBasic, setParticipantBasic] = useState<any>([]);
  const [wInfo, setWInfo] = useState<any>(undefined);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [isShowShareEvidence, setShowShareEvidence] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(undefined);
  const [selectedUser, setSelectedUser] = useState<any>(undefined);

  useEffect(() => {
    loadWP();
    if (location && location.state && location.state) {
      setWInfo(location.state);
    }
  }, []);

  useEffect(() => {
    if (wParticipant) {
      checkParticipantBasic();
    }
  }, [wParticipant])

  useEffect(() => {
    if (info.id === 'WEBINAR_ADD_CERTIFICATE_USER_SUCCESS') {
      message.success(info.msg);
      loadWP();
    }
    if (info.id === 'WEBINAR_ADD_CERTIFICATE_USER_FAIL') {
      message.error(info.msg.message);
    }
    if (info.id === 'WEBINAR_VALIDATE_W_REGIS_SUCCESS') {
      message.success('Status verifikasi berhasil diubah');
      loadWP();
    }
    if (info.id === 'WEBINAR_VALIDATE_W_REGIS_FAIL') {
      message.error(info.msg.message);
    }
    if (info.id === 'WEBINAR_VALIDATE_PARTICIPANT_BASIC_SUCCESS') {
      message.success(info.msg);
      loadWP();
    }
    if (info.id === 'WEBINAR_VALIDATE_PARTICIPANT_BASIC_FAIL') {
      message.error(info.msg.message);
    }
    if (info.id === 'WEBINAR_GENERATE_INVOICE_SUCCESS') {
      message.success('Generate invoice berhasil');
      loadWP();
    }
    if (info.id === 'WEBINAR_GENERATE_INVOICE_FAIL') {
      message.error(info.msg.message);
    }
    if (info.id === 'PAYMENT_XENDIT_CHECK_SUCCESS') {
      message.success('Cek invoice berhasil');
      loadWP();
    }
    if (info.id === 'PAYMENT_XENDIT_CHECK_FAIL') {
      message.error(info.msg.message);
    }
  }, [info]);

  const loadWP = () => {
    dispatch(loadWebinarParticipant(id));
  }

  const onFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  }; 

  const onCancel = async () => {
    setIsOpenForm(false);
  };

  const onSubmit = async () => {
    const formData = new FormData();
    setIsOpenForm(false);
    formData.append("certificate", selectedFile);

    let wId = id;
    let uId = selectedUser && selectedUser.user._id;

    dispatch(uploadUserCertificate(wId, uId, formData));
  };

  const showModalVerificationConfirm = (param: any, validate: any) => {
    let payload = {
      isValidated: validate,
    };

    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: `Apakah Kamu yakin akan ${validate ? 'memvalidasi' : 'membatalkan verifikasi'} peserta ini?`,
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(validateWRegis(param.webinar, param.user._id, payload));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const showModalGenerateInvoice = (param: any, validate: any) => {
    let payload = {
      paymentMethod: 'xendit'
    };

    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: `Apakah Kamu yakin akan membuat ulang invoice untuk peserta ini?`,
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(generateUserInvoiceWebinar(param.webinar, param.user._id, payload));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }
  
  const showModalAccAllBasicPackage = (param: any) => {
    let payload = {
      participants: ["63d0aee77cf9163ec8fb2f18", "63d0eea5726d7b2a58639518"]  
    };
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: 'Apakah Kamu yakin akan memvalidasi semua peserta akun basic?',
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(validateBasicParticipant(param, payload));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const renderListShareEvidence = () => {
    if (selectedUser && selectedUser?.shareEvidence !== 'https://shareevidence.png' && isJsonString(selectedUser.typeEvidences)) {
      let evidList = JSON.parse(selectedUser.typeEvidences);
      return evidList.map((item: any, ind: any) => {
        return (
          <Col className="gutter-row" span={6} key={ind}>
            <img
              width={'90%'}
              alt="logo"
              src={item.imagePath ? process.env.REACT_APP_WEB + item.imagePath : "https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"}
            />
            <div>{item.description}</div>
          </Col>
        )
      })
    } else {
      return (
        <div style={{marginLeft: 10}}>Belum ada bukti share</div>
      )
    }
  }

  const renderListShareEvidence2 = () => {
    if (selectedUser && selectedUser?.shareEvidence !== 'https://shareevidence.png' && selectedUser.shareEvidence !== 'kosong.png') {
      let sEList = JSON.parse(selectedUser.shareEvidence);
      return sEList.map((item: any, ind: any) => {
        return (
          <Col className="gutter-row" span={6} key={ind}>
            <img
              width={'90%'}
              alt="logo"
              src={item ? process.env.REACT_APP_WEB + item : "https://gw.alipayobjects.com/zos/rmsportal/mqaQswcyDLcXyDKnZfES.png"}
            />
          </Col>
        )
      })
    } else {
      return (
        <div style={{marginLeft: 10}}>Belum ada bukti share</div>
      )
    }
  }

  const columns = [
    {
      title: 'Nama',
      dataIndex: ['user', 'name'],
      key: 'nama',
    },
    {
      title: 'Email',
      dataIndex: ['user', 'email'],
      key: 'email',
    },
    {
      title: 'HP',
      dataIndex: ['user', 'phone'],
      key: 'phone',
    },
    {
      title: 'Alamat',
      key: 'address',
      dataIndex: ['user', 'address'],
      render: (data: any) => <text>{data ? (data.fullAddress || '-') : '-'}</text>,
    },
    {
      title: 'Paket',
      dataIndex: ['package', 'name'],
      key: 'Paket',
    },
    {
      title: 'Terverifikasi',
      dataIndex: 'isValidated',
      key: 'isValidated',
      render: (data: any) => <span>{data ? 'Ya' : 'Belum'}</span>
    },
    {
      title: 'Poin Post Test',
      dataIndex: 'postTestPoint',
      key: 'postTestPoint',
      render: (data: any) => <span>{data ? data : '-'}</span>
    },
    {
      title: 'Sertifikat',
      dataIndex: 'certificate',
      key: 'certificate',
      render: (data: any, ind: any) => {
        return <span>
          {data ? 
            <img key={ind} src={ 'https://ruangseminar.site/' + data } className="img-fluid img-bordered" width="100px" />
            : '-'
          }
        </span>
      }
    },
    {
      title: 'Action',
      key: 'action',
      render: (item: any, record: any) => (
        <Space size="middle">
          <Link to={{pathname: '/admin/webinar/participant-detail/'+record._id}} state={record}>Rincian</Link>
          {((item.invoice !== null) || (item.package.category !== "basic")) && (
            <a
              onClick={() => {
                console.log('cek status pembayaran')
              }}
            >Cek Status Pembayaran</a>
          )}
          {((item.package.name.toLowerCase() === "basic") || (item.package.category === "basic")) && (
            <a
              onClick={() => {
                setShowShareEvidence(true);
                setSelectedUser(record);
              }}
            >Bukti Share</a>
          )}
          {((item.package.name.toLowerCase() === "basic") || (item.package.category === "basic")) && (!item.isValidated) && (
            <a
              onClick={() => {
                setSelectedUser(record);
                showModalVerificationConfirm(record, true);
              }}
            >Verifikasi</a>
          )}
          {((item.package.name.toLowerCase() === "basic") || (item.package.category === "basic")) && (item.isValidated) && (
            <a
              onClick={() => {
                setSelectedUser(record);
                showModalVerificationConfirm(record, false);
              }}
            >Batalkan Verifikasi</a>
          )}
          {((item.package.name.toLowerCase() !== "basic") || (item.package.category !== "basic")) && (
            <a
              onClick={() => {
                setSelectedUser(record);
                showModalGenerateInvoice(record, false);
              }}
            >Generate Invoice</a>
          )}
          <a
            onClick={() => {
              setIsOpenForm(true);
              setSelectedUser(record);
            }}
          >Upload Sertifikat</a>
        </Space>
      ),
    },
  ];

  const handleCancel = () => {
    setShowShareEvidence(false)
    setSelectedUser(undefined)
  }

  const checkParticipantBasic = () => {
    const pBasic = (wParticipant && wParticipant.find((x: any) => ((x.package.name === 'Basic') && ((!x.isValidated))))) || [];
    const result = Array.isArray(pBasic);
    if (result) {
      let pBasic1: any = [];
      if (pBasic.length > 0) {
        pBasic.forEach((element: any) => {
          pBasic1.push(element._id);
        });
      }
      setParticipantBasic(pBasic1)
      return pBasic
    } else {
      let pBasic2 = [];
      pBasic2.push(pBasic._id);
      setParticipantBasic(pBasic2)
      return pBasic2
    }
  }

  const getButtonAccAllBasicCheck = () => {
    if (participantBasic && (participantBasic.length > 0)) {
      return true;
    }
    return true;
  } 
  
  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title={'Peserta Webinar ' + (wInfo && wInfo.name ? wInfo.name : '')}
        // breadcrumb={{ routes }}
        extra={[
          <Button key="3" type="primary" disabled={!getButtonAccAllBasicCheck()} onClick={() => showModalAccAllBasicPackage(id)}>{`ACC ${participantBasic && participantBasic.length} Peserta Paket Basic`}</Button>
        ]}
      />
      {/* <Table 
        columns={columns} 
        dataSource={wParticipant} 
        loading={isLoading}  /> */}
        <Row gutter={[16, 16]}>
          {wParticipant && wParticipant.map((item: any, index: any) => (
            <Col span={8} key={item._id}>
              <Card
                title={item.user.name || '-'}
                extra={<Link to={{pathname: '/admin/webinar/participant-detail/'+item._id}} state={item}>Rincian</Link>}
                bordered={true}
              >
                <p>Email: {item.user.email || '-'}</p>
                <p>HP: {item.user.phone || '-'}</p>
                <p>Alamat: {item.user.address?.fullAddress || '-'}</p>
                <p>Paket: {item.package.name || '-'}</p>
                {item.package.category !== 'basic' ? (
                  <p style={{ color: ((item.invoice.status === 'SETTLED') || (item.invoice.status === 'PAID')) ? 'green' : 'red' }}>
                    Status Pembayaran: {item.invoice.status}
                  </p>
                ) : (
                  <p style={{ color: item.isValidated ? 'green' : 'red' }}>
                    Terverifikasi: {item.isValidated ? 'Ya' : 'Belum'}
                  </p>
                )}
                <p>Poin Post Test: {item.postTestPoint || '-'}</p>
                <p>
                  Sertifikat:{' '}
                  {item.certificate ? (
                    <img
                      src={`https://ruangseminar.site/${item.certificate}`}
                      alt="certificate"
                      width="100px"
                    />
                  ) : (
                    '-'
                  )}
                </p>

                <Space direction="vertical">
                  {((item.invoice !== null) && !((item.invoice.status === 'EXPIRED') || (item.invoice.status === 'SETTLED') || (item.invoice.status === 'PAID')) && item.package.category !== 'basic') && (
                    <Button block onClick={() => { dispatch(checkInvoiceStatus({userId: item.user._id, webinarId: item.webinar})) }}>
                      Cek Status Pembayaran
                    </Button>
                  )}

                  {(item.package.name.toLowerCase() === 'basic' ||
                    item.package.category === 'basic') && (
                    <>
                      <Button
                        onClick={() => {
                          setShowShareEvidence(true);
                          setSelectedUser(item);
                        }}
                      >
                        Bukti Share
                      </Button>

                      {!item.isValidated ? (
                        <Button
                          onClick={() => {
                            setSelectedUser(item);
                            showModalVerificationConfirm(item, true);
                          }}
                        >
                          Verifikasi
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            setSelectedUser(item);
                            showModalVerificationConfirm(item, false);
                          }}
                        >
                          Batalkan Verifikasi
                        </Button>
                      )}
                    </>
                  )}

                  {(item.package.name.toLowerCase() !== 'basic' ||
                    item.package.category !== 'basic') && (
                    <Button block
                      onClick={() => {
                        setSelectedUser(item);
                        showModalGenerateInvoice(item, false);
                      }}
                    >
                      Generate Invoice
                    </Button>
                  )}

                  <Button
                    block
                    onClick={() => {
                      setIsOpenForm(true);
                      setSelectedUser(item);
                    }}
                  >
                    Upload Sertifikat Manual
                  </Button>
                </Space>
              </Card>
            </Col>
          ))}
      </Row>

      <Modal
        visible={isOpenForm}
        title={"Unggah Sertifikat"}
        okText={"Simpan"}
        cancelText="Batal"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then(() => {
              form.resetFields();
              onSubmit();
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{certificate: ''}}
        >
          <Form.Item
            name="certificate"
            label="Sertifikat"
          >
            <Input type="file" onChange={onFileChange} />
          </Form.Item>
          
        </Form>
      </Modal>

      <Modal
        visible={isShowShareEvidence}
        width={1000}
        centered
        title={"Bukti share "}
        okText={"Tutup"}
        onCancel={() => {
          setShowShareEvidence(false)
          setSelectedUser(undefined)
        }}
        onOk={() => {
          setShowShareEvidence(false)
        }}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Tutup
          </Button>
        ]}
      >
        {/* <List.Item>
          <List.Item.Meta
            title="Jenis Bukti Share"
            description={(selectedUser && selectedUser.typeEvidence) ? getMainValue(evidList, '_id', 'description', selectedUser.typeEvidence) : '-'}
          />
        </List.Item> */}
        <Row gutter={[16, 24]}>
          {renderListShareEvidence()}
        </Row>

      </Modal>
      {isLoading &&
        <div className="overlay">
          <Spin size="large" />
        </div>
      }
    </Content>
  )
};

export default WebinarParticipant;