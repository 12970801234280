import {useState, useEffect} from 'react';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Tag, Space, Modal, Tooltip, Input, message, Select, Spin, Checkbox } from 'antd';
import { useSelector } from 'react-redux';

let moment = require('moment');

const { Option } = Select;

const WebinarFormComponent = (props: any) => {
  let {initVal, form} = props;
  const {pList, webCatList} = useSelector((state: any) => ({
    pList: state.pckg.data,
    webCatList: state.wbcr.data,
  }));

  const [tags, setTags] = useState<any>([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const [isRefreshField, setIsRefreshField] = useState(false);

  useEffect(() => {
    setTags(initVal.tags)
    form.setFieldValue("packages", initVal.packages);
  }, []);

  useEffect(() => {
    if (pList && pList.length > 0) {
      form.setFieldValue("packages", pList);
    }
    setTimeout(() => {
      setIsRefreshField(false)
    }, 100);
  }, [isRefreshField]);

  const handleClose = (removedTag: any) => {
    const tagss = tags.filter((tag: any) => tag !== removedTag);
    setTags(tagss);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    let tagss: any = [];
    if (inputValue && inputValue !== '' && tags.indexOf(inputValue) === -1) {
      tagss = [...tags, inputValue];
    }

    setTags(tagss);
    setInputVisible(false);
    setInputValue('');

    let fieldVal = form.getFieldsValue();

    fieldVal.tags = tagss;
    form.setFieldsValue(fieldVal);
    // form.setFields('tags', tagss);
  };

  const handleEditInputChange = (e: any) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags: any = [...tags];
    newTags[editInputIndex] = editInputValue;

    setTags(newTags);
    setEditInputIndex(-1);
    setEditInputValue('');

    // let fieldVal= form.get
    // form.setFields('tags', newTags);
  };

  // const saveInputRef = (input: any) => {
  //   input = input;
  // };

  // const saveEditInputRef = (input: any) => {
  //   editInput = input;
  // };

  function range(start: any, end: any) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  const onChangeDisableDateForEnd = () => {
    let fieldVal = form.getFieldsValue();
  }

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }

  function disabledDateforEnd(current: any) {
    // Can not select days before today and today
    if (form.getFieldValue('start')) {
      return current && current < moment(form.getFieldValue('start')).subtract(1, "days");
    } else {
      return current && current < moment().endOf('day');
    }
  }

  function disabledDateTimeforEnd() {
    if (form.getFieldValue('start')) {
      let hourss = moment(form.getFieldValue('start')).format('HH');
      let minutess = moment(form.getFieldValue('start')).format('mm');
      let minutesss = (parseInt(minutess) + 1).toString();
      let secondss = moment(form.getFieldValue('start')).format('ss');
      return {
        disabledHours: () => range(0, 24).splice(0, hourss),
        disabledMinutes: () => range(0, minutesss)
      };
    }
    return undefined;
  }
  
  function disabledDateTime() {
    return {
      disabledHours: () => range(0, 24).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }

  const onResetPackagesField = () => {
    setIsRefreshField(true)
    form.setFieldValue("packages", pList);
  }

  return (
    <Form
      form={form}
      layout="vertical"
      name="form_in_modal"
      initialValues={initVal}
      // initialValues={{name: initVal.name}}
    >
      <Form.Item
        name="name"
        label="Judul Webinar"
        rules={[{ required: true, message: 'Judul webinar harus diisi' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="webinarTheme" label="Tema Webinar" rules={[{ required: true, message: 'Tema webinar harus diisi' }]}>
        <Select
          placeholder="Pilih jenis webinar"
          allowClear
        >
          {webCatList && webCatList.map((item: any, ind: any) => {
            return (<Option key={ind} value={item.name}>{item.name}</Option>)
          })}
        </Select>
      </Form.Item>
      {/* <Form.Item
        name="webinarTheme"
        label="Tema Webinar"
        rules={[{ required: true, message: 'Tema webinar harus diisi' }]}
      >
        <Input />
      </Form.Item> */}
      <Form.Item 
        name="description" 
        label="Deskripsi"
        rules={[{ required: true, message: 'Deskripsi webinar harus diisi' }]}
      >
        <Input type="textarea" />
      </Form.Item>
      <Form.Item
        name="start"
        label="Mulai webinar"
        rules={[{ required: true, message: 'Waktu mulai webinar harus diisi' }]}
      >
        <DatePicker
          format="YYYY-MM-DD HH:mm:ss"
          disabledDate={disabledDate}
          // disabledTime={disabledDateTime}
          showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
          onClick={() => onChangeDisableDateForEnd()}
        />
      </Form.Item>
      <Form.Item
        name="end"
        label="Akhir webinar"
        rules={[{ required: true, message: 'Waktu selesai webinar harus diisi' }]}
      >
        <DatePicker
          format="YYYY-MM-DD HH:mm:ss"
          disabledDate={disabledDateforEnd}
          // disabledTime={disabledDateTimeforEnd}
          showTime={{ defaultValue: moment('00:00:00', 'HH:mm:ss') }}
        />
      </Form.Item>
      <Form.Item
        name="link_youtube"
        label="Link webinar (Youtube) (Link harus dimulai dari https://)"
        rules={[{ required: true, message: 'Link webinar harus diisi' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="link_zoom"
        label="Link webinar (Zoom) (Link harus dimulai dari https://)"
        rules={[{ required: true, message: 'Link webinar harus diisi' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="location"
        label="Lokasi webinar"
        rules={[{ required: true, message: 'Lokasi webinar harus diisi' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="code"
        label="Kode webinar"
        rules={[{ required: true, message: 'Kode webinar harus diisi' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="certificateType"
        label="Jenis Sertifikat"
        rules={[{ required: true, message: 'Jenis sertifikat harus diisi' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="certificateNumberFormat"
        label="Format Nomor Sertifikat (tulis posisi no urut dengan <<nourut>>, misal: webinar0123 / <<nourut>> / 07 / 2023"
        rules={[{ required: true, message: 'Format nomor sertifikat harus diisi' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="activityType" label="Jenis Aktivitas Webinar" rules={[{ required: true }]}>
        <Select
          placeholder="Pilih jenis aktivitas webinar"
          allowClear
        >
          <Option value="Webinar">Webinar</Option>
          <Option value="Pelatihan">Pelatihan</Option>
        </Select>
      </Form.Item>
      <Form.Item name="webinarType" label="Jenis Webinar" rules={[{ required: true }]}>
        <Select
          placeholder="Pilih jenis webinar"
          allowClear
        >
          <Option value="ONLINE">Online</Option>
          <Option value="OFFLINE">Offline</Option>
          <Option value="HYBRID">Hybrid (Online dan Offline)</Option>
        </Select>
      </Form.Item>

      <Form.Item name="tags" label="Tags">
        {tags.map((tag: any, index: any) => {
          if (editInputIndex === index) {
            return (
              <Input
                // ref={saveEditInputRef}
                key={tag}
                size="small"
                className="tag-input"
                value={editInputValue}
                onChange={handleEditInputChange}
                onBlur={handleEditInputConfirm}
                onPressEnter={handleEditInputConfirm}
              />
            );
          }

          const isLongTag = tag.length > 20;

          const tagElem = (
            <Tag
              className="edit-tag"
              key={tag}
              closable={index !== 0}
              onClose={() => handleClose(tag)}
            >
              <span
                onDoubleClick={e => {
                  setEditInputIndex(index);
                  setEditInputValue(tag);
                  e.preventDefault();
                  // if (index !== 0) {
                    
                    // this.setState({ editInputIndex: index, editInputValue: tag }, () => {
                    //   this.editInput.focus();
                    // });
                  // }
                }}
              >
                {isLongTag ? `${tag.slice(0, 20)}...` : tag}
              </span>
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={tag} key={tag}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
        {inputVisible && (
          <Input
            // ref={saveInputRef}
            type="text"
            size="small"
            className="tag-input"
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
        )}
        {!inputVisible && (
          <Tag className="site-tag-plus" onClick={showInput}>
            <PlusOutlined /> Tag Baru
          </Tag>
        )}
      </Form.Item>
      <Form.List
        name="speaker"
        // rules={[
        //   {
        //     validator: async (_, names) => {
        //       if (!names || names.length < 2) {
        //         return Promise.reject(new Error('At least 2 passengers'));
        //       }
        //     },
        //   },
        // ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            {fields.map((field: any, index: any) => (
              <Form.Item
                // {...(index === 0 ? formItemLayout : formItemLayout)}
                label={index === 0 ? 'Pemateri' : ''}
                required={false}
                key={field.key}
              >
                <Space style={{ display: 'flex'}} align="baseline">
                  <Form.Item
                    name={[field.name, 'name']}
                    fieldKey={[field.fieldKey, 'name']}
                    rules={[{ required: true, message: 'Nama lengkap harus diisi.' }]}
                  >
                    <Input placeholder="Nama lengkap" />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, 'front_title']}
                    fieldKey={[field.fieldKey, 'front_title']}
                  >
                    <Input placeholder="Gelar Depan" />
                  </Form.Item>

                  <Form.Item
                    name={[field.name, 'end_title']}
                    fieldKey={[field.fieldKey, 'end_title']}
                  >
                    <Input placeholder="Gelar Belakang" />
                  </Form.Item>

                  <Form.Item
                    name={[field.name, 'instansi']}
                    fieldKey={[field.fieldKey, 'instansi']}
                  >
                    <Input placeholder="Pekerjaan atau instansi terkait" />
                  </Form.Item>

                  {/* <Tooltip>
                    Dr. | S.T. (dipisah |) atau | S.E. (kalau hanya ada gelar belakang)
                  </Tooltip> */}
                  <Form.Item
                    name={[field.name, 'scope']}
                    fieldKey={[field.fieldKey, 'scope']}
                    rules={[{ required: true, message: 'Keahlian harus diisi' }]}
                  >
                    <Input placeholder="Keahlian pemateri" />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Space>

              </Form.Item>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                style={{ width: '60%' }}
                icon={<PlusOutlined />}
              >
                Tambahkan Pemateri
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>

      <Form.Item
        label={'Isi Harga Paket Webinar (beri tanda x apabila paket tidak tersedia)'}
      >
        <Button onClick={() => onResetPackagesField()} type='default'>Load List Paket (tekan apabila daftar input harga belum muncul)</Button>
      </Form.Item>

      {!isRefreshField && (
        <Form.List
          name="packages"
          >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field: any, index: any) => (
                <Form.Item
                  // {...(index === 0 ? formItemLayout : formItemLayout)}
                  // label={index === 0 ? 'Paket Webinar (beri tanda x apabila paket tidak tersedia)' : ''}
                  required={false}
                  key={field.key}
                >
                  <Space style={{ display: 'flex'}} align="baseline">
                    <Form.Item
                      name={[field.name, 'name']}
                      fieldKey={[field.fieldKey, 'name']}
                      rules={[{ required: true, message: 'Nama paket harus diisi.' }]}
                    >
                      <Input placeholder="Nama paket" disabled />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, 'price']}
                      fieldKey={[field.fieldKey, 'price']}
                      rules={[{ required: true, message: 'Harga harus diisi' }]}
                    >
                      <Input placeholder="Harga" />
                    </Form.Item>
                  </Space>

                </Form.Item>
              ))}
            </>
          )}
        </Form.List>
      )}
    </Form>
  )
}

export default WebinarFormComponent;

