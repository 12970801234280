import { Table, Tag, Space, PageHeader, Layout, Switch } from 'antd';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Link, useNavigate } from "react-router-dom";

import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Modal, Input, message, Select, Spin } from 'antd';
import { getWebinar, createWebinar, addPackageToWebinar, removeWebinar, updateStatusWebinar, updateWebinar, refreshWebinarStatus } from '../../store/actions/WebinarAction';
import './styles.less';
import { getPackage } from '../../store/actions/PackageAction';
import WebinarFormComponent from './component/WebinarFormComponent';
import { getEvidence } from '../../store/actions/EvidenceAction';
import { clearInfo } from '../../store/actions/InfoAction';
import { getWebinarCategories } from '../../store/actions/WebinarCategoryAction';
let moment = require('moment');

const { Option } = Select;
const { Header, Content } = Layout;

const Webinar = () => {
  const dispatch = useDispatch();
  const {info, isLoading, wList, pList, isLoading2} = useSelector((state: any) => ({
    info: state.info,
    wList: state.wbnr.data,
    isLoading: state.wbnr.isLoading,
    pList: state.pckg.data,
    isLoading2: state.pckg.isLoading,
  }));

  let navigate = useNavigate();
  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isAnnounce, setIsAnnounce] = useState(false);
  const [isModalPackageVisible, setModalPackageVisible] = useState(false);
  const [modalData, setModalData] = useState<any>(undefined);

  const [isOpenAlert, setOpenAlert] = useState(false);
  const [alertContent, setAlertContent] = useState({title: 'Berhasil', content: 'Webinar berhasil ditambahkan', type: 'success'})

  const [page, setPage] = useState(1);
  const [initVal, setInitVal] = useState<any>({
    name: '',
    webinarTheme: '',
    description: '',
    start: '',
    end: '',
    link_youtube: '',
    link_zoom: '',
    location: '',
    code: '',
    certificateType: '', 
    activityType: '', 
    webinarType: '', 
    tags: [], 
    speaker: [{name: '', front_title: '', end_title: '', scope: ''}],
    packages: pList,
  });

  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    loadWebinar()
    loadWebinarCategory()
    dispatch(getPackage());
    dispatch(getEvidence({}));
  }, []);

  useEffect(() => {
    if (info.id === 'WEBINAR_CREATE_SUCCESS') {
      setAlertContent({title: 'Berhasil', content: info.msg, type: 'success'});
      setTimeout(() => {
        modal.success(configSuccess);
      }, 500);
    }
    if (info.id === 'WEBINAR_CREATE_FAIL') {
      setAlertContent({title: 'Mohon Maaf', content: info.msg.message || info.msg2, type: 'failed'});
      setTimeout(() => {
        modal.error(configFailed);
      }, 500);
    }
    if (info.id === 'WEBINAR_UPDATE_STATUS_SUCCESS') {
      message.success(info.msg);
      loadWebinar()
      if (isAnnounce) {
        setTimeout(() => {
          showModalOnCreateAnnouncement()
        }, 200);
      }
    }
    if (info.id === 'WEBINAR_DELETE_SUCCESS' || info.id === 'WEBINAR_ADD_PACKAGE_SUCCESS' || info.id === 'WEBINAR_REFRESH_STATUS_SUCCESS') {
      message.success(info.msg);
      loadWebinar()
    }
    if (info.id === 'WEBINAR_DELETE_FAIL' || info.id === 'WEBINAR_ADD_PACKAGE_FAIL' || info.id === 'WEBINAR_UPDATE_STATUS_FAIL' || info.id === 'WEBINAR_REFRESH_STATUS_FAIL') {
      message.error(info.msg.message);
    }
  }, [info]);

  
  const configSuccess = {
    title: 'Berhasil',
    centered: true,
    content: (<div><p>{isUpdate ? 'Webinar berhasil diubah' : 'Webinar berhasil ditambahkan'}</p></div>),
    onOk() {
      setVisible(false);
      dispatch(clearInfo())
      resetInitVal()
      form.resetFields();
      loadWebinar()
    }
  };

  const configFailed = {
    title: 'Belum Berhasil',
    centered: true,
    content: (<div><p>{info?.msg && info?.msg[0] && info?.msg[0].message || (isUpdate ? 'Webinar belum berhasil diubah' : 'Webinar belum berhasil ditambahkan')}</p></div>),
    onOk() {
      setOpenAlert(false);
      // dispatch(clearInfo())
    }
  };

  const loadWebinar = () => {
    let param = {
      page: page,
      limit: 10000,
    }
    dispatch(getWebinar(param));
  }

  const loadWebinarCategory = () => {
    let param = {
      page: 1,
      limit: 20,
    }
    dispatch(getWebinarCategories(param));
  }

  const onSubmitAlert = () => {
    setOpenAlert(false);
    if (alertContent.type === 'success') {
      setVisible(false);
      dispatch(clearInfo())
      resetInitVal()
      form.resetFields();
      loadWebinar()
    }
    if (alertContent.type === 'failed') {
      dispatch(clearInfo())
      // form.resetFields();
    }
  }

  const onCancel = () => {
    setVisible(false);
    setModalPackageVisible(false);
  }

  const onSubmit = (val: any) => {
    // setVisible(false);
    let speakerNames = val.speaker.map((val: any) => val.name);
    let speakerTitles = val.speaker.map((val: any) => ((val.front_title || '') + ' | ' + (val.end_title || '')));
    let speakerScopes = val.speaker.map((val: any) => val.scope);
    let speakerCv = val.speaker.map((val: any) => null);
    // let speaker = val.speaker;
    let packageId = val.packages.map((val: any) => val._id);
    let packagePrice = val.packages.map((val: any) => (val.price !== 'x') ? Number(val.price) : 'x');
    let linkw = [val.link_youtube, val.link_zoom];
    // let tags  = val.tags.map((val: any) => val.name);
    let payload = {
      name: val.name,
      description: val.description,
      webinarTheme: val.webinarTheme,
      speakersNames: speakerNames,
      speakersTitles: speakerTitles,
      speakersScopes: speakerScopes,
      speakersCvs: speakerCv,
      speakers: val.speaker,
      start: moment(val.start).toISOString(),
      end: moment(val.end).toISOString(),
      links: linkw,
      link_youtube: val.link_youtube,
      link_zoom: val.link_zoom,
      tags: val.tags,
      profileImage: 'profileImage.png',
      banner: ['banner.png'],
      certificateType: val.certificateType,
      certificateNumberFormat: val.certificateNumberFormat,
      location: val.location,
      webinarType: val.webinarType,
      activityType: val.activityType,
      code: val.code,
      packageIds: packageId,
      packagePrices: packagePrice,
    };

    if (isUpdate) {
      setIsUpdate(false);
      // dispatch(updateWebinar())
    } else {
      dispatch(createWebinar(payload));
    }
  }

  const onSubmitAddPackage = (val: any) => {
    setModalPackageVisible(false);
    let payload = val;
    payload.price = parseInt(val.price);
    dispatch(addPackageToWebinar(modalData._id, payload));
  }

  const resetInitVal = () => {
    setInitVal({
      name: '',
      webinarTheme: '',
      description: '',
      start: '',
      end: '',
      link_youtube: '',
      link_zoom: '',
      location: '',
      code: '',
      certificateType: '', 
      certificateNumberFormat: '',
      activityType: '', 
      webinarType: '', 
      tags: [], 
      speaker: [{name: '', front_title: '', end_title: '', scope: ''}],
      packages: pList,
    })
  }

  const showModalDeleteConfirm = (param: any) => {
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: 'Apakah Kamu yakin akan menghapus webinar ini?',
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(removeWebinar(param._id));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const showModalChangeStatus = (param: any) => {
    let askStatus = param.status ? 'tidak aktif' : 'aktif';
    let payload = {
      status: param.status ? 'not_active' : 'active'
    }
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: `Apakah Kamu yakin akan mengubah status webinar menjadi ${askStatus}?`,
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(updateStatusWebinar(param._id, payload));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const showModalOnCreateAnnouncement = () => {
    setIsAnnounce(false);
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: `Apakah Webinar ini akan diumumkan?`,
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        navigate('/admin/announcement/add/', {state: {webinar: modalData}})
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const showModalChangeShowByUserStatus = (param: any) => {
    let askStatus = param.isShowByUser ? 'tidak bisa' : 'bisa';
    if (!param.isShowByUser) {
      setIsAnnounce(true);
      setModalData(param)
    }
    let payload = {
      isShowByUser: param.isShowByUser ? 'not_active' : 'active'
    }
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: `Apakah Kamu yakin akan mengubah status webinar menjadi ${askStatus} dilihat user?`,
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(updateStatusWebinar(param._id, payload));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const showModalChangeShowInLPStatus = (param: any) => {
    let askStatus = param.isShowInLandingPage ? 'tidak bisa' : 'bisa';
    let payload = {
      isShowInLandingPage: param.isShowInLandingPage ? 'not_active' : 'active'
    }
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: `Apakah Kamu yakin akan mengubah status webinar menjadi ${askStatus} dilihat di Landing Page?`,
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(updateStatusWebinar(param._id, payload));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const columns = [
    {
      title: 'Kode',
      dataIndex: 'code',
      key: 'code',
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Waktu Pelaksanaan',
      dataIndex: 'start',
      key: 'start',
      render: (tanggal: any) => <text>{moment(tanggal).format('DD MMM YYYY HH:mm')}</text>,
    },
    {
      title: 'Status Webinar',
      key: 'status',
      render: (item: any, record: any) => (
        <Space size="middle">
          <Switch checkedChildren="Aktif" unCheckedChildren="Aktif" checked={record.status} onChange={() => showModalChangeStatus(record)}  />
          <Switch checkedChildren="User" unCheckedChildren="User" checked={record.isShowByUser} onChange={() => showModalChangeShowByUserStatus(record)} />
          <Switch checkedChildren="LP" unCheckedChildren="LP" checked={record.isShowInLandingPage} onChange={() => showModalChangeShowInLPStatus(record)} />
        </Space>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (item: any, record: any) => (
        <Space size="middle">
          <Link to={{pathname: '/admin/webinar/detail/'+record._id}}>Rincian</Link>
          <Link to={{pathname: '/admin/webinar/participant/'+record._id}} state={record}>Peserta</Link>
          <Link to={{pathname: '/admin/webinar/posttest/'+record._id}}>Post Test</Link>
          {/* <Link to={{pathname: '/admin/webinar/certificate/'+record._id}}>Sertifikat</Link> */}
          <a
            onClick={() => {
              showModalDeleteConfirm(record);
            }}
          >Hapus</a>
        </Space>
      ),
    },
  ];
  
  const expandedRowRender = (record: any) => {
    const columns = [
      { title: 'Paket', dataIndex: 'name', key: 'name' },
      { title: 'Deskripsi', dataIndex: 'description', key: 'description' },
      { title: 'Harga', dataIndex: 'price', key: 'price' },
      { title: 'Syarat', dataIndex: 'term', key: 'term' },
      {
        title: 'Aksi',
        dataIndex: 'operation',
        key: 'operation',
        render: () => (
          <Space size="middle">
            <a>Ubah</a>
          </Space>
        ),
      },
    ];

    return <Table columns={columns} dataSource={record.packages} pagination={false} />;
  };

  function range(start: any, end: any) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }
  
  function disabledDateTime() {
    return {
      disabledHours: () => range(0, 24).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }

  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title="Daftar Webinar"
        // breadcrumb={{ routes }}
        extra={[
          <Button key="2" type="default" onClick={() => dispatch(refreshWebinarStatus())}>Refresh Status Webinar</Button>,
          <Button key="3" type="primary" onClick={() => setVisible(true)}>Buat Webinar</Button>
        ]}
      />
      <Table 
        className="components-table-demo-nested"
        columns={columns} 
        dataSource={wList} 
        loading={isLoading} 
        expandable={{ expandedRowRender }}
        // scroll={{ y: 1000, x: '20vw' }} 
      />

      <Modal
        open={visible}
        title={isUpdate ? "Ubah info webinar" : "Tambah webinar baru"}
        okText={isUpdate ? "Ubah" : "Tambah"}
        cancelText="Batal"
        onCancel={onCancel}
        width={768}
        onOk={() => {
          form
            .validateFields()
            .then((values: any) => {
              onSubmit(values);
            })
            .catch((info: any) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        {pList && pList.length > 0 && (
          <WebinarFormComponent
            initVal= {initVal} 
            form= {form}
          />
        )}
      </Modal>

      <Modal
        open={isModalPackageVisible}
        title={isUpdate ? "Ubah paket webinar" : "Tambah paket webinar"}
        okText={isUpdate ? "Ubah" : "Tambah"}
        cancelText="Batal"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then((values: any) => {
              form.resetFields();
              onSubmitAddPackage(values);
            })
            .catch((info: any) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={initVal}
          // initialValues={{name: initVal.name}}
        >
          {/* <Form.Item
            name="name"
            label="Nama"
            rules={[{ required: true, message: 'Nama paket harus diisi' }]}
          >
            <Input />
          </Form.Item> */}
          <Form.Item name="name" label="Nama Paket" rules={[{ required: true }]}>
            <Select
              placeholder="Pilih Paket"
              // onChange={onGenderChange}
              allowClear
            >
              <Option value="Basic">Basic</Option>
              <Option value="Silver">Silver</Option>
              <Option value="Gold">Gold</Option>
              <Option value="Platinum">Platinum</Option>
            </Select>
          </Form.Item>
          <Form.Item 
            name="description" 
            label="Description"
            rules={[{ required: true, message: 'Deskripsi paket harus diisi' }]}
          >
            <Input type="textarea" />
          </Form.Item>
          <Form.Item
            name="price"
            label="Harga"
            rules={[{ required: true, message: 'Harga harus diisi!' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="term"
            label="Ketentuan"
            rules={[{ required: true, message: 'Ketentuan harus diisi!' }]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      {/* {modal} */}
      {contextHolder}
    </Content>
  )
};

export default Webinar;

