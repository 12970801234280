import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { List, Card , Form, Modal, Typography, message, Select, Tag, Space, PageHeader, Layout, Input, Button } from 'antd';
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { getWebinar, createWebinar, addPackageToWebinar, removeWebinar, loadWebinarDetail, addSpeakerAvatar } from '../../store/actions/WebinarAction';
import { getPackage } from '../../store/actions/PackageAction';
import { formatUang } from '../../shared/helper';
import { createQuestionWebinar, getPostTestWebinar } from '../../store/actions/PostTestAction';
import PostTestFormComponent from './component/PostTestFormComponent';
import { useNavigate, useParams } from 'react-router-dom';
let moment = require('moment');

const { Option } = Select;
const { Header, Content } = Layout;
const { Text } = Typography;

const routes = [
  {
    path: '/',
    breadcrumbName: 'Dashboard',
  },
  {
    path: 'first',
    breadcrumbName: 'Webinar',
  },
  {
    path: 'second',
    breadcrumbName: 'Post Test',
  },
];

const WebinarPostTest = (props: any) => {
  const dispatch = useDispatch();
  const {info, pList, isLoading, wDetail} = useSelector((state: any) => ({
    info: state.info,
    pList: state.pote.data,
    isLoading: state.pote.isLoading,
    wDetail: state.wbnr.wDetail,
  }));
  let navigate = useNavigate();
  let { id } = useParams();

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(undefined);
  const [selectedSpeaker, setSelectedSpeaker] = useState<any>(undefined);
  const [isOpenForm, setOpenForm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isModalPackageVisible, setModalPackageVisible] = useState(false);
  const [modalData, setModalData] = useState<any>(undefined);
  const [initVal, setInitVal] = useState<any>({
    name: '',
    description: '',
    speaker: '',
    start: '',
    end: '',
    link: '',
    location: '',
    code: '',
    packages: pList,
  });

  useEffect(() => {
    loadData()
  }, []);

  useEffect(() => {
    if (info.id === 'POSTEST_CREATE_SUCCESS') {
      message.success(info.msg);
      loadData()
    }
    if (info.id === 'POSTEST_CREATE_FAIL') {
      message.error(info.msg.message);
    }
  }, [info]);

  const loadData = () => {    
    dispatch(loadWebinarDetail(id));
    dispatch(getPostTestWebinar(id));
  } 
  
  const onCancel = () => {
    setVisible(false);
    setOpenForm(false)
  }

  const onSubmit = (val: any) => {
    setVisible(false);
    let wId = id;
    let payload = {
      testTitle: val.testTitle, 
      testSubtitle: val.testSubtitle, 
      testCase: val.testCase, 
      testNumber: val.testNumber, 
      testQuestion: val.testQuestion, 
      testOptAmount: val.testOptAmount, 
      testOpt1: val.testOpt1, 
      testOpt2: val.testOpt2, 
      testOpt3: val.testOpt3, 
      testOpt4: val.testOpt4, 
      testOpt5: val.testOpt5, 
      testAnswer: val.testAnswer, 
      testCorrectPoint: val.testCorrectPoint, 
      testWrongPoint: val.testWrongPoint
    }
    if (isUpdate) {
      setIsUpdate(false);
      // dispatch(updateMaterial(initVal._id, val));
      resetInitVal()
    } else {
      dispatch(createQuestionWebinar(payload, wId));
    }
  }

  const resetInitVal = () => {
    setInitVal({
      name: '',
      description: '',
      point: '',
      category: '',
    })
  }

  const showModalDeleteConfirm = (param: any) => {
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: 'Apakah Kamu yakin akan menghapus pertanyaan ini?',
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(removeWebinar(param._id));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const renderQuestionCard = () => {
    if (pList && pList.length > 0) {
      return pList.map((item: any, ind: any) => {
        return (
          <Card 
            key={ind}
            type="inner" 
            title={`Pertanyaan ${ind+1}`} 
            extra={<a href="#">Ubah</a>}
          >
            <h3>{item.testQuestion}</h3>
            <p>{'a. ' + item.testOpt1}</p>
            <p>{'b. ' + item.testOpt2}</p>
            {parseInt(item.testOptAmount) >= 3 && (
              <p>{'c. ' + item.testOpt3}</p>
            )}
            {parseInt(item.testOptAmount) >= 4 && (
              <p>{'d. ' + item.testOpt4}</p>
            )}
            {parseInt(item.testOptAmount) === 5 && (
              <p>{'e. ' + item.testOpt5}</p>
            )}

            <h4>Jawaban: {item[item.testAnswer]}</h4>
          </Card>
        )  
      });
    } else {
      return (
        <Card type="inner" >
          <p>Belum ada pertanyaan</p>
        </Card>
      )
    }
  }

  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title={wDetail && wDetail.name}
        // breadcrumb={{ routes }}
        extra={[
          <Button key="3" type="primary" onClick={() => setVisible(true)}>Tambah Pertanyaan</Button>
        ]}
      />
      {renderQuestionCard()}

      <Modal
        visible={visible}
        title={isUpdate ? "Ubah Pertanyaan" : "Tambah Pertanyaan"}
        okText={isUpdate ? "Ubah" : "Tambah"}
        cancelText="Batal"
        onCancel={onCancel}
        width={768}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              form.resetFields();
              onSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <PostTestFormComponent 
          initVal= {initVal} 
          form= {form}
        />
      </Modal>
    </Content>
  )
};

export default WebinarPostTest;