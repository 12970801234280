import { EVIDENCE_CREATE_FAIL, EVIDENCE_CREATE_SUCCESS, EVIDENCE_DELETE_FAIL, EVIDENCE_DELETE_SUCCESS, EVIDENCE_GET_FAIL, EVIDENCE_GET_SUCCESS, EVIDENCE_LOADING, EVIDENCE_UPDATE_FAIL, EVIDENCE_UPDATE_SUCCESS } from '../types';

const initialState = {
  data: [],
  total: 0,
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case EVIDENCE_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case EVIDENCE_GET_SUCCESS:
      return {
        ...state,
        data: action.resp.evidences,
        total: action.resp.total,
        isLoading: false,
      };
    case EVIDENCE_GET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case EVIDENCE_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case EVIDENCE_CREATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case EVIDENCE_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case EVIDENCE_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case EVIDENCE_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case EVIDENCE_DELETE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
