import { Table, Modal, Input, Select, PageHeader, Layout, Row, Col, Button } from 'antd';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getMutation, getPaymentList, getXenditInvoices } from '../../store/actions/PaymentAction';
import { formatUang2 } from '../../shared/helper';

let moment = require('moment');
const { Search } = Input;

const { Option } = Select;
const { Header, Content } = Layout;

const PaymentNotXendit = () => {
  const dispatch = useDispatch();
  const {info, isLoading, paymentList} = useSelector((state: any) => ({
    info: state.info,
    paymentList: state.pymn.data,
    isLoading: state.pymn.isLoading,
  }));

  const [sValue, setSearchValue] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [initVal, setInitVal] = useState<any>({
    name: '',
    description: '',
    point: '',
    category: '',
  });

  useEffect(() => {
    loadMutation()
  }, [sValue]);

  const loadMutation = () => {
    let qParam = {
      amount: sValue ? sValue : '',
      bank: ''
    }
    dispatch(getMutation(qParam));
    dispatch(getXenditInvoices());
  }

  const showModalConfirm = (param: any) => {
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: 'Apakah Kamu yakin akan menghapus material ini?',
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        // dispatch(removeMaterial(param._id));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const onSearch = (value: string) => setSearchValue(value);

  const columns = [
    {
      title: 'Tanggal',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (tanggal: any) => <text>{moment(tanggal).format('DD MMM YYYY HH:mm')}</text>,
    },
    {
      title: 'ID Mutasi',
      dataIndex: 'mutation_id',
      key: 'mutation_id',
    },
    {
      title: 'Bank',
      key: 'bank',
      dataIndex: ['bank', 'label']
    },
    {
      title: 'Jenis',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Nominal',
      key: 'amount',
      dataIndex: 'amount',
      render: (amount: any) => <text>{formatUang2(amount, undefined)}</text>,

    },
    {
      title: 'Deskripsi',
      key: 'description',
      dataIndex: 'description'
    },
  ];

  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title="Daftar Pembayaran"
        // breadcrumb={{ routes }}
      />
      {/* <Row>
        <Col span={21}><Search placeholder="input search text" onSearch={onSearch} enterButton /></Col>
        <Col span={3}><Button block onClick={() => setSearchValue('')}>Reset</Button></Col>
      </Row> */}
      <Table columns={columns} dataSource={paymentList} />

    </Content>
  )
};

export default PaymentNotXendit;