import {httpRequestAxiosService} from '../../services';
import {returnErrors, returnSuccess} from './InfoAction';
import {
  PAYMENT_LOADING,
  PAYMENT_GET_SUCCESS,
  PAYMENT_GET_FAIL,
  PAYMENT_G_GET_SUCCESS,
  PAYMENT_G_GET_FAIL,
  PAYMENT_XENDIT_GET_SUCCESS,
  PAYMENT_XENDIT_GET_FAIL,
  PAYMENT_XENDIT_CHECK_SUCCESS,
  PAYMENT_XENDIT_CHECK_FAIL,
} from '../types';
import { mootaTokenConfig, tokenConfig } from './AuthAction';

// Get mutation list from moota
export const getMutation = (param: any) => (dispatch: any, getState: any) => {
  dispatch({type: PAYMENT_LOADING});

  httpRequestAxiosService.get(`${process.env.REACT_APP_MOOTA_API}?amount=${param.amount}&bank=${param.bank}`, mootaTokenConfig())
    .subscribe((resp: any) => {
      dispatch({
        type: PAYMENT_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Daftar mutasi berhasil diperoleh.', 200, 'PAYMENT_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Data mutasi belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'PAYMENT_GET_FAIL',
        ),
      );
      dispatch({
        type: PAYMENT_GET_FAIL,
      });
  })
};

// Get mutation list from DB
export const getPaymentList = () => (dispatch: any, getState: any) => {
  dispatch({type: PAYMENT_LOADING});

  httpRequestAxiosService.get(`${process.env.REACT_APP_MAIN_API}payments`, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: PAYMENT_G_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Daftar mutasi berhasil diperoleh.', 200, 'PAYMENT_G_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Data mutasi belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'PAYMENT_G_GET_FAIL',
        ),
      );
      dispatch({
        type: PAYMENT_G_GET_FAIL,
      });
  })
};

// Get mutation list from DB
export const getXenditInvoices = () => (dispatch: any, getState: any) => {
  dispatch({type: PAYMENT_LOADING});

  httpRequestAxiosService.get(`${process.env.REACT_APP_MAIN_API}payments/xendit-invoices`, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: PAYMENT_XENDIT_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Daftar mutasi berhasil diperoleh.', 200, 'PAYMENT_XENDIT_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Data mutasi belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'PAYMENT_XENDIT_GET_FAIL',
        ),
      );
      dispatch({
        type: PAYMENT_XENDIT_GET_FAIL,
      });
  })
};

export const checkInvoiceStatus = (qparam: any = undefined) => (dispatch: any, getState: any) => {
  dispatch({type: PAYMENT_LOADING});

  httpRequestAxiosService.get(`${process.env.REACT_APP_MAIN_API}users/${qparam.userId}/webinars/${qparam.webinarId}/check-xendit`, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: PAYMENT_XENDIT_CHECK_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Cek status pembayaran berhasil dilakukan.', 200, 'PAYMENT_XENDIT_CHECK_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Cek status pembayaran belum berhasil.',
          (err && err.response && err.response.status) || 401,
          'PAYMENT_XENDIT_CHECK_FAIL',
        ),
      );
      dispatch({
        type: PAYMENT_XENDIT_CHECK_FAIL,
      });
  })
};