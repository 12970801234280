import {httpRequestAxiosService} from '../../services';
import {returnErrors, returnSuccess} from './InfoAction';
import {
  USER_LOADING,
  USER_CREATE_SUCCESS,
  USER_CREATE_FAIL,
  USER_GET_SUCCESS,
  USER_GET_FAIL,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAIL,
  USER_DELETE_SUCCESS,
  USER_DELETE_FAIL,
  USER_GET_DETAIL_SUCCESS,
  USER_GET_DETAIL_FAIL,
} from '../types';
import { tokenConfig } from './AuthAction';

// Get User
export const getUser = (qparam: any = undefined) => (dispatch: any, getState: any) => {
  dispatch({type: USER_LOADING});
  let param = {
    page: qparam && qparam.page ? qparam.page : 1,
    limit: qparam && qparam.limit ? qparam.limit : 10,
  };

  httpRequestAxiosService.get(process.env.REACT_APP_MAIN_API + 'users?page=' + param.page + '&limit=' + param.limit, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: USER_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Daftar user berhasil diperoleh.', 200, 'USER_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Data user belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'USER_GET_FAIL',
        ),
      );
      dispatch({
        type: USER_GET_FAIL,
      });
  })
};

// Get User
export const getUserById = (qparam: any = undefined) => (dispatch: any, getState: any) => {
  dispatch({type: USER_LOADING});

  httpRequestAxiosService.get(process.env.REACT_APP_MAIN_API + `users/${qparam}`, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: USER_GET_DETAIL_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Daftar user berhasil diperoleh.', 200, 'USER_GET_DETAIL_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Data user belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'USER_GET_DETAIL_FAIL',
        ),
      );
      dispatch({
        type: USER_GET_DETAIL_FAIL,
      });
  })
};

// add new user
export const createUser = (payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: USER_LOADING});

  httpRequestAxiosService.post(process.env.REACT_APP_MAIN_API + 'users', payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: USER_CREATE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('User berhasil ditambahkan.', 200, 'USER_CREATE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'USER_CREATE_FAIL',
          'User belum berhasil ditambahkan',
        ),
      );
      dispatch({
        type: USER_CREATE_FAIL,
      });
  })
};

// remove user
export const removeUser = (paramId: any) => (dispatch: any, getState: any) => {
  dispatch({type: USER_LOADING});

  httpRequestAxiosService.delete(process.env.REACT_APP_MAIN_API + 'users/' + paramId, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: USER_DELETE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Akun berhasil dihapus.', 200, 'USER_DELETE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'USER_DELETE_FAIL',
          'Akun belum berhasil dihapus',
        ),
      );
      dispatch({
        type: USER_DELETE_FAIL,
      });
  })
};