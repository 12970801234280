import { Table, Tag, Space, PageHeader, Layout, Switch } from 'antd';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { PageContainer, FooterToolbar } from '@ant-design/pro-layout';
import ProTable from '@ant-design/pro-table';
import type { ProColumns, ActionType } from '@ant-design/pro-table';
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Input, message, Select, Divider } from 'antd';
import { createPackage, getPackage, removePackage, updatePackage } from '../../../store/actions/PackageAction';
import { createPaymentMethod, getAllPaymentMethod, removePaymentMethod, updatePaymentMethod } from '../../../store/actions/PaymentMethodActions';

const { Header, Content } = Layout;
const { Option } = Select;

const PaymentMethodPage = () => {
  const dispatch = useDispatch();
  const {info, isLoading, pmList} = useSelector((state: any) => ({
    info: state.info,
    pmList: state.pmtd.data,
    isLoading: state.pmtd.isLoading,
  }));

  const actionRef = useRef<ActionType>();
  const [form] = Form.useForm();
  const [isAddFormVisible, setAddFormVisible] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isConfirmDelete, setConfirmDelete] = useState(false);
  const [initVal, setInitVal] = useState<any>({
    method_name: '',
    account_code: '',
    account_name: '',
    account_number: '',
    account_owner: '',
    notes: '',
  });

  useEffect(() => {
    dispatch(getAllPaymentMethod());
  }, []);

  useEffect(() => {
    if (info.id === 'MD_PAYMENT_METHOD_CREATE_SUCCESS' || info.id === 'MD_PAYMENT_METHOD_UPDATE_SUCCESS' || info.id === 'MD_PAYMENT_METHOD_REMOVE_SUCCESS') {
      message.success(info.msg);
      dispatch(getAllPaymentMethod());
    }
    if (info.id === 'MD_PAYMENT_METHOD_REMOVECREATE_FAIL' || info.id === 'MD_PAYMENT_METHOD_UPDATE_FAIL' || info.id === 'MD_PAYMENT_METHOD_REMOVE_FAIL') {
      message.error(info.msg.message);
    }
  }, [info]);

  const onCancel = () => {
    setAddFormVisible(false);
    setIsUpdate(false);
  }

  const onSubmit = (val: any) => {
    setAddFormVisible(false);
    if (isUpdate) {
      setIsUpdate(false);
      dispatch(updatePaymentMethod(initVal._id, val));
      resetInitVal()
    } else {
      dispatch(createPaymentMethod(val));
    }
  }

  const resetInitVal = () => {
    setInitVal({
      account_code: '',
      account_name: '',
      account_number: '',
      account_owner: '',
      notes: ''
    })
  }

  const showModalConfirm = (param: any) => {
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: 'Apakah Kamu yakin akan menghapus metode pembayaran ini?',
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(removePaymentMethod(param._id));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const showModalChangeStatus = (param: any) => {
    let askStatus = (param.status === 'active') ? 'tidak aktif' : 'aktif';
    let payload = {
      status: (param.status === 'active') ? 'not_active' : 'active'
    }
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: `Apakah Kamu yakin akan mengubah status metode pembayaran menjadi ${askStatus}?`,
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(updatePaymentMethod(param._id, payload));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const columns = [
    {
      title: 'Kode Bank',
      dataIndex: 'account_code',
      key: 'account_code',
    },
    {
      title: 'Bank',
      dataIndex: 'account_name',
      key: 'account_name',
    },
    {
      title: 'No Rekening',
      dataIndex: 'account_number',
      key: 'account_number',
    },
    {
      title: 'Pemilik Rekening',
      dataIndex: 'account_owner',
      key: 'account_owner',
    },
    {
      title: 'Aksi',
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          <Switch checkedChildren="Aktif" unCheckedChildren="Aktif" checked={(record.status === 'active')} onChange={() => showModalChangeStatus(record)}  />
          <a 
            onClick={() => {
              setInitVal(record);
              form.setFieldsValue(record);
              setIsUpdate(true);
              setTimeout(() => {
                setAddFormVisible(true);
              }, 1000);
            }}
          >Ubah</a>
          <a
            onClick={() => {
              showModalConfirm(record);
            }}
          >Hapus</a>
        </Space>
      ),
    },
  ];

  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        extra={[
          <Button key="3" type="primary" onClick={() => setAddFormVisible(true)}>Tambah Metode Pembayaran</Button>
        ]}
      />
      <Table columns={columns} loading={isLoading} dataSource={pmList} />

      <Modal
        visible={isAddFormVisible}
        title={isUpdate ? "Ubah Metode Pembayaran" : "Tambah Metode Pembayaran"}
        okText={isUpdate ? "Ubah" : "Tambah"}
        cancelText="Batal"
        onCancel={onCancel}
        onOk={() => {
          form
            .validateFields()
            .then(values => {
              form.resetFields();
              onSubmit(values);
            })
            .catch(info => {
              console.log('Validate Failed:');
            });
        }}
      >
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={initVal}
          // initialValues={{name: initVal.name}}
        >
          <Form.Item name="method_name" label="Jenis Pembayaran" rules={[{ required: true, message: 'Nama material harus diisi!' }]}>
            <Select
              placeholder="Jenis Pembayran"
              allowClear
            >
              <Option value={'transfer_bank'}>Transfer Bank</Option>
              <Option value={'ewallet'}>E Wallet</Option>
              <Option value={'tunai'}>Tunai</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="account_code"
            label="Kode Penyedia Pembayaran (misal: BCA/BNI/OVO)"
            rules={[{ required: true, message: 'Kode penyedia pembayaran harus diisi!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="account_name"
            label="Nama Penyedia Pembayaran (misal: Bank Central Asia, OVO, dll)"
            rules={[{ required: true, message: 'Nama penyedia pembayaran harus diisi!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="account_number"
            label="No Rekening atau sejenisnya"
            rules={[{ required: true, message: 'No rekening harus diisi!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="account_owner"
            label="Pemilik Rekening"
            rules={[{ required: true, message: 'Pemilik rekening harus diisi!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="notes"
            label="Keterangan Tambahan"
          >
            <Input />
          </Form.Item>
          
        </Form>
      </Modal>
      {/* {modal} */}
    </Content>
  )
};

export default PaymentMethodPage;