import { Table, Modal, Input, Select, PageHeader, Layout, Row, Col, Button, Card } from 'antd';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { getMutation, getPaymentList, getXenditInvoices } from '../../store/actions/PaymentAction';
import { formatUang2, formatUang3 } from '../../shared/helper';

let moment = require('moment');
const { Search } = Input;

const { Option } = Select;
const { Header, Content } = Layout;
const routes = [
  {
    path: 'index',
    breadcrumbName: 'Dashboard',
  },
  {
    path: 'first',
    breadcrumbName: 'Pembayaran Xendit',
  },
];

const PaymentXendit = () => {
  const dispatch = useDispatch();
  const {info, isLoading, paymentList, invXList} = useSelector((state: any) => ({
    info: state.info,
    paymentList: state.pymn.data,
    invXList: state.pymn.dataX,
    isLoading: state.pymn.isLoading,
  }));

  const [sValue, setSearchValue] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [initVal, setInitVal] = useState<any>({
    name: '',
    description: '',
    point: '',
    category: '',
  });

  useEffect(() => {
    loadMutation()
  }, [sValue]);

  const loadMutation = () => {
    let qParam = {
      amount: sValue ? sValue : '',
      bank: ''
    }
    dispatch(getMutation(qParam));
    dispatch(getXenditInvoices());
  }

  const showModalConfirm = (param: any) => {
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: 'Apakah Kamu yakin akan menghapus material ini?',
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        // dispatch(removeMaterial(param._id));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const onSearch = (value: string) => setSearchValue(value);

  const columns = [
    {
      title: 'Tanggal',
      dataIndex: 'created',
      key: 'created',
      render: (tanggal: any) => <text>{moment(tanggal).format('DD MMM YYYY HH:mm')}</text>,
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Bank',
      key: 'bank_code',
      dataIndex: 'bank_code'
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Inv Exp Date',
      dataIndex: 'expiry_date',
      key: 'expiry_date',
      render: (tanggal: any) => <text>{tanggal ? moment(tanggal).format('DD MMM YYYY HH:mm') : '-'}</text>,
    },
    {
      title: 'Nominal',
      key: 'amount',
      dataIndex: 'amount',
      render: (amount: any) => <text>{formatUang3(amount, undefined)}</text>,
    },
    {
      title: 'Invoice URL',
      dataIndex: 'invoice_url',
      key: 'invoice_url',
      render: (url: any) => <a href={url}>URL Invoice</a>,
    },
    {
      title: 'Pelanggan',
      key: 'pelanggan',
      dataIndex: ['customer', 'given_names']
    },
    {
      title: 'No HP',
      key: 'nohppelanggan',
      dataIndex: ['customer', 'mobile_number']
    },
    {
      title: 'Email',
      key: 'emailpelanggan',
      dataIndex: 'payer_email'
    },
    {
      title: 'Deskripsi',
      key: 'description',
      dataIndex: 'description'
    },
  ];

  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title="Daftar Pembayaran via XENDIT"
        // breadcrumb={{ routes }}
      />
      {/* <Row>
        <Col span={21}><Search placeholder="input search text" onSearch={onSearch} enterButton /></Col>
        <Col span={3}><Button block onClick={() => setSearchValue('')}>Reset</Button></Col>
      </Row> */}
      {/* <Table columns={columns} dataSource={invXList} /> */}

      <Row gutter={[16, 16]}>
        {invXList.map((item: any, index: any) => (
          <Col span={8} key={index}>
            <Card
              title={`ID: ${item.id}`}
              bordered={true}
              style={{ width: '100%' }}
            >
              <p><strong>Tanggal:</strong> {moment(item.created).format('DD MMM YYYY HH:mm')}</p>
              <p><strong>Bank:</strong> {item.bank_code}</p>
              <p><strong>Status:</strong> {item.status}</p>
              <p><strong>Inv Exp Date:</strong> {item.expiry_date ? moment(item.expiry_date).format('DD MMM YYYY HH:mm') : '-'}</p>
              <p><strong>Nominal:</strong> {formatUang3(item.amount, undefined)}</p>
              <p><strong>Invoice URL:</strong> <a href={item.invoice_url} target="_blank" rel="noopener noreferrer">URL Invoice</a></p>
              <p><strong>Pelanggan:</strong> {item.customer?.given_names || '-'}</p>
              <p><strong>No HP:</strong> {item.customer?.mobile_number || '-'}</p>
              <p><strong>Email:</strong> {item.payer_email || '-'}</p>
              <p><strong>Deskripsi:</strong> {item.description || '-'}</p>
            </Card>
          </Col>
        ))}
      </Row>

    </Content>
  )
};

export default PaymentXendit;