import { Table, Tag, Space, PageHeader, Layout, Descriptions } from 'antd';
import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Input, message, Select, Divider } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { getImportedParticipant, removeAllImportedParticipant, removeImportedParticipant } from '../../store/actions/ImportExportActions';
let moment = require('moment');

const { Option } = Select;
const { Header, Content } = Layout;

const ParticipantImport = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {info, isLoading, uList, uTotal} = useSelector((state: any) => ({
    info: state.info,
    uList: state.imex.data,
    uTotal: state.imex.total,
    isLoading: state.imex.isLoading,
  }));

  const [page, setPage] = useState(1);

  useEffect(() => {
    loadParticipant()
  }, [page]);

  useEffect(() => {
    if (info.id === 'EXPORT_IMPORT_PARTICIPANT_REMOVE_ALL_SUCCESS' || info.id === 'EXPORT_IMPORT_PARTICIPANT_REMOVE_BY_ID_SUCCESS') {
      message.success(info.msg);
      loadParticipant()
    }
    if (info.id === 'EXPORT_IMPORT_PARTICIPANT_REMOVE_ALL_FAIL' || info.id === 'EXPORT_IMPORT_PARTICIPANT_REMOVE_BY_ID_FAIL') {
      message.error(info.msg.message);
    }
  }, [info]);

  const loadParticipant = () => {
    let param = {
      page: page,
      limit: 10,
    }
    dispatch(getImportedParticipant(param));
  }

  const showModalDeleteConfirm = (param: any) => {
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: 'Apakah Kamu yakin akan menghapus peserta ini?',
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(removeImportedParticipant(param._id));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const showModalDeleteAllConfirm = () => {
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: 'Apakah Kamu yakin akan menghapus semua data?',
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(removeAllImportedParticipant())
        // console.log('dlete all')
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const columns = [
    {
      title: 'Nama',
      dataIndex: 'nama',
      key: 'nama',
    },
    {
      title: 'Kategori',
      dataIndex: 'kategori',
      key: 'kategori',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'No HP',
      dataIndex: 'nohp',
      key: 'nohp',
    },
    {
      title: 'Tanggal Terdata',
      dataIndex: 'tgl_input',
      key: 'tgl_input',
      render: (tanggal: any) => <text>{moment(tanggal).format('DD MMM YYYY HH:mm')}</text>,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text: any, record: any) => (
        <Space size="middle">
          {/* <Link to={{pathname: '/admin/participant/detail/'+record._id}} state={record}>Rincian</Link> */}
          {/* <a>Blokir</a> */}
          <a onClick={() => {
            showModalDeleteConfirm(record);
          }}>Hapus</a>
        </Space>
      ),
    },
  ];

  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title="Daftar Peserta hasil Import"
        extra={[
          <Button key="0" type="default" onClick={() => showModalDeleteAllConfirm()}>Hapus Semua Data</Button>,
          <Button key="1" type="primary" onClick={() => navigate('/admin/imported-participant/add/')}>Import Data</Button>
        ]}
      >
        <Descriptions size="small" column={3}>
          <Descriptions.Item label="">Peserta hasil import belum teregistrasi di aplikasi</Descriptions.Item>
        </Descriptions>
      </PageHeader>
      <Table 
        columns={columns} 
        dataSource={uList} 
        loading={isLoading} 
        // scroll={{ y: 500, x: '100vw' }} 
        pagination={{
          hideOnSinglePage: true,
          total: uTotal,
          onChange: (val) => setPage(val),
          // onShowSizeChange: (val, val2) => setLimit(val2)
        }}
      />
    </Content>
  )
};

export default ParticipantImport;