import {useEffect, useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { List, Card , Form, Modal, Typography, message, Select, Tag, Space, PageHeader, Layout, Input, Button, Row, Col } from 'antd';
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import {useNavigate, useParams} from 'react-router-dom';

import { removeWebinar, loadWebinarDetail, addWebinarCertificate } from '../../store/actions/WebinarAction';

const { Option } = Select;
const { Header, Content } = Layout;
const { Text } = Typography;

const routes = [
  {
    path: '/',
    breadcrumbName: 'Dashboard',
  },
  {
    path: 'first',
    breadcrumbName: 'Webinar',
  },
  {
    path: 'second',
    breadcrumbName: 'Sertifikat',
  },
];

function WebinarCertificate(props: any) {
  const dispatch = useDispatch();
  const {info, pList, isLoading, wDetail} = useSelector((state: any) => ({
    info: state.info,
    pList: state.pote.data,
    isLoading: state.pote.isLoading,
    wDetail: state.wbnr.wDetail,
  }));
  let navigate = useNavigate();
  const { id } = useParams();

  const [textHorizontal, setTextHorizontal] = useState(50);
  const [textVertical, setTextVertical] = useState(10);
  const [fillStyle, setFillStyle] = useState("#000");
  const [lineStyle, setLineStyle] = useState("#ffff00");
  const [certName, setCertificateName] = useState("Cek Nama");

  const [form] = Form.useForm();
  const [visible, setVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(undefined);
  const [isOpenForm, setOpenForm] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [files, setFiles] = useState<File[]>([])
  const [isModalPackageVisible, setModalPackageVisible] = useState(false);
  const [modalData, setModalData] = useState<any>(undefined);
  const [initVal, setInitVal] = useState<any>({
    name: '',
    description: '',
    speaker: '',
    start: '',
    end: '',
    link: '',
    location: '',
    code: '',
    packages: pList,
  });

  useEffect(() => {
    loadData()
    if (selectedFile && certName) {
      onShowCertificate(selectedFile, certName);
    }
  }, [certName, textHorizontal, textVertical]);

  useEffect(() => {
    if (info.id === 'WEBINAR_ADD_CERTIFICATE_SUCCESS') {
      message.success(info.msg);
      loadData()
      // navigate(`admin/webinar/detail/${id}`)
    }
    if (info.id === 'WEBINAR_ADD_CERTIFICATE_FAIL') {
      message.error(info.msg.message);
    }
  }, [info]);

  const loadData = () => {
    dispatch(loadWebinarDetail(id));
  } 

  const handleFile = useCallback(
    (f: File | FileList) => {
      setFiles([...files, ...Object.values(f)])
    },
    [files]
  )
  
  const onChangeText = (e: any) => {
    setCertificateName(e.target.value);
  }

  const onFileSelected = (e: any) => {
    setSelectedFile(e.target.files[0]);
    if(e.target.files) {
      onShowCertificate(e.target.files[0], certName);
    }
  }

  const onShowCertificate = (imgFile: any, name: any) => {
    let imageFile = imgFile; //here we get the image file
    var reader = new FileReader();

    let textLength = measureText(certName);

    reader.readAsDataURL(imageFile);
    reader.onloadend = function (e: any) {
      var myImage: any = new Image(); // Creates image object
      myImage.src = e.target.result; // Assigns converted image to image object
      // addTextToImage(myImage.src, certName


      
      myImage.onload = function(ev: any) {
        var myCanvas: any = document.getElementById("myCanvas"); // Creates a canvas object
        var myContext = myCanvas.getContext("2d"); // Creates a contect object
        
        myCanvas.width = myImage.width; // Assigns image's width to canvas
        myCanvas.height = myImage.height; // Assigns image's height to canvas
        let widthShow = myImage.width * 0.5;
        let heightShow = myImage.height * 0.5;
        
        let textV = heightShow/2 + textVertical;
        let textH = (widthShow-textLength-textHorizontal) / 2;
        
        myContext.drawImage(myImage,0,0, widthShow, heightShow); // Draws the image on canvas
        myContext.lineWidth = 1;
        myContext.fillStyle = fillStyle;
        myContext.lineStyle = lineStyle;
        myContext.font = "18px sans-serif";
        myContext.fillText(certName, textH, textV);
      }
    }
  }

  // Save | Download image
  const onDownloadCertificate = () => {
    var canvas: any = document.querySelector('#myCanvas');

    var dataURL = canvas.toDataURL("image/jpeg", 1.0);

    downloadImage(dataURL, 'my-canvas.jpeg');
  }

  function downloadImage(data: any, filename = 'untitled.jpeg') {
    var a = document.createElement('a');
    a.href = data;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
  }

  const onCancel = () => {
    setVisible(false);
    setOpenForm(false)
  }

  const onUploadFile = (val: any) => {
    const formData = new FormData();
    formData.append("certificate", selectedFile);

    let wId = wDetail && wDetail._id;

    dispatch(addWebinarCertificate(wId, formData));
  }

  const resetInitVal = () => {
    setInitVal({
      name: '',
      description: '',
      point: '',
      category: '',
    })
  }

  const showModalDeleteConfirm = (param: any) => {
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: 'Apakah Kamu yakin akan menghapus pertanyaan ini?',
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(removeWebinar(param._id));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  const renderCertificateFile = () => {
    // addTextToImage("http://www.gravatar.com/avatar/0c7c99dec43bb0062494520e57f0b9ae?s=256&d=identicon&r=PG", certName)
    return (
      <canvas id="myCanvas"></canvas>
      // <canvas id="canvas"></canvas>
    )
  }

  const addTextToImage = (imagePath: any, text: any) => {
    var circle_canvas:any = document.getElementById("canvas");
    if (circle_canvas) {
      var context = circle_canvas.getContext("2d");
      let textLength = measureText(text);

      // Draw Image function
      var img = new Image();
      img.src = imagePath;
  
      var width = img.naturalWidth;
      var height = img.naturalHeight;

      let textV = height/2 + textVertical;
      let textH = (width-textLength-textHorizontal) / 2;
      img.onload = function () {
          context.drawImage(img, 0, 0);
          context.lineWidth = 1;
          context.fillStyle = fillStyle;
          context.lineStyle = lineStyle;
          context.font = "18px sans-serif";
          context.fillText(text, textH, textV);
      };
    }
  }

  const measureText = (str: any, fontSize = 12) => {
    const widths = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0.2796875,0.2765625,0.3546875,0.5546875,0.5546875,0.8890625,0.665625,0.190625,0.3328125,0.3328125,0.3890625,0.5828125,0.2765625,0.3328125,0.2765625,0.3015625,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.5546875,0.2765625,0.2765625,0.584375,0.5828125,0.584375,0.5546875,1.0140625,0.665625,0.665625,0.721875,0.721875,0.665625,0.609375,0.7765625,0.721875,0.2765625,0.5,0.665625,0.5546875,0.8328125,0.721875,0.7765625,0.665625,0.7765625,0.721875,0.665625,0.609375,0.721875,0.665625,0.94375,0.665625,0.665625,0.609375,0.2765625,0.3546875,0.2765625,0.4765625,0.5546875,0.3328125,0.5546875,0.5546875,0.5,0.5546875,0.5546875,0.2765625,0.5546875,0.5546875,0.221875,0.240625,0.5,0.221875,0.8328125,0.5546875,0.5546875,0.5546875,0.5546875,0.3328125,0.5,0.2765625,0.5546875,0.5,0.721875,0.5,0.5,0.5,0.3546875,0.259375,0.353125,0.5890625]
    const avg = 0.5279276315789471
    return str
      .split('')
      .map((c: any) => c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg)
      .reduce((cur: any, acc: any) => acc + cur) * fontSize
  }

  const renderConfigureCard = () => {
    return (
      <Card 
        type="inner" 
        title={'Pengaturan'} 
      >
        {/* Baris 1 */}
        <Row>
          <Col span={24}>Pilih Sertifikat</Col>
        </Row>
        <Row>
          <Col span={18}><Input type={'file'} placeholder="Sertifikat" onChange={onFileSelected} /></Col>
          <Col span={2} />
          <Col span={4}><Button onClick={onUploadFile}>Unggah</Button></Col>
        </Row>
        {/* Baris 2 */}
        <Row>
          <Col span={8}>Vertical</Col>
          <Col span={8}>Horizontal</Col>
          <Col span={8}>Cek Nama</Col>
        </Row>
        <Row>
          <Col span={4}><Button onClick={() => setTextVertical(textVertical-1)}>Atas</Button></Col>
          <Col span={4}><Button onClick={() => setTextVertical(textVertical+1)}>Bawah</Button></Col>
          <Col span={4}><Button onClick={() => setTextHorizontal(textHorizontal+1)}>Kiri</Button></Col>
          <Col span={4}><Button onClick={() => setTextHorizontal(textHorizontal-1)}>Kanan</Button></Col>
          <Col span={8}><Input placeholder="Nama Penerima" onChange={onChangeText} /></Col>
          {/* <Col span={4}><Button>Tampilkan</Button></Col> */}
        </Row>
      </Card>
    )
  }

  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title={wDetail && wDetail.name}
        // breadcrumb={{ routes }}
        extra={[
          <Button key="3" type="primary" onClick={() => setVisible(true)}>Generate Sertifikat</Button>
        ]}
      />
      {renderConfigureCard()}
      {/* {renderQuestionCard()} */}
      {renderCertificateFile()}

    </Content>
  )
};

export default WebinarCertificate;