import {
  USER_CREATE_FAIL,
  USER_CREATE_SUCCESS,
  USER_DELETE_FAIL,
  USER_DELETE_SUCCESS,
  USER_GET_DETAIL_FAIL,
  USER_GET_DETAIL_SUCCESS,
  USER_GET_FAIL,
  USER_GET_SUCCESS,
  USER_LOADING,
  USER_UPDATE_FAIL,
  USER_UPDATE_SUCCESS,
} from '../types';

const initialState = {
  data: [],
  total: 0,
  uDetail: undefined,
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case USER_GET_SUCCESS:
      return {
        ...state,
        data: action.resp.users,
        total: action.resp.total,
        isLoading: false,
      };
    case USER_GET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case USER_GET_DETAIL_SUCCESS:
      return {
        ...state,
        uDetail: action.resp,
        isLoading: false,
      };
    case USER_GET_DETAIL_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case USER_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case USER_CREATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case USER_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case USER_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case USER_DELETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case USER_DELETE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
