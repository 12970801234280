import {httpRequestAxiosService} from '../../services';
import {returnErrors, returnSuccess} from './InfoAction';
import { HISTORY_GET_FAIL, HISTORY_GET_SUCCESS, HISTORY_LOADING, HISTORY_USER_ACTIVITY_GET_FAIL, HISTORY_USER_ACTIVITY_GET_SUCCESS, MOOTA_NOTIF_GET_FAIL, MOOTA_NOTIF_GET_SUCCESS } from '../types';
import { tokenConfig } from './AuthAction';

// Get History
export const getHistory = (qparam: any) => (dispatch: any, getState: any) => {
  dispatch({type: HISTORY_LOADING});

  let param = {
    page: qparam && qparam.page ? qparam.page : 1,
    limit: qparam && qparam.limit ? qparam.limit : 10,
  };

  httpRequestAxiosService.get(process.env.REACT_APP_MAIN_API + 'users/webinars?page=' + param.page + '&limit=' + param.limit, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: HISTORY_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('History webinar berhasil diperoleh.', 200, 'HISTORY_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'History webinar belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'HISTORY_GET_FAIL',
        ),
      );
      dispatch({
        type: HISTORY_GET_FAIL,
      });
  })
};

// get moota notification
export const getMootaNotif = (qparam: any) => (dispatch: any, getState: any) => {
  dispatch({type: HISTORY_LOADING});

  let param = {
    page: qparam && qparam.page ? qparam.page : 1,
    limit: qparam && qparam.limit ? qparam.limit : 10,
  };

  httpRequestAxiosService.post(process.env.REACT_APP_MAIN_API + 'moota/notifications', {}, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: MOOTA_NOTIF_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Notifikasi moota berhasil diperoleh.', 200, 'MOOTA_NOTIF_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Notifikasi moota belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'MOOTA_NOTIF_GET_FAIL',
        ),
      );
      dispatch({
        type: MOOTA_NOTIF_GET_FAIL,
      });
  })
};

// get log activity
export const getUserActivity = (qparam: any) => (dispatch: any, getState: any) => {
  dispatch({type: HISTORY_LOADING});

  let param = {
    page: qparam && qparam.page ? qparam.page : 1,
    limit: qparam && qparam.limit ? qparam.limit : 10,
  };

  httpRequestAxiosService.get(process.env.REACT_APP_MAIN_API + 'users/user-activities', tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: HISTORY_USER_ACTIVITY_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Data berhasil diperoleh.', 200, 'HISTORY_USER_ACTIVITY_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Data belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'HISTORY_USER_ACTIVITY_GET_FAIL',
        ),
      );
      dispatch({
        type: HISTORY_USER_ACTIVITY_GET_FAIL,
      });
  })
};