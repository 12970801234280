// Common
export const GET_ERRORS = 'GET_ERRORS';
export const GET_INFO = 'GET_INFO';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const GET_SUCCESS = 'GET_SUCCESS';
export const CLEAR_INFO = 'CLEAR_INFO';

// Authentication
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOADING = 'AUTH_LOADING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const AUTH_CHANGE_PASSWORD_SUCCESS = 'AUTH_CHANGE_PASSWORD_SUCCESS';
export const AUTH_CHANGE_PASSWORD_FAIL = 'AUTH_CHANGE_PASSWORD_FAIL';

// Webinar
export const WEBINAR_LOADING = 'WEBINAR_LOADING';
export const WEBINAR_GET_SUCCESS = 'WEBINAR_GET_SUCCESS';
export const WEBINAR_GET_FAIL = 'WEBINAR_GET_FAIL';
export const WEBINAR_CREATE_SUCCESS = 'WEBINAR_CREATE_SUCCESS';
export const WEBINAR_CREATE_FAIL = 'WEBINAR_CREATE_FAIL';
export const WEBINAR_UPDATE_SUCCESS = 'WEBINAR_UPDATE_SUCCESS';
export const WEBINAR_UPDATE_FAIL = 'WEBINAR_UPDATE_FAIL';
export const WEBINAR_DELETE_SUCCESS = 'WEBINAR_DELETE_SUCCESS';
export const WEBINAR_DELETE_FAIL = 'WEBINAR_DELETE_FAIL';
export const WEBINAR_ADD_PACKAGE_SUCCESS = 'WEBINAR_ADD_PACKAGE_SUCCESS';
export const WEBINAR_ADD_PACKAGE_FAIL = 'WEBINAR_ADD_PACKAGE_FAIL';
export const WEBINAR_RESET = 'WEBINAR_RESET';
export const WEBINAR_PARTICIPANT_GET_SUCCESS = 'WEBINAR_PARTICIPANT_GET_SUCCESS';
export const WEBINAR_PARTICIPANT_GET_FAIL = 'WEBINAR_PARTICIPANT_GET_FAIL';
export const WEBINAR_PARTICIPANT_DETAIL_GET_SUCCESS = 'WEBINAR_PARTICIPANT_DETAIL_GET_SUCCESS';
export const WEBINAR_PARTICIPANT_DETAIL_GET_FAIL = 'WEBINAR_PARTICIPANT_DETAIL_GET_FAIL';
export const WEBINAR_PARTICIPANT_RESET_NO_CERT_SUCCESS = 'WEBINAR_PARTICIPANT_RESET_NO_CERT_SUCCESS';
export const WEBINAR_PARTICIPANT_RESET_NO_CERT_FAIL = 'WEBINAR_PARTICIPANT_RESET_NO_CERT_FAIL';
export const WEBINAR_DETAIL_GET_SUCCESS = 'WEBINAR_DETAIL_GET_SUCCESS';
export const WEBINAR_DETAIL_GET_FAIL = 'WEBINAR_DETAIL_GET_FAIL';
export const WEBINAR_VALIDATE_W_REGIS_SUCCESS = 'WEBINAR_VALIDATE_W_REGIS_SUCCESS';
export const WEBINAR_VALIDATE_W_REGIS_FAIL = 'WEBINAR_VALIDATE_W_REGIS_FAIL';
export const WEBINAR_ADD_SPEAKER_AVATAR_SUCCESS = 'WEBINAR_ADD_SPEAKER_AVATAR_SUCCESS';
export const WEBINAR_ADD_SPEAKER_AVATAR_FAIL = 'WEBINAR_ADD_SPEAKER_AVATAR_FAIL';
export const WEBINAR_ADD_CERTIFICATE_USER_SUCCESS = 'WEBINAR_ADD_CERTIFICATE_USER_SUCCESS';
export const WEBINAR_ADD_CERTIFICATE_USER_FAIL = 'WEBINAR_ADD_CERTIFICATE_USER_FAIL';
export const WEBINAR_ADD_CERTIFICATE_SUCCESS = 'WEBINAR_ADD_CERTIFICATE_SUCCESS';
export const WEBINAR_ADD_CERTIFICATE_FAIL = 'WEBINAR_ADD_CERTIFICATE_FAIL';
export const WEBINAR_ADD_POSTER_SUCCESS = 'WEBINAR_ADD_POSTER_SUCCESS';
export const WEBINAR_ADD_POSTER_FAIL = 'WEBINAR_ADD_POSTER_FAIL';
export const WEBINAR_ADD_VBG_SUCCESS = 'WEBINAR_ADD_VBG_SUCCESS';
export const WEBINAR_ADD_VBG_FAIL = 'WEBINAR_ADD_VBG_FAIL';
export const WEBINAR_VALIDATE_PAYMENT_MOOTA_SUCCESS = 'WEBINAR_VALIDATE_PAYMENT_MOOTA_SUCCESS';
export const WEBINAR_VALIDATE_PAYMENT_MOOTA_FAIL = 'WEBINAR_VALIDATE_PAYMENT_MOOTA_FAIL';
export const WEBINAR_VALIDATE_PARTICIPANT_BASIC_SUCCESS = 'WEBINAR_VALIDATE_PARTICIPANT_BASIC_SUCCESS';
export const WEBINAR_VALIDATE_PARTICIPANT_BASIC_FAIL = 'WEBINAR_VALIDATE_PARTICIPANT_BASIC_FAIL';
export const WEBINAR_UPDATE_STATUS_SUCCESS = 'WEBINAR_UPDATE_STATUS_SUCCESS';
export const WEBINAR_UPDATE_STATUS_FAIL = 'WEBINAR_UPDATE_STATUS_FAIL';
export const WEBINAR_REFRESH_STATUS_SUCCESS = 'WEBINAR_REFRESH_STATUS_SUCCESS';
export const WEBINAR_REFRESH_STATUS_FAIL = 'WEBINAR_REFRESH_STATUS_FAIL';
export const WEBINAR_USER_GET_SUCCESS = 'WEBINAR_USER_GET_SUCCESS';
export const WEBINAR_USER_GET_FAIL = 'WEBINAR_USER_GET_FAIL';
export const WEBINAR_GENERATE_INVOICE_SUCCESS = 'WEBINAR_GENERATE_INVOICE_SUCCESS';
export const WEBINAR_GENERATE_INVOICE_FAIL = 'WEBINAR_GENERATE_INVOICE_FAIL';

// Webinar Category
export const WEBINAR_CATEGORY_LOADING = 'WEBINAR_CATEGORY_LOADING';
export const WEBINAR_CATEGORY_GET_SUCCESS = 'WEBINAR_CATEGORY_GET_SUCCESS';
export const WEBINAR_CATEGORY_GET_FAIL = 'WEBINAR_CATEGORY_GET_FAIL';
export const WEBINAR_CATEGORY_CREATE_SUCCESS = 'WEBINAR_CATEGORY_CREATE_SUCCESS';
export const WEBINAR_CATEGORY_CREATE_FAIL = 'WEBINAR_CATEGORY_CREATE_FAIL';
export const WEBINAR_CATEGORY_UPDATE_SUCCESS = 'WEBINAR_CATEGORY_UPDATE_SUCCESS';
export const WEBINAR_CATEGORY_UPDATE_FAIL = 'WEBINAR_CATEGORY_UPDATE_FAIL';
export const WEBINAR_CATEGORY_DELETE_SUCCESS = 'WEBINAR_CATEGORY_DELETE_SUCCESS';
export const WEBINAR_CATEGORY_DELETE_FAIL = 'WEBINAR_CATEGORY_DELETE_FAIL';

// Setting
export const SETTING_LOADING = 'SETTING_LOADING';
export const SETTING_GET_SUCCESS = 'SETTING_GET_SUCCESS';
export const SETTING_GET_FAIL = 'SETTING_GET_FAIL';
export const SETTING_CREATE_SUCCESS = 'SETTING_CREATE_SUCCESS';
export const SETTING_CREATE_FAIL = 'SETTING_CREATE_FAIL';
export const SETTING_UPDATE_SUCCESS = 'SETTING_UPDATE_SUCCESS';
export const SETTING_UPDATE_FAIL = 'SETTING_UPDATE_FAIL';
export const SETTING_DELETE_SUCCESS = 'SETTING_DELETE_SUCCESS';
export const SETTING_DELETE_FAIL = 'SETTING_DELETE_FAIL';

// Package
export const PACKAGE_LOADING = 'PACKAGE_LOADING';
export const PACKAGE_GET_SUCCESS = 'PACKAGE_GET_SUCCESS';
export const PACKAGE_GET_FAIL = 'PACKAGE_GET_FAIL';
export const PACKAGE_CREATE_SUCCESS = 'PACKAGE_CREATE_SUCCESS';
export const PACKAGE_CREATE_FAIL = 'PACKAGE_CREATE_FAIL';
export const PACKAGE_UPDATE_SUCCESS = 'PACKAGE_UPDATE_SUCCESS';
export const PACKAGE_UPDATE_FAIL = 'PACKAGE_UPDATE_FAIL';
export const PACKAGE_DELETE_SUCCESS = 'PACKAGE_DELETE_SUCCESS';
export const PACKAGE_DELETE_FAIL = 'PACKAGE_DELETE_FAIL';
export const PACKAGE_RESET = 'PACKAGE_RESET';

// User
export const USER_LOADING = 'USER_LOADING';
export const USER_GET_SUCCESS = 'USER_GET_SUCCESS';
export const USER_GET_FAIL = 'USER_GET_FAIL';
export const USER_GET_DETAIL_SUCCESS = 'USER_GET_DETAIL_SUCCESS';
export const USER_GET_DETAIL_FAIL = 'USER_GET_DETAIL_FAIL';
export const USER_CREATE_SUCCESS = 'USER_CREATE_SUCCESS';
export const USER_CREATE_FAIL = 'USER_CREATE_FAIL';
export const USER_UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS';
export const USER_UPDATE_FAIL = 'USER_UPDATE_FAIL';
export const USER_DELETE_SUCCESS = 'USER_DELETE_SUCCESS';
export const USER_DELETE_FAIL = 'USER_DELETE_FAIL';
export const USER_RESET = 'USER_RESET';

// Webinar Registration
export const HISTORY_LOADING = 'HISTORY_LOADING';
export const HISTORY_GET_SUCCESS = 'HISTORY_GET_SUCCESS';
export const HISTORY_GET_FAIL = 'HISTORY_GET_FAIL';
export const HISTORY_USER_ACTIVITY_GET_SUCCESS = 'HISTORY_USER_ACTIVITY_GET_SUCCESS';
export const HISTORY_USER_ACTIVITY_GET_FAIL = 'HISTORY_USER_ACTIVITY_GET_FAIL';

// MOOTA
export const MOOTA_NOTIF_GET_SUCCESS = 'MOOTA_NOTIF_GET_SUCCESS';
export const MOOTA_NOTIF_GET_FAIL = 'MOOTA_NOTIF_GET_FAIL';

// Announcement
export const ANNOUNCEMENT_LOADING = 'ANNOUNCEMENT_LOADING';
export const ANNOUNCEMENT_GET_SUCCESS = 'ANNOUNCEMENT_GET_SUCCESS';
export const ANNOUNCEMENT_GET_FAIL = 'ANNOUNCEMENT_GET_FAIL';
export const ANNOUNCEMENT_CREATE_SUCCESS = 'ANNOUNCEMENT_CREATE_SUCCESS';
export const ANNOUNCEMENT_CREATE_FAIL = 'ANNOUNCEMENT_CREATE_FAIL';
export const ANNOUNCEMENT_UPDATE_SUCCESS = 'ANNOUNCEMENT_UPDATE_SUCCESS';
export const ANNOUNCEMENT_UPDATE_FAIL = 'ANNOUNCEMENT_UPDATE_FAIL';
export const ANNOUNCEMENT_REMOVE_SUCCESS = 'ANNOUNCEMENT_REMOVE_SUCCESS';
export const ANNOUNCEMENT_REMOVE_FAIL = 'ANNOUNCEMENT_REMOVE_FAIL';

// Evidence
export const EVIDENCE_LOADING = 'EVIDENCE_LOADING';
export const EVIDENCE_GET_SUCCESS = 'EVIDENCE_GET_SUCCESS';
export const EVIDENCE_GET_FAIL = 'EVIDENCE_GET_FAIL';
export const EVIDENCE_CREATE_SUCCESS = 'EVIDENCE_CREATE_SUCCESS';
export const EVIDENCE_CREATE_FAIL = 'EVIDENCE_CREATE_FAIL';
export const EVIDENCE_UPDATE_SUCCESS = 'EVIDENCE_UPDATE_SUCCESS';
export const EVIDENCE_UPDATE_FAIL = 'EVIDENCE_UPDATE_FAIL';
export const EVIDENCE_DELETE_SUCCESS = 'EVIDENCE_DELETE_SUCCESS';
export const EVIDENCE_DELETE_FAIL = 'EVIDENCE_DELETE_FAIL';
export const EVIDENCE_RESET = 'EVIDENCE_RESET';

// Post Test
export const POSTEST_LOADING = 'POSTEST_LOADING';
export const POSTEST_GET_SUCCESS = 'POSTEST_GET_SUCCESS';
export const POSTEST_GET_FAIL = 'POSTEST_GET_FAIL';
export const POSTEST_CREATE_SUCCESS = 'POSTEST_CREATE_SUCCESS';
export const POSTEST_CREATE_FAIL = 'POSTEST_CREATE_FAIL';
export const POSTEST_UPDATE_SUCCESS = 'POSTEST_UPDATE_SUCCESS';
export const POSTEST_UPDATE_FAIL = 'POSTEST_UPDATE_FAIL';
export const POSTEST_DELETE_SUCCESS = 'POSTEST_DELETE_SUCCESS';
export const POSTEST_DELETE_FAIL = 'POSTEST_DELETE_FAIL';

// Payment
export const PAYMENT_LOADING = 'PAYMENT_LOADING';
export const PAYMENT_GET_SUCCESS = 'PAYMENT_GET_SUCCESS';
export const PAYMENT_GET_FAIL = 'PAYMENT_GET_FAIL';
export const PAYMENT_G_GET_SUCCESS = 'PAYMENT_G_GET_SUCCESS';
export const PAYMENT_G_GET_FAIL = 'PAYMENT_G_GET_FAIL';
export const PAYMENT_XENDIT_GET_SUCCESS = 'PAYMENT_XENDIT_GET_SUCCESS';
export const PAYMENT_XENDIT_GET_FAIL = 'PAYMENT_XENDIT_GET_FAIL';
export const PAYMENT_XENDIT_CHECK_SUCCESS = 'PAYMENT_XENDIT_CHECK_SUCCESS';
export const PAYMENT_XENDIT_CHECK_FAIL = 'PAYMENT_XENDIT_CHECK_FAIL';

// Master Data
export const MD_PAYMENT_METHOD_LOADING = 'MD_PAYMENT_METHOD_LOADING';
export const MD_PAYMENT_METHOD_GET_SUCCESS = 'MD_PAYMENT_METHOD_GET_SUCCESS';
export const MD_PAYMENT_METHOD_GET_FAIL = 'MD_PAYMENT_METHOD_GET_FAIL';
export const MD_PAYMENT_METHOD_CREATE_SUCCESS = 'MD_PAYMENT_METHOD_CREATE_SUCCESS';
export const MD_PAYMENT_METHOD_CREATE_FAIL = 'MD_PAYMENT_METHOD_CREATE_FAIL';
export const MD_PAYMENT_METHOD_UPDATE_SUCCESS = 'MD_PAYMENT_METHOD_UPDATE_SUCCESS';
export const MD_PAYMENT_METHOD_UPDATE_FAIL = 'MD_PAYMENT_METHOD_UPDATE_FAIL';
export const MD_PAYMENT_METHOD_REMOVE_SUCCESS = 'MD_PAYMENT_METHOD_REMOVE_SUCCESS';
export const MD_PAYMENT_METHOD_REMOVE_FAIL = 'MD_PAYMENT_METHOD_REMOVE_FAIL';

// Account
export const ACCOUNT_LOADING = 'ACCOUNT_LOADING';
export const ACCOUNT_GET_SUCCESS = 'ACCOUNT_GET_SUCCESS';
export const ACCOUNT_GET_FAIL = 'ACCOUNT_GET_FAIL';

// Export Import
export const EXPORT_IMPORT_LOADING = 'EXPORT_IMPORT_LOADING';
export const EXPORT_IMPORT_PARTICIPANT_GET_SUCCESS = 'EXPORT_IMPORT_PARTICIPANT_GET_SUCCESS';
export const EXPORT_IMPORT_PARTICIPANT_GET_FAIL = 'EXPORT_IMPORT_PARTICIPANT_GET_FAIL';
export const EXPORT_IMPORT_PARTICIPANT_POST_SUCCESS = 'EXPORT_IMPORT_PARTICIPANT_POST_SUCCESS';
export const EXPORT_IMPORT_PARTICIPANT_POST_FAIL = 'EXPORT_IMPORT_PARTICIPANT_POST_FAIL';
export const EXPORT_IMPORT_PARTICIPANT_REMOVE_BY_ID_SUCCESS = 'EXPORT_IMPORT_PARTICIPANT_REMOVE_BY_ID_SUCCESS';
export const EXPORT_IMPORT_PARTICIPANT_REMOVE_BY_ID_FAIL = 'EXPORT_IMPORT_PARTICIPANT_REMOVE_BY_ID_FAIL';
export const EXPORT_IMPORT_PARTICIPANT_REMOVE_ALL_SUCCESS = 'EXPORT_IMPORT_PARTICIPANT_REMOVE_ALL_SUCCESS';
export const EXPORT_IMPORT_PARTICIPANT_REMOVE_ALL_FAIL = 'EXPORT_IMPORT_PARTICIPANT_REMOVE_ALL_FAIL';
