import {useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { List, Card , Form, Modal, Typography, message, Select, Tag, Space, PageHeader, Layout, Input, Button } from 'antd';
import { ExclamationCircleOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

import { getWebinar, createWebinar, addPackageToWebinar, removeWebinar, loadWebinarDetail, addSpeakerAvatar, addWebinarPoster, addWebinarCertificate, addWebinarVBG, updateWebinar } from '../../store/actions/WebinarAction';
import { getPackage } from '../../store/actions/PackageAction';
import { formatUang, renderSpeaker } from '../../shared/helper';
import { useParams } from 'react-router-dom';

import rs_img from '../../shared/assets/img/ruang_seminar_01.jpg';
import WebinarFormComponent from './component/WebinarFormComponent';
import { clearInfo } from '../../store/actions/InfoAction';

let moment = require('moment');

const { Option } = Select;
const { Header, Content } = Layout;
const { Text } = Typography;

const routes = [
  {
    path: '/',
    breadcrumbName: 'Dashboard',
  },
  {
    path: 'first',
    breadcrumbName: 'Webinar',
  },
  {
    path: 'second',
    breadcrumbName: 'Rincian Webinar',
  },
];

const WebinarDetail = (props: any) => {
  const dispatch = useDispatch();
  const {info, isLoading, wDetail, pList, isLoading2} = useSelector((state: any) => ({
    info: state.info,
    wDetail: state.wbnr.wDetail,
    isLoading: state.wbnr.isLoading,
    pList: state.pckg.data,
    isLoading2: state.pckg.isLoading,
  }));
  const { id } = useParams();

  const [form] = Form.useForm();
  const [isUpdateVisible, setIsUpdateVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>(undefined);
  const [selectedPoster, setSelectedPoster] = useState<any>(undefined);
  const [selectedCertificate, setSelectedCertificate] = useState<any>(undefined);
  const [selectedVBG, setSelectedVBG] = useState<any>(undefined);
  const [selectedSpeaker, setSelectedSpeaker] = useState<any>(undefined);

  const [isOpenChangeSpeakerAvatarModal, setIsOpenChangeSpeakerAvatarModal] = useState(false);
  const [isOpenChangePosterModal, setIsOpenChangePosterModal] = useState(false);
  const [isOpenChangeCertificateModal, setIsOpenChangeCertificateModal] = useState(false);
  const [isOpenChangeVBGModal, setIsOpenChangeVBGModal] = useState(false);
  
  const [isModalPackageVisible, setModalPackageVisible] = useState(false);
  const [modalData, setModalData] = useState<any>(undefined);
  const [initVal, setInitVal] = useState<any>({
    name: '',
    webinarTheme: '',
    description: '',
    start: '',
    end: '',
    link_youtube: '',
    link_zoom: '',
    location: '',
    code: '',
    certificateType: '', 
    certificateNumberFormat: '', 
    activityType: '', 
    webinarType: '', 
    tags: [], 
    speaker: [],
    packages: pList,
  });
  const [modal, contextHolder] = Modal.useModal();

  useEffect(() => {
    dispatch(loadWebinarDetail(id));
    dispatch(getPackage());
  }, []);

  useEffect(() => {
    if (info.id === 'WEBINAR_ADD_SPEAKER_AVATAR_SUCCESS' || info.id === 'WEBINAR_ADD_POSTER_SUCCESS' || info.id === 'WEBINAR_ADD_VBG_SUCCESS' || info.id === 'WEBINAR_ADD_CERTIFICATE_SUCCESS') {
      message.success(info.msg);
      dispatch(getWebinar({}));
      dispatch(loadWebinarDetail(id));
    }
    if (info.id === 'WEBINAR_ADD_SPEAKER_AVATAR_FAIL' || info.id === 'WEBINAR_ADD_POSTER_FAIL' || info.id === 'WEBINAR_ADD_VBG_FAIL' || info.id === 'WEBINAR_ADD_CERTIFICATE_FAIL') {
      message.error(info.msg.message);
    }
    if (info.id === 'WEBINAR_UPDATE_SUCCESS') {
      // setAlertContent({title: 'Berhasil', content: info.msg, type: 'success'});
      setTimeout(() => {
        modal.success(configSuccess);
      }, 500);
    }
    if (info.id === 'WEBINAR_UPDATE_FAIL') {
      // setAlertContent({title: 'Mohon Maaf', content: info.msg.message || info.msg2, type: 'failed'});
      setTimeout(() => {
        modal.error(configFailed);
      }, 500);
    }
  }, [info]);

  const configSuccess = {
    title: 'Berhasil',
    centered: true,
    content: (<div><p>{'Webinar berhasil diubah'}</p></div>),
    onOk() {
      setIsUpdateVisible(false);
      dispatch(clearInfo())
      resetInitVal()
      form.resetFields();
      dispatch(loadWebinarDetail(id));
    }
  };

  const configFailed = {
    title: 'Belum Berhasil',
    centered: true,
    content: (<div><p>{info?.msg && info?.msg[0] && info?.msg[0].message || 'Webinar belum berhasil diubah'}</p></div>),
    onOk() {
      // dispatch(clearInfo())
    }
  };

  const generateInitialValue = () => {
    let initialValue = {
      name: wDetail && wDetail.name,
      webinarTheme: wDetail && wDetail.webinarTheme,
      description: wDetail && wDetail.description,
      start: wDetail && wDetail.start && moment(wDetail.start),
      end: wDetail && wDetail.end && moment(wDetail.end),
      link_youtube: wDetail && wDetail.links.length === 2 ? wDetail.links[0] : wDetail.link_youtube,
      link_zoom: wDetail && wDetail.links.length === 2 ? wDetail.links[1] : wDetail.link_zoom,
      location: wDetail && wDetail.location,
      code: wDetail && wDetail.code,
      certificateType: (wDetail && wDetail.certificateType) || '', 
      certificateNumberFormat: (wDetail && wDetail.certificateNumberFormat) || '', 
      activityType: (wDetail && wDetail.activityType) || '', 
      webinarType: (wDetail && wDetail.webinarType) || '', 
      tags: (wDetail && wDetail.tags) || [], 
      speaker: (wDetail && wDetail.speakers) || [],
      packages: (wDetail && wDetail.packages) || pList,
    }
    setInitVal(initialValue);
  }
  
  const onCancel = () => {
    setIsUpdateVisible(false);
    setIsOpenChangeSpeakerAvatarModal(false)
    setIsOpenChangePosterModal(false)
    setIsOpenChangeCertificateModal(false)
    setIsOpenChangeVBGModal(false)
  }

  const onSubmit = (val: any) => {
    let packageId = val.packages.map((val: any) => val._id);
    let packagePrice = val.packages.map((val: any) => (val.price !== 'x') ? Number(val.price) : 'x');
    let linkw = [val.link_youtube, val.link_zoom];
    // let tags  = val.tags.map((val: any) => val.name);
    let payload = {
      name: val.name,
      webinarTheme: val.webinarTheme,
      description: val.description,
      start: moment(val.start).toISOString(),
      end: moment(val.end).toISOString(),
      links: linkw,
      link_youtube: val.link_youtube,
      link_zoom: val.link_zoom,
      location: val.location,
      code: val.code,
      certificateType: val.certificateType,
      certificateNumberFormat: val.certificateNumberFormat,
      activityType: val.activityType,
      webinarType: val.webinarType,
      tags: val.tags,
      speakers: val.speaker,
      packageIds: packageId,
      packagePrices: packagePrice,
    };

    dispatch(updateWebinar(wDetail._id, payload));
  }

  const resetInitVal = () => {
    setInitVal({
      name: '',
      description: '',
      point: '',
      category: '',
    })
  }

  const showModalDeleteConfirm = (param: any) => {
    Modal.confirm({
      title: 'Konfirmasi',
      icon: <ExclamationCircleOutlined />,
      content: 'Apakah Kamu yakin akan menghapus webinar ini?',
      okText: 'Ya',
      cancelText: 'Batal',
      onOk() {
        dispatch(removeWebinar(param._id));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  function range(start: any, end: any) {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  }

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }
  
  function disabledDateTime() {
    return {
      disabledHours: () => range(0, 24).splice(4, 20),
      disabledMinutes: () => range(30, 60),
      disabledSeconds: () => [55, 56],
    };
  }

  const onChangePicture = (param: any) => {
    setSelectedSpeaker(param);
    setIsOpenChangeSpeakerAvatarModal(true);
  }

  const onFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
  }; 

  const onUpdateSpeakerPhoto = async () => {
    const formData = new FormData();
    setIsOpenChangeSpeakerAvatarModal(false);
    formData.append("avatar", selectedFile);

    let wId = wDetail && wDetail._id;
    let sId = selectedSpeaker && selectedSpeaker._id;

    dispatch(addSpeakerAvatar(wId, sId, formData));
  };

  const onChangePoster = () => {
    setIsOpenChangePosterModal(true)
  }

  const onPosterFileChange = (event: any) => {
    setSelectedPoster(event.target.files[0]);
  }

  const onUpdatePoster = () => {
    const formData = new FormData();
    setIsOpenChangePosterModal(false);
    formData.append("profileImage", selectedPoster);

    let wId = wDetail && wDetail._id;

    dispatch(addWebinarPoster(wId, formData));
  }

  const onChangeVBG = () => {
    setIsOpenChangeVBGModal(true)
  }

  const onVBGFileChange = (event: any) => {
    setSelectedVBG(event.target.files[0]);
  }

  const onUpdateVBG = () => {
    const formData = new FormData();
    setIsOpenChangeVBGModal(false);
    formData.append("virtual_bg", selectedVBG);

    let wId = wDetail && wDetail._id;

    dispatch(addWebinarVBG(wId, formData));
  }

  const onChangeCertificate = () => {
    setIsOpenChangeCertificateModal(true)
  }

  const onCertificateFileChange = (event: any) => {
    setSelectedCertificate(event.target.files[0]);
  }

  const onUpdateCertificate = () => {
    const formData = new FormData();
    setIsOpenChangeCertificateModal(false);
    formData.append("certificate", selectedCertificate);

    let wId = wDetail && wDetail._id;

    dispatch(addWebinarCertificate(wId, formData));
  }

  const onOpenUpdateModal = () => {
    setIsUpdateVisible(true)
    generateInitialValue()
  }

  return (
    <Content style={{
      margin: '8px 16px',
      paddingLeft: 12,
      paddingRight: 12,
      minHeight: 600,
    }}>
      <PageHeader
        className="site-page-header"
        title={wDetail && wDetail.name}
        // breadcrumb={{ routes }}
        extra={[
          <Button key="3" type="primary" onClick={() => onOpenUpdateModal()}>Ubah Webinar</Button>
        ]}
      />
      {wDetail && (
        <>
          <Card 
            type="inner" 
            title="Informasi" 
            // extra={<a href="#">More</a>}
          >
            <List.Item>
              <List.Item.Meta
                title="Kode Webinar"
                description={wDetail && wDetail.code}
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title="Tema Webinar"
                description={wDetail && wDetail?.webinarTheme || '-'}
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title="Judul Webinar"
                description={wDetail && wDetail.name}
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title="Deskripsi"
                description={wDetail && wDetail.description}
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title="Tanggal Pelaksanaan"
                description={
                  (wDetail && wDetail.start ? moment(wDetail.start).format('DD MMM YYYY HH:mm') : '') + ' ' +
                  (wDetail && wDetail.end ? ('- ' + moment(wDetail.end).format('DD MMM YYYY HH:mm')) : '')
                }
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title="Lokasi"
                description={wDetail && wDetail.location}
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title="Aktivitas"
                description={wDetail && wDetail?.activityType || '-'}
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title="Jenis Webinar"
                description={wDetail && wDetail.webinarType}
              />
            </List.Item>
            {/* <List.Item>
              <List.Item.Meta
                title="Jenis Sertifikat"
                description={wDetail && wDetail?.certificateType}
              />
            </List.Item> */}
            <List.Item>
              <List.Item.Meta
                title="Tags"
                description={
                  wDetail && wDetail.tags && wDetail.tags.map((tag: any) => {
                    if (tag) {
                      // let color = tag && tag.length > 5 ? 'geekblue' : 'green';
                      let color = 'geekblue';
                      if (tag === 'loser') {
                        color = 'volcano';
                      }
                      return (
                        <Tag color={color} key={tag}>
                          {tag.toUpperCase()}
                        </Tag>
                      );
                    } else {
                      return '-'
                    }
                  })
                }
              />
            </List.Item>
          </Card>

          <Card
            style={{ marginTop: 16 }}
            type="inner"
            title="Poster"
            // extra={<a href="#">More</a>}
          >
            <img
              width={200}
              alt="poster"
              src={
                wDetail && wDetail.profileImage && wDetail.profileImage !== 'profileImage.png' ?
                  process.env.REACT_APP_WEB + wDetail.profileImage  
                  : rs_img
              }
              onClick={() => onChangePoster()}
            />
          </Card>

          <Card
            style={{ marginTop: 16 }}
            type="inner"
            title="Virtual Background"
            // extra={<a href="#">More</a>}
          >
            <img
              width={200}
              alt="virtual_bg"
              src={
                wDetail && wDetail.virtual_bg && wDetail.virtual_bg !== 'profileImage.png' ?
                  process.env.REACT_APP_WEB + wDetail.virtual_bg  
                  : rs_img
              }
              onClick={() => onChangeVBG()}
            />
          </Card>

          <Card
            style={{ marginTop: 16 }}
            type="inner"
            title="Pemateri"
            // extra={<a href="#">More</a>}
          >
            <List
              itemLayout="vertical"
              size="large"
              dataSource={wDetail && wDetail.speakers}
              renderItem={(item: any, ind: any) => (
                <List.Item
                  key={ind}
                  extra={
                    <img
                      width={200}
                      alt="logo"
                      src={
                        item && item.avatar ?
                          process.env.REACT_APP_WEB + item.avatar  
                          : "https://cdn.onlinewebfonts.com/svg/img_569205.png"
                      }
                      onClick={() => onChangePicture(item)}
                    />
                  }
                >
                  <List.Item.Meta
                    title={renderSpeaker(item)}
                    description={item.scope}
                  />
                </List.Item>
              )}
            />
          </Card>

          <Card
            style={{ marginTop: 16 }}
            type="inner"
            title="Sertifikat"
            // extra={<a href="#">More</a>}
          >
            <List.Item>
              <List.Item.Meta
                title="Jenis Sertifikat"
                description={wDetail && wDetail?.certificateType || '-'}
              />
              <List.Item.Meta
                title="Format Nomor Sertifikat"
                description={wDetail && wDetail?.certificateNumberFormat || '-'}
              />
            </List.Item>
            <img
              width={200}
              alt="logo"
              src={
                wDetail && wDetail.certificate ?
                  // 'https://ruangseminar.site/' + wDetail.certificate  
                  process.env.REACT_APP_WEB + wDetail.certificate  
                  : rs_img
              }
              onClick={() => onChangeCertificate()}
            />
          </Card>

          <Card
            style={{ marginTop: 16 }}
            type="inner"
            title="Link Webinar"
          >
            <List.Item>
              <List.Item.Meta
                title="Link Zoom"
                // description={wDetail && wDetail.links && wDetail.links.length > 1  && wDetail.links[1] ? <a href={wDetail.links[1]}>{wDetail.links[1]}</a> : '-'}
                description={wDetail && wDetail.link_zoom ? <a href={wDetail.link_zoom}>{wDetail.link_zoom}</a> : '-'}
              />
            </List.Item>
            <List.Item>
              <List.Item.Meta
                title="Link Youtube"
                description={wDetail && wDetail.link_youtube ? <a href={wDetail.link_youtube}>{wDetail.link_youtube}</a> : '-'}
                // description={wDetail && wDetail.links && wDetail.links.length > 0  && wDetail.links[0] ? <a href={wDetail.links[0]}>{wDetail.links[0]}</a> : '-'}
              />
            </List.Item>
          </Card>
        
          <Card
            style={{ marginTop: 16 }}
            type="inner"
            title="Paket"
            // extra={<a href="#">More</a>}
          >
            <List
              itemLayout="vertical"
              size="large"
              dataSource={wDetail && wDetail.packages}
              renderItem={(item: any, ind: any) => {
                if (item.isActive) {
                  return (
                    <List.Item
                      key={ind}
                    >
                      <List.Item.Meta
                        title={item.name}
                        description={`${formatUang(item.price.toString(), 'Rp ')} | ${(item.isForStudent === 'yes') ? 'Pelajar/Mahasiswa' : 'Umum'}`}
                      />
                      {item.facilities && item.facilities.map((point: any, ind: any) => {
                        return (
                          <div key={ind}>
                            <Text>- </Text>
                            <Text>{point}</Text>
                          </div>
                        )
                      })}
                    </List.Item>
                  )
                }
              }}
            />
          </Card>
        </>
      )}

      <Modal
        visible={isOpenChangeSpeakerAvatarModal}
        title={"Ubah Foto Pemateri"}
        okText={"Simpan"}
        cancelText="Batal"
        onCancel={onCancel}
        onOk={() => {
          onUpdateSpeakerPhoto();
        }}
      >
        <Form layout="vertical" name="form_in_modal">
          <Form.Item label="Foto Pemateri">
            <Input type="file" onChange={onFileChange} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        visible={isOpenChangePosterModal}
        title={"Ubah Poster"}
        okText={"Simpan"}
        cancelText="Batal"
        onCancel={onCancel}
        onOk={() => {
          onUpdatePoster();
        }}
      >
        <Form layout="vertical" name="form_in_modal">
          <Form.Item label="Poster">
            <Input type="file" onChange={onPosterFileChange} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        visible={isOpenChangeCertificateModal}
        title={"Ubah Sertifikat"}
        okText={"Simpan"}
        cancelText="Batal"
        onCancel={onCancel}
        onOk={() => {
          onUpdateCertificate();
        }}
      >
        <Form layout="vertical" name="form_in_modal">
          <Form.Item label="Sertifikat">
            <Input type="file" onChange={onCertificateFileChange} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        visible={isOpenChangeVBGModal}
        title={"Ubah Virtual Background"}
        okText={"Simpan"}
        cancelText="Batal"
        onCancel={onCancel}
        onOk={() => {
          onUpdateVBG();
        }}
      >
        <Form layout="vertical" name="form_in_modal">
          <Form.Item label="Virtual Background">
            <Input type="file" onChange={onVBGFileChange} />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={isUpdateVisible}
        title={"Ubah info webinar"}
        okText={"Ubah"}
        cancelText="Batal"
        onCancel={onCancel}
        width={768}
        onOk={() => {
          form
            .validateFields()
            .then((values: any) => {
              onSubmit(values);
            })
            .catch((info: any) => {
              console.log('Validate Failed:', info);
            });
        }}
      >
        <WebinarFormComponent
          isUpdate
          initVal= {initVal} 
          form= {form}
        />
      </Modal>
      {contextHolder}
    </Content>
  )
};

export default WebinarDetail;