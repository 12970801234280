import {
  PAYMENT_GET_FAIL,
  PAYMENT_GET_SUCCESS,
  PAYMENT_G_GET_FAIL,
  PAYMENT_G_GET_SUCCESS,
  PAYMENT_LOADING,
  PAYMENT_XENDIT_CHECK_FAIL,
  PAYMENT_XENDIT_CHECK_SUCCESS,
  PAYMENT_XENDIT_GET_FAIL,
  PAYMENT_XENDIT_GET_SUCCESS,
} from '../types';

const initialState = {
  data: [],
  total: 0,
  dataG: [],
  totalG: 0,
  dataX: [],
  totalX: 0,
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case PAYMENT_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case PAYMENT_GET_SUCCESS:
      return {
        ...state,
        data: action.resp.data,
        total: action.resp.total,
        isLoading: false,
      };
    case PAYMENT_GET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case PAYMENT_G_GET_SUCCESS:
      return {
        ...state,
        dataG: action.resp.data,
        totalG: action.resp.total,
        isLoading: false,
      };
    case PAYMENT_G_GET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case PAYMENT_XENDIT_GET_SUCCESS:
      return {
        ...state,
        dataX: action.resp,
        totalX: action.resp.total,
        isLoading: false,
      };
    case PAYMENT_XENDIT_GET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case PAYMENT_XENDIT_CHECK_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case PAYMENT_XENDIT_CHECK_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
