import { MD_PAYMENT_METHOD_CREATE_FAIL, MD_PAYMENT_METHOD_CREATE_SUCCESS, MD_PAYMENT_METHOD_GET_FAIL, MD_PAYMENT_METHOD_GET_SUCCESS, MD_PAYMENT_METHOD_LOADING, MD_PAYMENT_METHOD_REMOVE_FAIL, MD_PAYMENT_METHOD_REMOVE_SUCCESS, MD_PAYMENT_METHOD_UPDATE_FAIL, MD_PAYMENT_METHOD_UPDATE_SUCCESS } from '../types';

const initialState = {
  data: [],
  total: 0,
  isLoading: false,
};

export default function (state = initialState, action: any) {
  switch (action.type) {
    case MD_PAYMENT_METHOD_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case MD_PAYMENT_METHOD_GET_SUCCESS:
      return {
        ...state,
        data: action.resp.data,
        total: action.resp.total,
        isLoading: false,
      };
    case MD_PAYMENT_METHOD_GET_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case MD_PAYMENT_METHOD_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case MD_PAYMENT_METHOD_CREATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case MD_PAYMENT_METHOD_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case MD_PAYMENT_METHOD_UPDATE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case MD_PAYMENT_METHOD_REMOVE_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case MD_PAYMENT_METHOD_REMOVE_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
}
