import {httpRequestAxiosService} from '../../services';
import {returnErrors, returnSuccess} from './InfoAction';
import {
  POSTEST_LOADING,
  POSTEST_CREATE_SUCCESS,
  POSTEST_CREATE_FAIL,
  POSTEST_GET_SUCCESS,
  POSTEST_GET_FAIL,
  POSTEST_UPDATE_SUCCESS,
  POSTEST_UPDATE_FAIL,
  POSTEST_DELETE_SUCCESS,
  POSTEST_DELETE_FAIL,
} from '../types';
import { tokenConfig } from './AuthAction';

// Get post test by webinar
export const getPostTestWebinar = (wId: any) => (dispatch: any, getState: any) => {
  dispatch({type: POSTEST_LOADING});

  httpRequestAxiosService.get(`${process.env.REACT_APP_MAIN_API}webinars/${wId}/posttestadmin`, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: POSTEST_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Pertanyaan berhasil diperoleh.', 200, 'POSTEST_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Pertanyaan belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'POSTEST_GET_FAIL',
        ),
      );
      dispatch({
        type: POSTEST_GET_FAIL,
      });
  })
};

// add new question
export const createQuestionWebinar = (payload: any, wId: any) => (dispatch: any, getState: any) => {
  dispatch({type: POSTEST_LOADING});

  httpRequestAxiosService.post(`${process.env.REACT_APP_MAIN_API}webinars/${wId}/posttest`, payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: POSTEST_CREATE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Pertanyaan berhasil ditambahkan.', 200, 'POSTEST_CREATE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'POSTEST_CREATE_FAIL',
          'Pertanyaan belum berhasil ditambahkan',
        ),
      );
      dispatch({
        type: POSTEST_CREATE_FAIL,
      });
  })
};

// // update packages
export const updatePackage = (paramId: any, payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: POSTEST_LOADING});

  httpRequestAxiosService.put(process.env.REACT_APP_MAIN_API + 'packages/' + paramId, payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: POSTEST_UPDATE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Paket berhasil diubah.', 200, 'POSTEST_UPDATE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'POSTEST_UPDATE_FAIL',
          'Paket belum berhasil diubah',
        ),
      );
      dispatch({
        type: POSTEST_UPDATE_FAIL,
      });
  })
};

// // remove packages
export const removePackage = (paramId: any) => (dispatch: any, getState: any) => {
  dispatch({type: POSTEST_LOADING});

  httpRequestAxiosService.delete(process.env.REACT_APP_MAIN_API + 'packages/' + paramId, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: POSTEST_DELETE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Paket berhasil dihapus.', 200, 'POSTEST_DELETE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'POSTEST_DELETE_FAIL',
          'Paket belum berhasil dihapus',
        ),
      );
      dispatch({
        type: POSTEST_DELETE_FAIL,
      });
  })
};