import '../App.css';

import React, { useState } from 'react';
import { Layout, Menu, Row, Col, Dropdown, Avatar, Breadcrumb } from 'antd';
import { Link, Outlet } from "react-router-dom";

import {
  HomeOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UserOutlined,
  VideoCameraOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { LaptopOutlined, NotificationOutlined } from '@ant-design/icons';

import Title from 'antd/lib/typography/Title';
import './index.less';
import { useDispatch } from 'react-redux';
import { logout } from '../store/actions/AuthAction';

const { SubMenu } = Menu;

const { Header, Sider, Content } = Layout;

const AdminLayout = (props: any) => {
  const {children} = props;
  const dispatch = useDispatch();

  const [isCollapsed, setCollapsed] = useState(false);

  const toggle = () => {
    setCollapsed(!isCollapsed);
  };

  const getSelectedKeys = () => {
    if (window.location.href.indexOf('/admin/dashboard') !== -1) {
      return ['dashboard']
    } else if (window.location.href.indexOf('/admin/webinar') !== -1) {
      return ['webinar']
    } else if (window.location.href.indexOf('/admin/history') !== -1) {
      return ['history']
    } else if (window.location.href.indexOf('/admin/participant') !== -1) {
      return ['participant']
    } else if (window.location.href.indexOf('/admin/imported-participant') !== -1) {
      return ['imported_participant']
    } else if (window.location.href.indexOf('/admin/payment') !== -1) {
      return ['payment']
    } else if (window.location.href.indexOf('/admin/certificate') !== -1) {
      return ['certificate']
    } else if (window.location.href.indexOf('/admin/package') !== -1) {
      return ['package']
    } else if (window.location.href.indexOf('/admin/announcement') !== -1) {
      return ['announcement']
    } else if (window.location.href.indexOf('/admin/masterdata') !== -1) {
      return ['masterdata']
    } 
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <Link to="/admin/profile">Profil</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/admin/changepassword">Ganti Password</Link>
      </Menu.Item>
      <Menu.Item onClick={() => dispatch(logout())}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <Layout>
      <Header className="header" style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
        <Row>
          <Col span={3} style={{paddingTop: 15}}>
            <Title style={{color: 'white', justifySelf: 'center'}} level={4}>RuSem</Title>
          </Col>
          <Col span={12}>
            {isCollapsed ? 
              <MenuUnfoldOutlined 
                style={{fontSize: '24px', color: 'white', marginLeft: 10}}
                onClick={toggle}
              /> 
              :
              <MenuFoldOutlined 
                style={{fontSize: '24px', color: 'white', marginLeft: 10}}
                onClick={toggle}
              />
            }
          </Col>
          <Col span={9} style={{alignItems: 'center', paddingTop: 10, paddingRight: 10}}>
            <Dropdown overlay={menu} placement="bottomLeft">
              {/* <Button>bottomLeft</Button> */}
              <Avatar style={{float: 'right'}} src='./assets/img/avatar.png' />
            </Dropdown>
          </Col>
        </Row>
      </Header>
      <Layout>
        <Sider 
          style={{ 
            position: 'fixed', 
            height: '100vh', 
            left: 0,           // Align to the left
            top: 64,           // Push down below the fixed header (64px is the default header height)
            zIndex: 1          // Ensure it's on top of content but below the header
          }} 
          width={200} className="site-layout-background"  trigger={null} collapsible collapsed={isCollapsed}>
          <Menu theme="dark" mode="inline" defaultSelectedKeys={getSelectedKeys()}>
            <Menu.Item key="dashboard" icon={<HomeOutlined />}>
              <Link to="/admin/dashboard">Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="webinar" icon={<VideoCameraOutlined />}>
              <Link to="/admin/webinar">Webinar</Link>
            </Menu.Item>
            <Menu.Item key="history" icon={<VideoCameraOutlined />}>
              <Link to="/admin/history">History</Link>
            </Menu.Item>
            <Menu.Item key="participant" icon={<UserOutlined />}>
              <Link to="/admin/participant">Peserta</Link>
            </Menu.Item>
            <Menu.Item key="imported_participant" icon={<UserOutlined />}>
              <Link to="/admin/imported-participant">Peserta (Import Data)</Link>
            </Menu.Item>
            <Menu.Item key="announcement" icon={<UserOutlined />}>
              <Link to="/admin/announcement">Pengumuman</Link>
            </Menu.Item>
            <Menu.Item key="payment" icon={<UploadOutlined />}>
              <Link to="/admin/payment">Pembayaran</Link>
            </Menu.Item>
            {/* <Menu.Item key="paymentxendit" icon={<UploadOutlined />}>
              <Link to="/admin/payment/xendit">Pembayaran Xendit</Link>
            </Menu.Item> */}
            {/* <Menu.Item key="certificate" icon={<UploadOutlined />}>
              <Link to="/admin/sertifikat">Sertifikat</Link>
            </Menu.Item> */}
            {/* <Menu.Item key="package" icon={<UploadOutlined />}>
              <Link to="/admin/paket">Paket</Link>
            </Menu.Item> */}
            <Menu.Item key="masterdata" icon={<UploadOutlined />}>
              <Link to="/admin/masterdata">Master Data</Link>
            </Menu.Item>
            <Menu.Item key="setting" icon={<UploadOutlined />}>
              <Link to="/admin/setting">Pengaturan</Link>
            </Menu.Item>
            {/* <Menu.Item key="6" icon={<UploadOutlined />}>
              Pembayaran
            </Menu.Item> */}
          </Menu>
        </Sider>
        <Layout style={{ marginLeft: isCollapsed ? '80px' : '200px', padding: '0 24px 24px', marginTop: '64px' }}>
          <Outlet />
        </Layout>
      </Layout>
    </Layout>
  )

  
}
export default AdminLayout;

// return (
//   <Layout>
//     <Header className="header" style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
//       <Row>
//         <Col span={3} style={{paddingTop: 15}}>
//           <Title style={{color: 'white', justifySelf: 'center'}} level={4}>RuSem</Title>
//         </Col>
//         <Col span={12}>
//           {isCollapsed ? 
//             <MenuUnfoldOutlined 
//               style={{fontSize: '24px', color: 'white', marginLeft: 10}}
//               onClick={toggle}
//             /> 
//             :
//             <MenuFoldOutlined 
//               style={{fontSize: '24px', color: 'white', marginLeft: 10}}
//               onClick={toggle}
//             />
//           }
//         </Col>
//         <Col span={9} style={{alignItems: 'center', paddingTop: 10, paddingRight: 10}}>
//           <Dropdown overlay={menu} placement="bottomLeft">
//             {/* <Button>bottomLeft</Button> */}
//             <Avatar style={{float: 'right'}} src='./assets/img/avatar.png' />
//           </Dropdown>
//         </Col>
//       </Row>
//     </Header>
    
//     <Layout>
//       <Sider width={200} className="site-layout-background" trigger={null} collapsible collapsed={isCollapsed} style={{height: '100vh'}}>
//         {/* <div className="logo" /> */}
//         {/* <Row style={{marginTop: 10, marginBottom: 10, alignItems: 'center', justifyContent: 'center'}}>
//           <Title style={{color: 'white', justifySelf: 'center'}} level={3}>RuSem</Title>
//         </Row> */}
//         <Menu theme="dark" mode="inline" defaultSelectedKeys={getSelectedKeys()}>
//           <Menu.Item key="dashboard" icon={<HomeOutlined />}>
//             <Link to="/admin/dashboard">Dashboard</Link>
//           </Menu.Item>
//           <Menu.Item key="webinar" icon={<VideoCameraOutlined />}>
//             <Link to="/admin/webinar">Webinar</Link>
//           </Menu.Item>
//           <Menu.Item key="history" icon={<VideoCameraOutlined />}>
//             <Link to="/admin/history">History</Link>
//           </Menu.Item>
//           <Menu.Item key="user" icon={<UserOutlined />}>
//             <Link to="/admin/peserta">Peserta</Link>
//           </Menu.Item>
//           <Menu.Item key="announcement" icon={<UserOutlined />}>
//             <Link to="/admin/announcement">Pengumuman</Link>
//           </Menu.Item>
//           {/* <Menu.Item key="payment" icon={<UploadOutlined />}>
//             <Link to="/admin/pembayaran">Pembayaran</Link>
//           </Menu.Item>
//           <Menu.Item key="certificate" icon={<UploadOutlined />}>
//             <Link to="/admin/sertifikat">Sertifikat</Link>
//           </Menu.Item> */}
//           {/* <Menu.Item key="package" icon={<UploadOutlined />}>
//             <Link to="/admin/paket">Paket</Link>
//           </Menu.Item> */}
//           <Menu.Item key="masterdata" icon={<UploadOutlined />}>
//             <Link to="/admin/masterdata">Master Data</Link>
//           </Menu.Item>
//           {/* <Menu.Item key="6" icon={<UploadOutlined />}>
//             Pembayaran
//           </Menu.Item> */}
//         </Menu>
//       </Sider>
//       <Layout style={{ padding: '0 24px 24px' }}>
//         {/* <Breadcrumb style={{ margin: '16px 0' }}>
//           <Breadcrumb.Item>Home</Breadcrumb.Item>
//           <Breadcrumb.Item>List</Breadcrumb.Item>
//           <Breadcrumb.Item>App</Breadcrumb.Item>
//         </Breadcrumb>
//         <Content
//           className="site-layout-background"
//           style={{
//             padding: 24,
//             margin: 0,
//             minHeight: 280,
//           }}
//         >
//           Content
//         </Content> */}
//         {children}
//       </Layout>
//     </Layout>
//   </Layout>
// );