import {httpRequestAxiosService} from '../../services';
import { WEBINAR_CATEGORY_CREATE_FAIL, WEBINAR_CATEGORY_CREATE_SUCCESS, WEBINAR_CATEGORY_DELETE_FAIL, WEBINAR_CATEGORY_DELETE_SUCCESS, WEBINAR_CATEGORY_GET_FAIL, WEBINAR_CATEGORY_GET_SUCCESS, WEBINAR_CATEGORY_LOADING, WEBINAR_CATEGORY_UPDATE_FAIL, WEBINAR_CATEGORY_UPDATE_SUCCESS } from '../types';
import { tokenConfig } from './AuthAction';
import {returnErrors, returnInfo, returnSuccess} from './InfoAction';

// Get Webinar
export const getWebinarCategories = (qparam: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_CATEGORY_LOADING});
  let param = {
    page: qparam && qparam.page ? qparam.page : 1,
    limit: qparam && qparam.limit ? qparam.limit : 10,
  };

  httpRequestAxiosService.get(process.env.REACT_APP_MAIN_API + 'webinar-category?page=' + param.page + '&limit=' + param.limit + '&sortby=createdAt&order=asc', tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_CATEGORY_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Daftar kategori webinar berhasil diperoleh.', 200, 'WEBINAR_CATEGORY_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnInfo(
          (err && err.response && err.response) || 'Data kategori webinar belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'WEBINAR_CATEGORY_GET_FAIL',
        ),
      );
      dispatch({
        type: WEBINAR_CATEGORY_GET_FAIL,
      });
  })
};

// add new webinar category
export const createWebinarCategory = (payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_CATEGORY_LOADING});

  httpRequestAxiosService.post(process.env.REACT_APP_MAIN_API + 'webinar-category', payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_CATEGORY_CREATE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Kategori webinar berhasil ditambahkan.', 200, 'WEBINAR_CATEGORY_CREATE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnInfo(
          (err && err.response && err.response && err.response.data) || 'Kategori webinar belum berhasil ditambahkan',
          (err && err.response && err.response.status) || 401,
          'WEBINAR_CATEGORY_CREATE_FAIL',
          'Kategori webinar belum berhasil ditambahkan',
        ),
      );
      dispatch({
        type: WEBINAR_CATEGORY_CREATE_FAIL,
      });
  })
};

// 
export const updateWebinarCategory = (wcId: any, payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_CATEGORY_LOADING});

  httpRequestAxiosService.put(process.env.REACT_APP_MAIN_API + 'webinar-category/' + wcId, payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_CATEGORY_UPDATE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Kategori webinar berhasil diubah.', 200, 'WEBINAR_CATEGORY_UPDATE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnInfo(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'WEBINAR_CATEGORY_UPDATE_FAIL',
          'Kategori webinar belum berhasil diubah',
        ),
      );
      dispatch({
        type: WEBINAR_CATEGORY_UPDATE_FAIL,
      });
  })
};

// remove webinar
export const removeWebinarCategory = (paramId: any) => (dispatch: any, getState: any) => {
  dispatch({type: WEBINAR_CATEGORY_LOADING});

  httpRequestAxiosService.delete(process.env.REACT_APP_MAIN_API + 'webinar-category/' + paramId, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: WEBINAR_CATEGORY_DELETE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Kategori webinar berhasil dihapus.', 200, 'WEBINAR_CATEGORY_DELETE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'WEBINAR_CATEGORY_DELETE_FAIL',
          'Kategori webinar belum berhasil dihapus',
        ),
      );
      dispatch({
        type: WEBINAR_CATEGORY_DELETE_FAIL,
      });
  })
};