import {httpRequestAxiosService} from '../../services';
import {returnErrors, returnSuccess} from './InfoAction';
import {
  PACKAGE_LOADING,
  PACKAGE_CREATE_SUCCESS,
  PACKAGE_CREATE_FAIL,
  PACKAGE_GET_SUCCESS,
  PACKAGE_GET_FAIL,
  PACKAGE_UPDATE_SUCCESS,
  PACKAGE_UPDATE_FAIL,
  PACKAGE_DELETE_SUCCESS,
  PACKAGE_DELETE_FAIL,
} from '../types';
import { tokenConfig } from './AuthAction';

// Get Package
export const getPackage = () => (dispatch: any, getState: any) => {
  dispatch({type: PACKAGE_LOADING});

  httpRequestAxiosService.get(process.env.REACT_APP_MAIN_API + 'packages', tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: PACKAGE_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Daftar Package berhasil diperoleh.', 200, 'PACKAGE_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Data Package belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'PACKAGE_GET_FAIL',
        ),
      );
      dispatch({
        type: PACKAGE_GET_FAIL,
      });
  })
};

// add new package
export const createPackage = (payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: PACKAGE_LOADING});

  httpRequestAxiosService.post(process.env.REACT_APP_MAIN_API + 'packages', payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: PACKAGE_CREATE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Package berhasil ditambahkan.', 200, 'PACKAGE_CREATE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'PACKAGE_CREATE_FAIL',
          'Package belum berhasil ditambahkan',
        ),
      );
      dispatch({
        type: PACKAGE_CREATE_FAIL,
      });
  })
};

// // update packages
export const updatePackage = (paramId: any, payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: PACKAGE_LOADING});

  httpRequestAxiosService.put(process.env.REACT_APP_MAIN_API + 'packages/' + paramId, payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: PACKAGE_UPDATE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Paket berhasil diubah.', 200, 'PACKAGE_UPDATE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'PACKAGE_UPDATE_FAIL',
          'Paket belum berhasil diubah',
        ),
      );
      dispatch({
        type: PACKAGE_UPDATE_FAIL,
      });
  })
};

// // remove packages
export const removePackage = (paramId: any) => (dispatch: any, getState: any) => {
  dispatch({type: PACKAGE_LOADING});

  httpRequestAxiosService.delete(process.env.REACT_APP_MAIN_API + 'packages/' + paramId, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: PACKAGE_DELETE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Paket berhasil dihapus.', 200, 'PACKAGE_DELETE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'PACKAGE_DELETE_FAIL',
          'Paket belum berhasil dihapus',
        ),
      );
      dispatch({
        type: PACKAGE_DELETE_FAIL,
      });
  })
};