import {httpRequestAxiosService} from '../../services';
import {returnErrors, returnSuccess} from './InfoAction';
import { MD_PAYMENT_METHOD_CREATE_FAIL, MD_PAYMENT_METHOD_CREATE_SUCCESS, MD_PAYMENT_METHOD_GET_FAIL, MD_PAYMENT_METHOD_GET_SUCCESS, MD_PAYMENT_METHOD_LOADING, MD_PAYMENT_METHOD_REMOVE_FAIL, MD_PAYMENT_METHOD_REMOVE_SUCCESS, MD_PAYMENT_METHOD_UPDATE_FAIL, MD_PAYMENT_METHOD_UPDATE_SUCCESS } from '../types';
import { tokenConfig } from './AuthAction';

// Get Master Data Payment Method
export const getAllPaymentMethod = () => (dispatch: any, getState: any) => {
  dispatch({type: MD_PAYMENT_METHOD_LOADING});

  httpRequestAxiosService.get(process.env.REACT_APP_MAIN_API + 'payment-method', tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: MD_PAYMENT_METHOD_GET_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Data berhasil diperoleh.', 200, 'MD_PAYMENT_METHOD_GET_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response) || 'Data belum berhasil diperoleh',
          (err && err.response && err.response.status) || 401,
          'MD_PAYMENT_METHOD_GET_FAIL',
        ),
      );
      dispatch({
        type: MD_PAYMENT_METHOD_GET_FAIL,
      });
  })
};

// add new payment method
export const createPaymentMethod = (payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: MD_PAYMENT_METHOD_LOADING});

  httpRequestAxiosService.post(process.env.REACT_APP_MAIN_API + 'payment-method', payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: MD_PAYMENT_METHOD_CREATE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Data berhasil ditambahkan.', 200, 'MD_PAYMENT_METHOD_CREATE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'MD_PAYMENT_METHOD_CREATE_FAIL',
          'Data belum berhasil ditambahkan',
        ),
      );
      dispatch({
        type: MD_PAYMENT_METHOD_CREATE_FAIL,
      });
  })
};

// // update PaymentMethod
export const updatePaymentMethod = (paramId: any, payload: any) => (dispatch: any, getState: any) => {
  dispatch({type: MD_PAYMENT_METHOD_LOADING});

  httpRequestAxiosService.put(process.env.REACT_APP_MAIN_API + 'payment-method/byId/' + paramId, payload, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: MD_PAYMENT_METHOD_UPDATE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Data berhasil diubah.', 200, 'MD_PAYMENT_METHOD_UPDATE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'MD_PAYMENT_METHOD_UPDATE_FAIL',
          'Data belum berhasil diubah',
        ),
      );
      dispatch({
        type: MD_PAYMENT_METHOD_UPDATE_FAIL,
      });
  })
};

// // remove packages
export const removePaymentMethod = (paramId: any) => (dispatch: any, getState: any) => {
  dispatch({type: MD_PAYMENT_METHOD_LOADING});

  httpRequestAxiosService.delete(process.env.REACT_APP_MAIN_API + 'payment-method/byId/' + paramId, tokenConfig(getState))
    .subscribe((resp: any) => {
      dispatch({
        type: MD_PAYMENT_METHOD_REMOVE_SUCCESS,
        resp: resp,
      });
      dispatch(returnSuccess('Data berhasil dihapus.', 200, 'MD_PAYMENT_METHOD_REMOVE_SUCCESS'));
    },
    (err: any) => {
      dispatch(
        returnErrors(
          (err && err.response && err.response && err.response.data),
          (err && err.response && err.response.status) || 401,
          'MD_PAYMENT_METHOD_REMOVE_FAIL',
          'Data belum berhasil dihapus',
        ),
      );
      dispatch({
        type: MD_PAYMENT_METHOD_REMOVE_FAIL,
      });
  })
};